import React from "react";

const LowBatteryIcon = ({ color }) => (
	<svg
		width="27"
		height="27"
		viewBox="0 0 27 27"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<rect
			x="7.91216"
			y="4.91216"
			width="18.1757"
			height="21.1757"
			fill="white"
			stroke={color}
			strokeWidth="1.82432"
		/>
		<rect x="11" y="20" width="12" height="3" rx="0.857143" fill={color} />
		<rect x="11" y="16" width="12" height="3" rx="0.857143" fill={color} />
		<rect x="11" y="12" width="12" height="3" rx="0.857143" fill={color} />
		<rect x="11" y="8" width="12" height="3" rx="0.857143" fill={color} />
		<rect x="13" width="8" height="3" fill={color} />
	</svg>
);

export default LowBatteryIcon;
