import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	getSites,
	getSitesOfOrganization,
	deleteSite,
} from "../../Store/Features/Sites";
import { Headings } from "../../Shared/Fonts/Fonts";
import { DataGrid } from "@mui/x-data-grid";
import { IconButton, Menu, MenuItem } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import search from "../../Assets/search.svg";
import Edit from "../../Assets/Edit.svg";
import { Modal, Button, Box } from "@mui/material";

export const Sites = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {
		loading,
		count,
		value: sitesData,
	} = useSelector((state) => state.sites);
	const { user } = useSelector((state) => state.auth);
	const [searchQuery, setSearchQuery] = useState("");
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const [sortModel, setSortModel] = useState([
		{ field: "createdAt", sort: "desc" },
	]);

	const theme = useTheme();
	const isSmallScreen = useMediaQuery("(max-width:768px)");

	const sites = Array.isArray(sitesData)
		? sitesData.map((site) => ({
				id: site.site_id,
				name: site.site_name,
				location: site.site_location,
				sector: site.site_sector,
				status: site.site_status,
				sensorsCount: parseInt(site.total_sensors),
				floorsCount: parseInt(site.total_floors),
				doorsCount: parseInt(site.total_doors),
		  }))
		: [];

	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
		setPaginationModel({ ...paginationModel, page: 0 });
	};

	useEffect(() => {
		if (user != null) {
			const action = user?.organizationId
				? getSitesOfOrganization
				: getSites;
			const params = {
				page: paginationModel.page + 1,
				limit: paginationModel.pageSize,
				sort: sortModel[0].sort,
				field: sortModel[0].field,
				search: searchQuery,
			};

			if (user?.organizationId) {
				params.organization = user.organizationId;
			}

			dispatch(action(params));
		}
	}, [dispatch, user, paginationModel, sortModel, searchQuery]);

	useEffect(() => {
		document.title = "Sites";
	}, []);

	const handleDelete = async (id) => {
		try {
		  const response = await dispatch(deleteSite(id)).unwrap();
		  if (response) {
			const updatedSites = sitesData.filter(site => site.site_id !== id);
			
			dispatch({
			  type: 'sites/setSites',
			  payload: {
				value: updatedSites,
				count: count - 1
			  }
			});
	
			if (updatedSites?.length === 0 && paginationModel.page > 0) {
			  setPaginationModel(prev => ({
				...prev,
				page: prev.page - 1
			  }));
			} else {
			  const action = user?.organizationId ? getSitesOfOrganization : getSites;
			  const params = {
				page: paginationModel.page + 1,
				limit: paginationModel.pageSize,
				sort: sortModel[0].sort,
				field: sortModel[0].field,
				search: searchQuery,
			  };
	
			  if (user?.organizationId) {
				params.organization = user.organizationId;
			  }
	
			  dispatch(action(params));
			}
		  }
		} catch (error) {
		  console.error('Error deleting site:', error);
		}
	  };

	const getStatusStyle = (status) => {
		switch (status) {
			case "Conforming":
				return {
					color: "#34A853",
					background: "rgba(52, 168, 83, 0.1)",
				};
			case "Warning":
				return {
					color: "#DDAD01",
					background: "rgba(221, 173, 1, 0.1)",
				};
			case "Not Conforming":
				return {
					color: "#EB4335",
					background: "rgba(235, 67, 53, 0.1)",
				};
			default:
				return {
					color: "#DDAD01",
					background: "rgba(221, 173, 1, 0.1)",
				};
		}
	};

	const columns = [
		{
			field: "name",
			headerName: "Site Name",
			flex: 1,
			minWidth: 180,
		},
		{
			field: "location",
			headerName: "Location",
			flex: 1,
			minWidth: 180,
		},
		{
			field: "sector",
			headerName: "Building Type",
			flex: 1,
			minWidth: 180,
		},
		{
			field: "sensorsCount",
			headerName: "No of Devices",
			flex: 1,
			minWidth: 180,
		},
		{
			field: "floorsCount",
			headerName: "No of Floors",
			flex: 1,
			minWidth: 180,
		},
		{
			field: "doorsCount",
			headerName: "No of Doors",
			flex: 1,
			minWidth: 180,
		},
		{
			field: "status",
			headerName: "Site Status",
			flex: 1,
			minWidth: 180,
			renderCell: (params) => {
				const status = params.row.status || "Awaiting Installation";
				const { color, background } = getStatusStyle(status);
				return (
					<div
						className="flex justify-center items-center h-8 py-2 w-full"
						style={{ backgroundColor: background }}>
						<Headings
							color={color}
							className="text-base text-dark font-futura font-extralight capitalize">
							{status || "Awaiting Installation"}
						</Headings>
					</div>
				);
			},
		},
		{
			field: "actions",
			headerName: "Actions",
			flex: 0.75,
			sortable: false,
			renderCell: (params) => (
				<ActionsDropdown
					siteId={params.row.id}
					siteDoorsCount={params.row.doorsCount}
					onDelete={handleDelete}
				/>
			),
		},
	];

	if(user?.roleId !== process.env.REACT_APP_ADMIN_ROLE_ID){ 
		columns.pop();
	}

	const columnVisibilityModel = {
		location: !isSmallScreen,
		sector: !isSmallScreen,
		sensorsCount: !isSmallScreen,
		floorsCount: !isSmallScreen,
		doorsCount: !isSmallScreen,
		// Essential columns
		name: true,
		status: true,
		actions: true,
	};

	const dataGridStyles = {
		"& .MuiDataGrid-iconButtonContainer": {
			visibility: "visible",
		},
		"& .MuiDataGrid-sortIcon": {
			opacity: "inherit !important",
		},
		"& .MuiDataGrid-cell": {
			display: "flex",
			alignItems: "center",
			padding: "0",
			color: "#212B36",
			fontFamily: "Futura PT Book !important",
			cursor: "pointer",
		},
		"& .MuiDataGrid-columnHeaders": {
			fontFamily: "Futura PT",
			fontWeight: "500",
			fontSize: 17,
			padding: "0",
		},
		"& .MuiTablePagination-select": {
			background: "#EEE",
		},
		"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
			outline: "none !important",
		},
		"& > .MuiDataGrid-columnSeparator": {
			display: "none !important",
		},
	};

	return (
		<div className="ContentWrapper">
			<div className="HeaderHeadline">
				<div className="flex gap-4">
					<h1 className="HeadlineTitle">Sites</h1>
					{(user?.roleId == process.env.REACT_APP_ADMIN_ROLE_ID ) && (
						<button
							className="bg-harmony py-1 px-4 rounded"
							onClick={() => navigate("/sites/addSite")}>
							<Headings>Add Site</Headings>
						</button>
					)}
				</div>
				<div className="flex items-center bg-white h-10 sm:w-80 w-full max-w-full border-gray-200 border border-solid rounded">
					<img src={search} alt="No search" className="mx-3" />
					<input
						className="text-gray-600 outline-none w-full pr-3"
						type="text"
						placeholder="Search..."
						value={searchQuery}
						onChange={handleSearchChange}
					/>
				</div>
			</div>

			<div className="TableWrapper">
				<DataGrid
					autoHeight
					rows={sites}
					columns={columns}
					rowCount={count || 0}
					loading={loading}
					pageSizeOptions={[5, 10, 20, 25, 50, 100]}
					paginationModel={paginationModel}
					paginationMode="server"
					onPaginationModelChange={setPaginationModel}
					sortModel={sortModel}
					onSortModelChange={(model) =>
						model?.length > 0 ? setSortModel(model) : null
					}
					getRowId={(row) => row.id}
					onRowClick={(params) => navigate(`/sites/${params.row.id}`)}
					columnVisibilityModel={columnVisibilityModel}
					// columnVisibilityModel={{
					// 	actions:
					// 		user.roles?.id ===
					// 		process.env.REACT_APP_ADMIN_ROLE_ID,
					// }}
					className="px-5 py-0 w-full bg-white !border-none !rounded-none shadow-sm !min-h-96"
					sx={dataGridStyles}
					disableColumnMenu
					disableColumnSelector
				/>
			</div>
		</div>
	);
};

// ActionsDropdown component remains the same

const ActionsDropdown = ({ siteId, siteDoorsCount, onDelete }) => {
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState(null);
	const [openModal, setOpenModal] = useState(false);

	const handleClick = (event) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleDeleteClick = () => {
		setOpenModal(true);
		setAnchorEl(null);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	const handleView = () => {
		navigate(`/sites/${siteId}`);
	};

	// const handleDeleteClick = () => {
	// 	onDelete(siteId);
	// 	setAnchorEl(false);
	// };

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleConfirmDelete = () => {
		if (siteDoorsCount === 0) {
			// Proceed to delete the site
			onDelete(siteId);
		} else {
			// Notify the user that they need to delete doors first
			alert("Please delete all doors before deleting the site.");
		}
		setOpenModal(false);
	};

	return (
		<div>
			<IconButton variant="outlined" onClick={handleClick} size="large">
				<img src={Edit} alt="Edit" />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={() => setAnchorEl(null)}>
				<MenuItem onClick={handleDeleteClick}>
					<DeleteIcon fontSize="small" />
					Delete
				</MenuItem>
				<MenuItem onClick={() => navigate(`/sites/edit/${siteId}`)}>
					<EditIcon fontSize="small" />
					Edit Site
				</MenuItem>
			</Menu>

			<Modal open={openModal} onClose={handleCloseModal}>
				<Box
					className="absolute top-1/2 left-1/2 w-[600px] shadow-sm p-6 -translate-x-1/2 -translate-y-1/2 rounded-md"
					sx={{
						bgcolor: "background.paper",
					}}>
					<h2 className="text-lg font-medium">Confirm Delete</h2>
					<p className="mb-4">
						{siteDoorsCount > 0
							? "You must delete all doors before deleting the site."
							: "Are you sure you want to delete this site?"}
					</p>

					<Box className="flex gap-2 mt-6">
						<Button
							variant="contained"
							color="error"
							onClick={handleConfirmDelete}
							className="ml-1"
							disabled={siteDoorsCount > 0}>
							Delete
						</Button>
						<Button
							variant="outlined"
							color="secondary"
							onClick={handleCloseModal}>
							Cancel
						</Button>
					</Box>
				</Box>
			</Modal>
		</div>
	);
};

const getStatus = (stat) => {
	if (stat === "Confirming") {
		return {
			color: "#34A853",
			background:
				"linear-gradient(0deg, var(--green-10, rgba(52, 168, 83, 0.10)) 0%, var(--green-10, rgba(52, 168, 83, 0.10)) 100%), var(--White, #FFF)",
		};
	} else if (stat === "Warning") {
		return {
			color: "rgba(221, 173, 1, 1)",
			background:
				"linear-gradient(0deg, var(--yellow-10, rgba(221, 173, 1, 0.10)) 0%, var(--yellow-10, rgba(221, 173, 1, 0.10)) 100%), var(--White, #FFF)",
		};
	} else if (stat === "Not Confirming") {
		return {
			color: "#EB4335",
			background:
				"linear-gradient(0deg, var(--red-10, rgba(235, 67, 53, 0.10)) 0%, var(--red-10, rgba(235, 67, 53, 0.10)) 100%), var(--White, #FFF)",
		};
	} else {
		return {
			color: "rgba(221, 173, 1, 1)",
			background:
				"linear-gradient(0deg, var(--yellow-10, rgba(221, 173, 1, 0.10)) 0%, var(--yellow-10, rgba(221, 173, 1, 0.10)) 100%), var(--White, #FFF)",
		};
	}
};
