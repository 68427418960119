import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { IconButton, Divider, CircularProgress } from "@mui/material";
import { Headings } from "../../Shared/Fonts/Fonts";
import Select from "../../Shared/CustomElement/DropDown";
import { useDispatch, useSelector } from "react-redux";
import FloorHoverComponent from "../../Shared/CustomElement/FloorTootlTip";
import AssetDrawer from "../../Components/Drawers/AssetDrawer";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getSite } from "../../Store/Features/Sites";

function FloorMap() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { siteId } = useParams();
	const { user } = useSelector((state) => state.auth);
	const matches = useMediaQuery("(max-width:768px)");
	const ref = useRef(null);

	const [hoveredFloorId, setHoveredFloorId] = useState(null);
	const [hoverPosition, setHoverPosition] = useState({ x: 0, y: 0 });
	const [isLoading, setIsLoading] = useState(true);
	const [site, setSite] = useState(null);

	useEffect(() => {
		const fetchSiteData = async () => {
			setIsLoading(true);
			try {
				const response = await dispatch(getSite(siteId)).unwrap();
				setSite(response || response?.data);
			} catch (error) {
				console.error("Failed to fetch site:", error);
			} finally {
				setIsLoading(false);
			}
		};

		if (siteId) {
			fetchSiteData();
		}
	}, [siteId, dispatch]);

	const sortedFloors =
		site?.floors?.sort(
			(a, b) => parseInt(b.floor_no) - parseInt(a.floor_no)
		) || [];

	// Fixed getStatus function for proper status handling
	const getStatus = (status) => {
		const statusStr = String(status || "").toLowerCase();

		if (statusStr === "conforming") {
			return {
				color: "#63C236",
				edge: 0xc3e1c6,
				text: "Conforming",
				background:
					"linear-gradient(0deg, rgba(52, 168, 83, 0.10) 0%, rgba(52, 168, 83, 0.10) 100%), #FFF",
			};
		} else if (statusStr === "warning") {
			return {
				color: "#FFCC49",
				edge: 0xddad01,
				text: "Warning",
				background:
					"linear-gradient(0deg, rgba(221, 173, 1, 0.10) 0%, rgba(221, 173, 1, 0.10) 100%), #FFF",
			};
		} else {
			return {
				color: "#E32B12",
				edge: 0xfdbdbd,
				text: "Not Conforming",
				background:
					"linear-gradient(0deg, rgba(235, 67, 53, 0.10) 0%, rgba(235, 67, 53, 0.10) 100%), #FFF",
			};
		}
	};

	const handleMouseInteraction = (e) => {
		if (!ref.current) return;

		const rect = ref.current.getBoundingClientRect();
		const mouseX = e.clientX - rect.left;
		const mouseY = e.clientY - rect.top;

		const buffer = 100;

		const wouldOverflowRight = mouseX + buffer > rect.width;

		let x = wouldOverflowRight ? mouseX - buffer : mouseX + 20;

		x = Math.max(0, Math.min(x, rect.width - buffer));

		const y = mouseY - 50;

		return { x, y };
	};

	if (isLoading) {
		return (
			<div className="flex items-center justify-center w-full h-screen">
				<div className="flex flex-col items-center gap-4">
					<CircularProgress size={40} className="text-harmony" />
					<span className="text-gray-600">
						Loading site details...
					</span>
				</div>
			</div>
		);
	}

	if (!site) {
		return (
			<div className="flex items-center justify-center w-full h-screen">
				<div className="text-gray-600">No site data found</div>
			</div>
		);
	}

	const siteOptions = site
		? [
				{
					data: {
						id: site.id,
						name: site.name,
					},
				},
		  ]
		: [];

	return (
		<div className="ContentWrapper">
			<div className="WrapperLayout">
				<div className="AdminLeftColumn">
					<div className="HeaderHeadline flex-wrap gap-5">
						<div className="flex items-center gap-4">
							<IconButton onClick={() => navigate(-1)}>
								<KeyboardBackspaceIcon />
							</IconButton>
							<div className="flex flex-col">
								<h1 className="HeadlineTitle">{site?.name}</h1>
								<span className="text-sm text-gray-500">
									{site?.organization?.name}
								</span>
							</div>
						</div>
						<div className="flex items-center sm:flex-nowrap flex-wrap gap-6">
							<div className="flex items-center sm:flex-nowrap flex-wrap gap-3">
								<span className="text-base text-gray-500 text-nowrap">
									{site?.address}
								</span>
								<Select
									options={siteOptions}
									onChange={(e) => {
										if (e !== 0) {
											navigate(`/sites/${e}`);
										}
									}}
									value={siteId}
									title={"Current Site"}
									placeholder={"Select Site"}
									key={"name"}
									area={"sites"}
								/>
							</div>
							{user?.roleId ===
								process.env.REACT_APP_ADMIN_ROLE_ID && (
								<button
									className="bg-harmony py-1 px-4 rounded text-nowrap"
									onClick={() =>
										navigate(`/sites/${siteId}/floor/add`)
									}>
									<Headings>Add Floor</Headings>
								</button>
							)}
						</div>
					</div>

					<div className="flex flex-col justify-center items-center w-full h-full">
						<div
							ref={ref}
							onMouseLeave={() => setHoveredFloorId(null)}
							className="flex relative min-w-96 flex-col gap-4 my-10">
							{sortedFloors.map((floor) => (
								<React.Fragment key={floor.id}>
									<div
										onMouseEnter={(e) => {
											const pos =
												handleMouseInteraction(e);
											if (pos) {
												setHoveredFloorId(floor.id);
												setHoverPosition(pos);
											}
										}}
										onMouseMove={(e) => {
											const pos =
												handleMouseInteraction(e);
											if (pos) {
												setHoverPosition(pos);
											}
										}}
										onClick={() =>
											navigate(
												`/sites/${siteId}/floor/${floor.id}`
											)
										}
										className={`floor w-full relative`}>
										<div
											className="w-full relative z-10 flex items-center justify-center text-center px-4 py-2 cursor-pointer transition-all duration-200"
											style={{
												backgroundColor: getStatus(
													floor.status
												).color,
												minHeight: "38px",
												borderRadius: "4px",
											}}>
											<span className="font-medium">
												{floor.floor_no}
											</span>
										</div>
										{hoveredFloorId === floor.id && (
											<div className="absolute z-50">
												<FloorHoverComponent
													floorId={floor.id}
													position={hoverPosition}
													data={floor}
													matches={matches}
													setHoveredFloorId={
														setHoveredFloorId
													}
												/>
											</div>
										)}
									</div>
									{floor.floor_no === "0" && (
										<Divider
											sx={{ borderBottomWidth: 10 }}
										/>
									)}
								</React.Fragment>
							))}
						</div>
					</div>
				</div>

				<div className="AdminRightColumn">
					<AssetDrawer
						siteInfo={{
							siteName: site?.name,
							organization: site?.organization?.name,
							totalDoors: site?.doorsCount,
							totalSensors: site?.sensorsCount,
							totalFloors: site?.floorsCount,
							sector: site?.sector,
							address: site?.address,
						}}
					/>
				</div>
			</div>
		</div>
	);
}

export default FloorMap;
