import { createSlice } from "@reduxjs/toolkit";
import { postApi, getApi, deleteApi, putApi } from "../../Utils/serviceWorker";
import { createAsyncThunk } from "@reduxjs/toolkit";
import jsCookie from "js-cookie";
import { toast } from "react-toastify";

export const getSensors = createAsyncThunk(
	"sensors/get",
	async (credentials, thunkAPI) => {
		try {
			const response = await getApi(
				`sensors?page=${credentials.page}&limit=${credentials.limit}&search=${credentials.search}`,
				true
			);
		
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const getSensorByCode = createAsyncThunk(
    'sensors/getSensorByCode',
    async ({ qr_code }, { rejectWithValue }) => {
        try {
            const response = await getApi(`sensors?qr_code=${qr_code}&commission_flag=0`, true);
            const sensor = response
            if (!sensor) {
                throw new Error('No sensor found with this code');
            }
            return sensor;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const getFloorOdSensor = createAsyncThunk(
	"floor/get/sensor",
	async (credentials, thunkAPI) => {
		try {
			const response = await getApi(`floors/${credentials}`, true);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const getSensor = createAsyncThunk(
	"sensor/get",
	async (credentials, thunkAPI) => {
		try {
			const response = await getApi(`sensors/${credentials}`, true);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const addSensor = createAsyncThunk(
	"sensor/add",
	async (credentials, thunkAPI) => {
		try {
			const response = await postApi(`sensors`, true, credentials);

			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const updateSensor = createAsyncThunk(
	"sensor/update",
	async (credentials, thunkAPI) => {
		try {
			const response = await putApi(
				`sensors/${credentials?.id}`,
				true,
				credentials
			);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const deleteSensor = createAsyncThunk(
	"sensor/delete",
	async (credentials, thunkAPI) => {
		try {
			const response = await deleteApi(`sensors/${credentials}`, true);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);
export const getSensorsbyDoor = createAsyncThunk(
	"sensors/door",
	async (credentials, thunkAPI) => {
		try {
			const response = await getApi(
				`sensors?door=${credentials}&field=createdAt&sort=desc`,
				true
			);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const getSensorOfOrg = createAsyncThunk(
	"sensors/org",
	async (credentials, thunkAPI) => {
		try {
			const response = await getApi(
				`sites/sensoragainstorganization/${credentials}`,
				true
			);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const getSensorsbyCode = createAsyncThunk(
	"sensors/Code",
	async (credentials, thunkAPI) => {
		const query =
			credentials.startsWith("hf") || credentials.startsWith("HF");
		if (query) {
			try {
				const response = await getApi(
					`doors?description=${credentials}`,
					true
				);
				return response;
			} catch (error) {
				return thunkAPI.rejectWithValue(error.response.data);
			}
		} else {
			try {
				const response = await getApi(
					`sensors?sensor_code=${credentials}`,
					true
				);
				return response;
			} catch (error) {
				return thunkAPI.rejectWithValue(error.response.data);
			}
		}
	}
);

export const scheduleDownlinkMessage = createAsyncThunk(
	"sensors/downlink",
	async (credentials, thunkAPI) => {
		try {
			const response = await postApi(
				`sensors/schedule_downlink`,
				true,
				credentials
			);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const getOrgDevice = createAsyncThunk(
	"sensors/org/ttn",
	async (credentials, thunkAPI) => {
		try {
			const response = await getApi(
				`sensors/getapplicationdevices/${credentials}`,
				true
			);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const getDeviceCommission = createAsyncThunk(
	"sensors/commission",
	async (credentials, thunkAPI) => {
		try {
			const response = await postApi(`sensors/getCommissioned`, true, {
				device_id: credentials,
			});
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

const initialState = {
	value: [],
	loading: false,
	error: null,
	count: 0,
};

export const Sensors = createSlice({
	name: "sensors",
	initialState,
	reducers: {
		reset: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(getSensors.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getSensors.fulfilled, (state, action) => {
				state.loading = false;
				state.value = action.payload.rows;
				state.count = action.payload.count;
			})
			.addCase(getSensors.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getFloorOdSensor.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getFloorOdSensor.fulfilled, (state, action) => {
				state.loading = false;
				// Update state as needed
			})
			.addCase(getFloorOdSensor.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getSensor.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getSensor.fulfilled, (state, action) => {
				state.loading = false;
				// Update state as needed
			})
			.addCase(getSensor.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(addSensor.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(addSensor.fulfilled, (state, action) => {
				state.loading = false;
				toast.success("Sensor added successfully");
			})
			.addCase(addSensor.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
				toast.error("Error adding sensor");
			})

			.addCase(updateSensor.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(updateSensor.fulfilled, (state, action) => {
				state.loading = false;
				toast.success("Sensor updated successfully");
			})
			.addCase(updateSensor.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
				toast.error("Error updating sensor");
			})

			.addCase(deleteSensor.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(deleteSensor.fulfilled, (state, action) => {
				state.loading = false;
				toast.success("Sensor deleted successfully");
			})
			.addCase(deleteSensor.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
				toast.error("Error deleting sensor");
			})

			.addCase(getSensorsbyDoor.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getSensorsbyDoor.fulfilled, (state, action) => {
				state.loading = false;
				// Update state as needed
			})
			.addCase(getSensorsbyDoor.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getSensorOfOrg.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getSensorOfOrg.fulfilled, (state, action) => {
				state.loading = false;
				state.value = action.payload.rows;
				state.count = action.payload.count;
			})
			.addCase(getSensorOfOrg.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getSensorsbyCode.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getSensorsbyCode.fulfilled, (state, action) => {
				state.loading = false;
				// Update state as needed
			})
			.addCase(getSensorsbyCode.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(scheduleDownlinkMessage.pending, (state) => {
				state.loading = true;
				state.error = null;
				toast.loading("Scheduling downlink message");
			})
			.addCase(scheduleDownlinkMessage.fulfilled, (state) => {
				toast.dismiss();
				state.loading = false;
				toast.success("Downlink message scheduled successfully");
			})
			.addCase(scheduleDownlinkMessage.rejected, (state, action) => {
				toast.dismiss();
				toast.error("Error scheduling downlink message");
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getOrgDevice.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getOrgDevice.fulfilled, (state, action) => {
				state.loading = false;
				// Update state as needed
			})
			.addCase(getOrgDevice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getDeviceCommission.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getDeviceCommission.fulfilled, (state, action) => {
				state.loading = false;
				// Update state as needed
			})
			.addCase(getDeviceCommission.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});
	},
});

export const { reset } = Sensors.actions;
export default Sensors.reducer;
