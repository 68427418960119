import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import SiteDrawer from "../Drawers/SiteDrawer";
import "../../Screens/Site/Sites";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import sortIcon from "../../Assets/sortIcon.svg";

export function SortedDescendingIcon() {
	return <img src={sortIcon} className="icon" alt="icon" />;
}

export function SortedAscendingIcon() {
	return <img src={sortIcon} className="icon" alt="icon" />;
}

export function UnsortedIcon() {
	return <img src={sortIcon} className="icon" alt="icon" />;
}

export const Table = (props) => {
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const {
		rows,
		columns,
		rowSelection,
		loading,
		unique_identifier,
		height,
		handleRowClick,
		getRowClassName,
		serverSide,
		paginationModel,
		setPaginationModel,
		matches,
		columnVisibilityModel,
	} = props;
	const [site, setSite] = useState();
	const handleEnter = (e) => {
		setSite(rows[e.currentTarget.getAttribute("data-rowindex")]);
		setOpen(true);
	};
	const handleLeave = (e) => {
		setOpen(false);
	};
	const onRowClick = (params) => {
		if (handleRowClick) {
			handleRowClick(params);
		}
	};

	return (
		<>
			<DataGrid
				rows={loading ? [] : rows}
				columns={columns}
				className="TableData"
				sx={{
					"& .MuiDataGrid-iconButtonContainer": {
						visibility: "visible",
					},
					"& .MuiDataGrid-sortIcon": {
						opacity: "inherit !important",
					},
					"& .MuiDataGrid-cell": {
						display: "flex",
						alignItems: "center",
						padding: "0",
						color: "#212B36",
						fontFamily: "Futura PT Book !important",
						cursor: "pointer",
					},
					"& .MuiDataGrid-columnHeaders": {
						fontFamily: "Futura PT",
						fontWeight: "500",
						fontSize: 17,
						padding: "0",
					},
					"& .MuiTablePagination-select": {
						background: "#EEE",
					},
					"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
						outline: "none !important",
					},
					"& > .MuiDataGrid-columnSeparator": {
						display: "none !important",
					},
				}}
				pageSizeOptions={[5, 10, 20, 25, 50, 100]}
				initialState={{
					pagination: {
						paginationModel: {
							pageSize: 10,
						},
					},
				}}
				headerCellProps={{
					tabIndex: -1,
					"aria-hidden": true,
				}}
				disableColumnMenu // Hide the column menu
				disableColumnSelector
				renderHeader={(params) => (
					<strong>
						{params.colDef.headerName}
						<ArrowDownward />
					</strong>
				)}
				pageSize
				autoHeight
				rowHeight={matches ? 74 : 54}
				loading={loading}
				onRowClick={(params, event) => {
					event.stopPropagation();
					onRowClick(params);
				}}
				columnVisibilityModel={columnVisibilityModel}
				hideSortIcons={false}
				disableRowSelectionOnClick={rowSelection}
				paginationModel={paginationModel}
				paginationMode={serverSide ? "server" : "client"}
				onPaginationModelChange={setPaginationModel}
				getRowId={
					unique_identifier
						? (row) => {
								return row.data[unique_identifier]
									? row.data[unique_identifier]
									: row.id;
						  }
						: null
				}
				slotProps={
					rowSelection && {
						row: {
							onMouseEnter: handleEnter,
							onMouseLeave: handleLeave,
						},
					}
				}
				isRowSelectable={false}
				getRowClassName={getRowClassName}
			/>
			<SiteDrawer open={open} setOpen={setOpen} site={site} />
		</>
	);
};
