import React from "react";

const GapWarningIcon = ({ color }) => (
	<svg
		width="27"
		height="27"
		viewBox="0 0 27 27"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<rect
			x="1.0594"
			y="1.0594"
			width="24.8812"
			height="24.8812"
			fill="white"
			stroke={color}
			stroke-width="2.11879"
		/>
		<rect
			x="3"
			y="3"
			width="14"
			height="21"
			fill="white"
			stroke={color}
			stroke-width="1.2"
		/>
		<circle
			cx="13.0946"
			cy="13.0946"
			r="1.27703"
			fill={color}
			stroke={color}
			stroke-width="0.364865"
		/>
		<path
			d="M17.7942 12.7942C17.6805 12.9079 17.6805 13.0921 17.7942 13.2058L19.6464 15.058C19.76 15.1716 19.9443 15.1716 20.058 15.058C20.1716 14.9443 20.1716 14.76 20.058 14.6464L18.4116 13L20.058 11.3536C20.1716 11.24 20.1716 11.0557 20.058 10.942C19.9443 10.8284 19.76 10.8284 19.6464 10.942L17.7942 12.7942ZM24.6087 13.2058C24.7224 13.0921 24.7224 12.9079 24.6087 12.7942L22.7566 10.942C22.6429 10.8284 22.4586 10.8284 22.345 10.942C22.2313 11.0557 22.2313 11.24 22.345 11.3536L23.9913 13L22.345 14.6464C22.2313 14.76 22.2313 14.9443 22.345 15.058C22.4586 15.1716 22.6429 15.1716 22.7566 15.058L24.6087 13.2058ZM18 13.291H24.4029V12.709H18V13.291Z"
			fill={color}
		/>
	</svg>
);

export default GapWarningIcon;
