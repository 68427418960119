import React, { useState, useRef } from "react";
import "./SidePanels.css";
import {  SubHeadings } from "../../Shared/Fonts/Fonts";
import { Divider } from "@mui/material";
import CanvasLoader from "../../Components/Canvas/Canvas";
import NotesModal from "../../Components/Modal/Notes";
export const AssetSidePanel = ({
	siteName,
	assetName,
	floorNumber,
	SitesLocation,
	image,
	sensors,
}) => {
	const [open, setOpen] = useState(false);
	const sidePanelAssetRef = useRef();
	console.log("sensors", sidePanelAssetRef);
	return (
		<React.Fragment>
			<div className="flex flex-col gap-8" ref={sidePanelAssetRef}>
				<div className="flex flex-col gap-4 w-full">
					<h2 className="text-lg font-medium">{siteName || ""}</h2>
					<div className="flex flex-col justify-between gap-4 w-full">
						<div className="flex gap- justify-between items-center">
							<SubHeadings className="text-base text-[#637381]">
								Floor {floorNumber || ""}
							</SubHeadings>
							<div className="flex items-center w-1 h-1 rounded-full bg-[#637381]" />
							<SubHeadings className="text-base text-[#637381]">
								{assetName || ""}
							</SubHeadings>
						</div>
						<SubHeadings className="text-base text-[#637381]">
							{SitesLocation || ""}
						</SubHeadings>
					</div>
				</div>
				<Divider className="w-full" />
				<div className="flex items-center flex-col w-full gap-8">
					{ <CanvasLoader parentRef={sidePanelAssetRef} /> }
					<img
						className="w-full h-60 border-none"
						style={{
							display: image ? null : "none",
						}}
						src={image}
					/>
				</div>
			</div>
			<NotesModal open={open} setOpen={setOpen} sensors={sensors} />
		</React.Fragment>
	);
};
