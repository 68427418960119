import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
} from "chart.js";
import 'chartjs-adapter-date-fns';
import { format } from "date-fns";

ChartJS.register(
  ArcElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Legend,
  TimeScale
);

const DynamicLineChartTemper = ({ data, chartTitle, xLabel, yLabel }) => {
  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  const labels = data.map((entry) => entry.createdAt.split("T")[0]);
  const doorSlam = data.map((entry) => entry?.doorSlamCount);
  const doorTemper = data.map((entry) => entry?.doorTamperCount);
  const reversLebels = labels.reverse();
  const reversSlams = doorSlam.reverse();
  const reverseTemper = doorTemper.reverse();
  const formattedLabels = reversLebels.map((date) => {
    return format(new Date(date), "MM/dd");
  });

  const chartData = {
    labels: formattedLabels,
    datasets: [
      {
        label: "Door Slam",
        data: reversSlams,
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        fill: false,
        pointBorderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 0,
        spanGaps: true,
      },
      {
        label: "Door Tamper",
        data: reverseTemper,
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
        fill: false,
        pointBorderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 0,
        spanGaps: true,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: chartTitle || "Door Slam and Tamper Events Over Time",
      },
      tooltip: {
        callbacks: {
          title: (context) => format(new Date(reversLebels[context[0].dataIndex]), 'yyyy-MM-dd'),
          label: (context) => `${context.dataset.label}: ${context.parsed.y}`,
        }
      }
    },
    scales: {
      x: {
        type: 'category',
        title: {
          display: true,
          text: xLabel || "Date",
        },
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          font: {
            size: 6,
            family: "Futura Pt",
          },
        },
      },
      y: {
        title: {
          display: true,
          text: yLabel || "Event Count",
        },
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  return <Line data={chartData} options={chartOptions} />;
};

export default DynamicLineChartTemper;