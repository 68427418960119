import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import jsCookie from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { profile } from "../../Store/Features/Auth";
import { logout } from "../../Screens/Auth/Logout";
import { resetReduxStore } from "../../Store/Store";

export function AccountMenu() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const { user } = useSelector((state) => state.auth);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = () => {
		logout();
		navigate("/login");
	};

	useEffect(() => {
		dispatch(profile()); // Fetch the latest profile data on mount (optional)
	}, [dispatch]);
	return (
		<React.Fragment>
			<Box className="flex items-center text-center">
				<Tooltip title="Account settings">
					<IconButton
						onClick={handleClick}
						size="small"
						aria-controls={open ? "account-menu" : undefined}
						aria-haspopup="true"
						aria-expanded={open ? "true" : undefined}>
						<Avatar
							className="w-[35px] h-[35px]"
							src={user?.avatar[0]?.publicUrl || ""} // Use updated avatar URL
						/>
					</IconButton>
				</Tooltip>
			</Box>
			<Menu
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: "visible",
						filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
						mt: 1.5,
						"& .MuiAvatar-root": {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						"&:before": {
							content: '""',
							display: "block",
							position: "absolute",
							top: "44%",
							left: -5,
							width: 10,
							height: 10,
							bgcolor: "background.paper",
							transform: "translateY(-50%) rotate(45deg)",
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{ horizontal: "left", vertical: "center" }}
				anchorOrigin={{ horizontal: "right", vertical: "center" }}>
				<MenuItem onClick={() => navigate("/profile")}>
					Settings
				</MenuItem>
				<MenuItem onClick={handleLogout}>Logout</MenuItem>
			</Menu>
		</React.Fragment>
	);
}
