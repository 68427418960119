import React, { useEffect, useRef, useState } from "react";
import "./Asset.css";

import { useParams, useNavigate } from "react-router-dom";
import {
	DoorStatusIndicators,
	SensorStatusIndicators,
} from "./StatusIndicators";
import GapWarningIcon from "../../Screens/Asset/icons/GapWarning";
import SystemStatusIcon from "../../Screens/Asset/icons/SystemStatus";
import SlamWarningIcon from "../../Screens/Asset/icons/SlamWarning";
import OrientationChangeIcon from "../../Screens/Asset/icons/OrientationChange";
import VandalishWarningIcon from "../../Screens/Asset/icons/VandalishWarning";
import SystemResetStatusIcon from "../../Screens/Asset/icons/SystemResetStatus";
import StatusMissedIcon from "../../Screens/Asset/icons/StatusMissed";
import OpenTimeoutIcon from "../../Screens/Asset/icons/OpenTimeout";

import { getNotifications } from "../../Store/Features/notifications";

import { IconButton } from "@mui/material";
import { Paper, CircularProgress } from "@mui/material";
import { Box } from "@mui/material";

import { Link, Divider } from "@mui/material";
import Select from "../../Shared/CustomElement/DropDown";
import { Headings } from "../../Shared/Fonts/Fonts";
import JsonFormatter from "react-json-formatter";
import { AssetSidePanel } from "../../Components/Drawers/AssetSidePanel";
import { useDispatch } from "react-redux";
import { getDoor } from "../../Store/Features/Door";
import { getFloor } from "../../Store/Features/Floors";
import { useSelector } from "react-redux";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { getSites } from "../../Store/Features/Sites";
import { getDoorsofFloor } from "../../Store/Features/Door";
import { getWebhooks } from "../../Store/Features/Webhook";
import { getStatusMessages } from "../../Utils/Functions/getStatusMessages";
import useMediaQuery from "@mui/material/useMediaQuery";
import Accordion from "@mui/material/Accordion";
import DynamicLineChart from "../../Components/Charts/AllTimeChart";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AdbIcon from "@mui/icons-material/Adb";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DoorCard } from "../../Components/Door/DoorCards";
import Modal from "@mui/material/Modal";
import { DoorCardTimeOut } from "../../Components/Door/DoorCardTimeOut";
import DynamicLineChartWeek from "../../Components/Charts/WeekChart";
import { formatDateToGB } from "../../Utils/Functions/utils";

export const Asset = () => {
	const navigate = useNavigate();
	const { siteId, floorId, assetId } = useParams();
	const matches = useMediaQuery("(max-width:768px)");
	const { value } = useSelector((state) => state.sites);
	const dispatch = useDispatch();

	const [sensors, setSensors] = useState([]);
	const [door, setDoor] = useState(assetId);
	const [floor, setFloor] = useState({});
	const [doors, setDoors] = useState([]);
	const [notifications, setNotification] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		document.title = "Door";
		setIsLoading(true);

		const fetchData = async () => {
			try {
				await dispatch(getSites());

				const floorResponse = await dispatch(getFloor(floorId));
				if (floorResponse.payload) {
					setFloor(floorResponse.payload);
				}

				const doorResponse = await dispatch(getDoor(assetId));
				if (doorResponse.payload) {
					setDoor(doorResponse.payload);
				}

				const doorsResponse = await dispatch(getDoorsofFloor(floorId));
				if (doorsResponse.payload?.data) {
					setDoors(doorsResponse.payload.data);
				}

				const notificationResponse = await dispatch(
					getNotifications({
						page: 0,
						limit: 8,
						id: assetId,
						query: "door",
						sort: "desc",
						field: "created_at",
					})
				);
				if (notificationResponse.payload?.rows) {
					setNotification(notificationResponse.payload.rows);
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setIsLoading(false);
			}
		};

		fetchData();
	}, [dispatch, floorId, assetId]);

	const onChangeSite = (e) => {
		if (e !== 0) {
			navigate(`/sites/${siteId}/floor/${floorId}/asset/${e}`);
			window.location.reload(false);
		}
	};

	if (isLoading) {
		return (
			<div className="flex items-center justify-center w-full h-screen">
				<div className="flex flex-col items-center gap-4">
					<CircularProgress size={40} sx={{ color: "#407AAD" }} />
					<p className="text-lg text-gray-600">Loading</p>
				</div>
			</div>
		);
	}

	return (
		<div className="ContentWrapper">
			<div className="AseetsLayout">
				<div className="AssetsLeftColumn">
					<div className="HeaderHeadline">
						<div className="flex items-center gap-4">
							<IconButton onClick={() => navigate(-1)}>
								<KeyboardBackspaceIcon />
							</IconButton>
							<h1 className="HeadlineTitle">
								{
									value.find(
										(ele) => ele.data?.site_id == siteId
									)?.data.name
								}
							</h1>
						</div>
						<div className="flex items-center">
							<Select
								options={doors}
								onChange={onChangeSite}
								value={assetId}
								title={"Doors"}
								area={"doors"}
							/>
						</div>
					</div>

					<div className="flex flex-col gap-4">
						<h1 className="HeadlineTitle">Status Report</h1>
						<div className="DoorsTwoOneColumnGrid">
							<div className="DoorFirstDiv">
								<div className="DoorsTwoColumnGrid">
									<DoorCard {...door} />
									{door?.door_status && (
										<Paper className="p-6 gap-4 flex-col flex !rounded-none !shadow-sm">
											<DoorStatusIndicators
												door={door}
												sensors={door.sensors}
											/>
										</Paper>
									)}
								</div>

								{door?.door_status && (
									<div className="DoorsTwoColumnGrid">
										<Paper className="flex flex-col h-80 p-6 pb-10 !rounded-none !shadow-sm">
											<div className="flex justify-between">
												<Headings>
													Door Utilisation
												</Headings>
												<Headings color="#407AAD">
													Weekly
												</Headings>
											</div>
											<DynamicLineChartWeek
												data={
													door?.door_utilization
														?.weekly || []
												}
												chartTitle="Dynamic Line Chart"
												xLabel="Date"
												yLabel="Value"
											/>
										</Paper>
										<Paper className="flex flex-col h-80 p-6 pb-10 !rounded-none !shadow-sm">
											<div className="flex justify-between">
												<Headings>
													Door Utilisation
												</Headings>
												<Headings color="#407AAD">
													Lifetime
												</Headings>
											</div>
											<DynamicLineChart
												data={
													door?.door_utilization
														?.all_time || []
												}
												chartTitle="Dynamic Line Chart"
												xLabel="Date"
												yLabel="Value"
											/>
										</Paper>
									</div>
								)}

								<div className="DoorsTwoColumnGrid">
									{door.sensors?.map((sensor) => (
										<TopSensor
											key={
												sensor.id || sensor.sensor_code
											}
											{...sensor}
										/>
									))}
								</div>
							</div>

							{door?.door_status && (
								<div className="StatusData">
									<Paper className="flex flex-col h-full p-4 pb-10 !rounded-none !shadow-sm">
										<div className="flex justify-between w-full items-center">
											<h2 className="text-lg font-medium">
												Event History
											</h2>
											<button
												className="!bg-harmony py-1 px-4 rounded"
												onClick={() =>
													navigate(
														`/events?doorId=${assetId}`
													)
												}>
												<Headings>
													View All Events
												</Headings>
											</button>
										</div>
										<div className="left-top-right-bottom-content mt-6">
											<NotificationSection
												notifications={notifications}
											/>
										</div>
									</Paper>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="AssetsRightColumn">
					<AssetSidePanel
						siteName={floor?.site?.name}
						assetName={door?.description}
						floorNumber={floor?.floor_number}
						SitesLocation={floor?.site?.address}
						image={
							door.door_image
								? door.door_image?.length > 0
									? door.door_image[0].publicUrl
									: null
								: null
						}
						sensors={sensors}
					/>
				</div>
			</div>
		</div>
	);
};

const TopSensor = ({ ...props }) => {
	const navigate = useNavigate();
	const [isOpen, setIsOpen] = useState(false);
	const { siteId, floorId, assetId } = useParams();
	const {
		status,
		location,
		sensor_code,
		id,
		qr_code,
		allStatus,
		latestData,
		last_reading,
	} = props;
	const { user } = useSelector((state) => state.auth);
	const [code, setCode] = useState();
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const matches = useMediaQuery("(max-width:768px)");

	const calculateExpectedTime = (lastReadingTime, frequency = 60) => {
		if (!lastReadingTime) return null;

		const lastReading = new Date(lastReadingTime);
		const expectedTime = new Date(lastReading.getTime() + frequency * 1000);

		return expectedTime;
	};

	const formatTime = (dateString) => {
		if (!dateString) return "N/A";

		const date = new Date(dateString);
		const hours = date.getHours().toString().padStart(2, "0");
		const minutes = date.getMinutes().toString().padStart(2, "0");
		const formattedDate = formatDateToGB(date.toISOString().split("T")[0]);

		return `${hours}:${minutes} - ${formattedDate}`;
	};

	const expectedTime = calculateExpectedTime(last_reading);

	return (
		<>
			<div className="reportCards flex flex-col gap-[5px]">
				<Paper className="py-5 px-6 border border-solid border-[#eeeeee] gap-4 flex-col flex !rounded-none !shadow-sm">
					{!matches && (
						<div className="flex justify-between">
							<h2 className="capitalize text-lg font-medium mb-2">
								{location} Sensor
							</h2>
							<Headings className="capitalize text-base font-extrabold text-[#637381]">
								{qr_code}
							</Headings>
						</div>
					)}
					<div className="flex justify-between flex-wrap">
						{user?.roleId ==
							process.env.REACT_APP_ADMIN_ROLE_ID && (
							<div className="flex justify-between gap-[10px] w-full">
								<div className="IconHold flex-wrap flex items-center justify-between gap-3 w-full">
									<SensorStatusIndicators
										sensor={{ props }}
									/>
								</div>
							</div>
						)}
					</div>
					<div className="DoorReport-down">
						<Headings className="text-base text-[#637381] font-medium">
							Reports
						</Headings>
						<div className="DoorReport-Upper">
							<Headings className="text-base text-[#637381] font-medium">
								Last
							</Headings>
							<Headings
								fontSize={16}
								color="rgba(33, 43, 54, 1)"
								fontWeigth={450}>
								{formatTime(last_reading)}
							</Headings>
						</div>
						<div className="DoorReport-Upper">
							<Headings
								fontSize={16}
								color="rgba(99, 115, 129, 1)"
								fontWeigth={450}>
								Next
							</Headings>
							<Headings
								fontSize={16}
								color="rgba(33, 43, 54, 1)"
								fontWeigth={450}>
								{formatTime(expectedTime)}
							</Headings>
						</div>
					</div>
				</Paper>
			</div>
		</>
	);
};

export default TopSensor;

const getStatus = (stat) => {
	let status = parseInt(stat);
	if (status >= 200 && status < 300) {
		return {
			color: "#34A853",
			background: "rgba(52, 168, 83, 0.1)",
		};
	} else if (status >= 300 && status < 400) {
		return {
			color: "rgba(221, 173, 1, 1)",
			background: "rgba(221, 173, 1, 0.1)",
		};
	} else if (status >= 400 && status < 500) {
		return {
			color: "#EB4335",
		};
	} else {
		return {
			color: "#34A853",
			background: "rgba(52, 168, 83, 0.1)",
		};
	}
};

function BasicModal({ open, setOpen, id }) {
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const { value, loading } = useSelector((state) => state.webhooks);
	const [data, setData] = useState({});
	const dispatch = useDispatch();

	useEffect(() => {
		if (id) {
			dispatch(
				getWebhooks(`limit=1&Sensor=${id}&field=createdAt&sort=desc`)
			).then((res) => {
				if (!res.error) {
					if (res.payload.rows?.length > 0) {
						setData(res.payload.rows[0]);
					}
				}
			});
		}
	}, [id]);

	const jsonStyle = {
		propertyStyle: { color: "red" },
		stringStyle: { color: "green" },
		numberStyle: { color: "darkorange" },
	};

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description">
			<Box className="absolute shadow-sm top-1/2 left-1/2 h-[400px] w-[650px] overflow-scroll p-6 -translate-x-1/2 -translate-y-1/2 bg-white">
				{loading ? (
					<Box className="w-full h-full flex justify-center items-center">
						{" "}
						<CircularProgress size={16} sx={{ color: "white" }} />
					</Box>
				) : data != {} ? (
					<JsonFormatter
						json={data}
						tabWith={4}
						jsonStyle={jsonStyle}
					/>
				) : (
					"No Data available"
				)}
			</Box>
		</Modal>
	);
}

export const NotificationSection = ({ notifications }) => {
	const navigate = useNavigate();

	const getNotificationIcon = (type, statusColor) => {
		const notificationType = type?.toLowerCase() || "";
		switch (notificationType) {
			case "slam status":
				return <SlamWarningIcon color={statusColor} />;
			case "vandalism status":
				return <VandalishWarningIcon color={statusColor} />;
			case "missed transmission status":
				return <StatusMissedIcon color={statusColor} />;
			case "orientation status":
				return <OrientationChangeIcon color={statusColor} />;
			case "gap status":
				return <GapWarningIcon color={statusColor} />;
			case "timeout status":
				return <OpenTimeoutIcon color={statusColor} />;
			case "system status":
				return <SystemStatusIcon color={statusColor} />;
			default:
				return <SystemResetStatusIcon color={statusColor} />;
		}
	};

	const getStatusStyle = (status) => {
		return status?.toLowerCase()?.includes("warning")
			? "#FFCC49"
			: "#EB4335";
	};

	return (
		<div className="flex flex-col">
			{notifications?.map((item) => {
				const statusColor = getStatusStyle(item.status);

				return (
					<div key={item.id} className="flex flex-col">
						<div
							className="flex items-start gap-3 py-4 rounded hover:bg-gray-50 cursor-pointer"
							onClick={() => navigate("#")}>
							<div className="mt-[6px]">
								{getNotificationIcon(item.type, statusColor)}
							</div>

							<div className="flex-1">
								<Headings className="text-sm text-gray-800 font-medium">
									{item.message}
								</Headings>
								<div className="flex justify-between items-center mt-1">
									<Headings className="text-xs text-gray-500">
										{new Date(
											item.created_at || item.createdAt
										).toLocaleString("en-GB", {
											hour: "2-digit",
											minute: "2-digit",
											day: "2-digit",
											month: "2-digit",
											year: "numeric",
										})}
									</Headings>
								</div>
							</div>
						</div>
						<Divider />
					</div>
				);
			})}

			{(!notifications || notifications.length === 0) && (
				<div className="flex justify-center items-center p-4 text-gray-500">
					No notifications found
				</div>
			)}
		</div>
	);
};

// const styles = `
// 	.notification-item {
// 	  transition: all 0.2s ease-in-out;
// 	}

// 	.notification-item:hover {
// 	  background-color: rgba(0, 0, 0, 0.02);
// 	}

// 	.icon-container {
// 	  display: flex;
// 	  align-items: center;
// 	  justify-content: center;
// 	  width: 32px;
// 	  height: 32px;
// 	  border-radius: 50%;
// 	}
//   `;
