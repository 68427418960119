import React from 'react';
import { Grid, TextField, MenuItem } from '@mui/material';

const AssigneeDropdown = ({ 
  formData, 
  handleChange, 
  errors, 
  installationManagers 
}) => {
  const handleAssigneeChange = (event) => {
    const selectedId = event.target.value;
    
    handleChange({
      target: {
        name: 'assignee',
        value: selectedId 
      }
    });
  };

  return (
    <Grid item xs={12} sm={12} md={6} lg={6}>
      <TextField
        select
        label="Assign To"
        variant="filled"
        fullWidth
        name="assignee"
        value={typeof formData.assignee === 'object' ? formData.assignee?.id : formData.assignee || ""}
        onChange={handleAssigneeChange}
        error={!!errors.assignee}
        helperText={errors.assignee}
        InputLabelProps={{ shrink: true }}
      >
        {installationManagers?.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.firstName} {option.lastName || ""}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  );
};

export default AssigneeDropdown;