import React from "react";

const StatusMissedIcon = ({ color }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="26.424"
		height="12.828"
		viewBox="0 0 26.424 12.828">
		<g id="icon-missedstatus" transform="translate(-0.586 -5.586)">
			<path
				id="Path_17"
				data-name="Path 17"
				d="M22,7l-8.5,8.5-5-5L2,17"
				fill="none"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
			/>
			<path
				id="Path_18"
				data-name="Path 18"
				d="M16,7h6v6"
				fill="none"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
			/>
			<path
				id="Path_31"
				data-name="Path 31"
				d="M10,17h.01"
				transform="translate(16 0.02)"
				fill="none"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
			/>
			<path
				id="Path_32"
				data-name="Path 32"
				d="M10,7v6.02"
				transform="translate(16 0)"
				fill="none"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
			/>
		</g>
	</svg>
);

export default StatusMissedIcon;
