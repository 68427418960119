import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

export const FilterControls = ({
	selectedOrg,
	selectedSite,
	selectedFloor,
	selectedDoor,
	onOrgChange,
	onSiteChange,
	onFloorChange,
	onDoorChange,
	organizations,
	sites,
	floors,
	doors,
	user,
}) => {
	const selectStyles = {
		".MuiSelect-select": {
			padding: "8px 32px 8px 14px",
			backgroundColor: "white",
			border: "1px solid #E5E7EB",
			borderRadius: "4px",
			minWidth: "200px",
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
			"&:focus": {
				borderColor: "#2563EB",
				backgroundColor: "white",
			},
			"&.MuiSelect-outlined": {
				width: "250px",
				display: "block",
			},
		},
		".MuiOutlinedInput-notchedOutline": {
			border: "none",
		},
		width: "250px",
		minWidth: "250px",
		"& .MuiMenuItem-root": {
			paddingLeft: "10px",
		},
	};

	const menuProps = {
		PaperProps: {
			style: {
				minWidth: "200px",
				maxHeight: "400px",
			},
			sx: {
				"& .MuiMenuItem-root": {
					paddingLeft: "10px", // Add padding to dropdown items
				},
			},
		},
		anchorOrigin: {
			vertical: "bottom",
			horizontal: "left",
		},
		transformOrigin: {
			vertical: "top",
			horizontal: "left",
		},
	};

	const getFloorDisplayName = (floor) => {
		if (!floor?.data) return "";
		const { floor_no, description } = floor.data;
		return `${description} ${floor_no}`;
	};

	const inputLabelStyles = {
		position: "absolute",
		visibility: "visible",
		transition: "none",
		"&.Mui-focused, &.MuiFormLabel-filled": {
			visibility: "hidden",
		},
	};

	return (
		<div className="flex flex-wrap gap-x-4 gap-y-3 mb-4">
			{!user?.organizationId && (
				<div className="flex flex-col gap-1">
					<FormControl size="small">
						<InputLabel id="org-select-label" sx={inputLabelStyles}>
							Organization
						</InputLabel>

						<Select
							labelId="org-select-label"
							id="org-select"
							value={selectedOrg}
							onChange={onOrgChange}
							label="Organization"
							MenuProps={menuProps}
							sx={selectStyles}>
							<MenuItem
								disabled
								style={{ minWidth: "250px", color: "#aaa" }}>
								<span
									style={{
										minWidth: "234px",
										display: "block",
									}}>
									Organization
								</span>
							</MenuItem>
							{organizations?.map((org) => (
								<MenuItem
									key={org.id}
									value={org.id}
									style={{ minWidth: "250px" }}>
									<span
										style={{
											minWidth: "234px",
											display: "block",
										}}>
										{org.name}
									</span>
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
			)}

			{(selectedOrg || user?.organizationId) && (
				<div className="flex flex-col">
					<FormControl size="small" padding="10px">
						<InputLabel
							id="site-select-label"
							sx={inputLabelStyles}>
							Site
						</InputLabel>
						<Select
							labelId="site-select-label"
							id="site-select"
							value={selectedSite}
							onChange={onSiteChange}
							label="Site"
							sx={selectStyles}
							MenuProps={menuProps}>
							<MenuItem
								disabled
								style={{ minWidth: "250px", color: "#aaa" }}>
								<span
									style={{
										minWidth: "234px",
										display: "block",
									}}>
									Site
								</span>
							</MenuItem>
							{sites?.map((site) => (
								<MenuItem
									key={site.site_id}
									value={site.site_id}
									style={{ minWidth: "250px" }}>
									<span
										style={{
											minWidth: "234px",
											display: "block",
										}}>
										{site.site_name}
									</span>
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
			)}

			{selectedSite && (
				<div className="flex flex-col gap-1">
					<FormControl size="small">
						<InputLabel
							id="floor-select-label"
							sx={inputLabelStyles}>
							Floor
						</InputLabel>
						<Select
							labelId="floor-select-label"
							id="floor-select"
							value={selectedFloor}
							onChange={onFloorChange}
							label="Floor"
							sx={selectStyles}
							MenuProps={menuProps}>
							<MenuItem
								disabled
								style={{ minWidth: "250px", color: "#aaa" }}>
								<span
									style={{
										minWidth: "234px",
										display: "block",
									}}>
									Floor
								</span>
							</MenuItem>
							{floors?.map((floor) => (
								<MenuItem
									key={floor.data.id}
									value={floor.data.id}
									style={{ minWidth: "250px" }}>
									<span
										style={{
											minWidth: "234px",
											display: "block",
										}}>
										{getFloorDisplayName(floor)}
									</span>
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
			)}

			{selectedFloor && (
				<div className="flex flex-col gap-1">
					<FormControl size="small">
						<InputLabel
							id="door-select-label"
							sx={inputLabelStyles}>
							Door
						</InputLabel>
						<Select
							labelId="door-select-label"
							id="door-select"
							value={selectedDoor}
							onChange={onDoorChange}
							label="Door"
							sx={selectStyles}
							MenuProps={menuProps}>
							<MenuItem
								disabled
								style={{ minWidth: "250px", color: "#aaa" }}>
								<span
									style={{
										minWidth: "234px",
										display: "block",
									}}>
									Door
								</span>
							</MenuItem>
							{doors?.map((door) => (
								<MenuItem
									key={door.data.id}
									value={door.data.id}
									style={{ minWidth: "250px" }}>
									<span
										style={{
											minWidth: "234px",
											display: "block",
										}}>
										{door.data.description ||
											"Unknown Door"}
									</span>
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
			)}
		</div>
	);
};
