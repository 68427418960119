import React from "react";

const OrientationChangeIcon = ({ color }) => (
	<svg
		width="28"
		height="27"
		viewBox="0 0 28 27"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<rect
			x="8.8565"
			y="0.912162"
			width="17.8784"
			height="25.1757"
			fill="white"
			stroke={color}
			strokeWidth="1.82432"
		/>
		<path
			d="M16.2057 9.01944L17.0291 10.7423C17.171 11.0391 17.0168 11.3826 16.6848 11.5095C16.3528 11.6363 15.9687 11.4985 15.8268 11.2017L14.3737 8.16122C14.2318 7.86438 14.3859 7.52091 14.7179 7.39406L18.1184 6.09478C18.4504 5.96793 18.8346 6.10573 18.9764 6.40257C19.1183 6.69942 18.9642 7.04289 18.6322 7.16974L16.6634 7.92199C22.8526 10.3586 26.8595 14.4101 25.5248 17.3843C24.1309 20.4908 17.6172 20.8454 10.9565 18.456C4.29587 16.0666 -0.137347 11.7849 1.25663 8.67845C1.98795 7.04869 4.1359 6.15129 7.09234 6.05685C7.45315 6.04533 7.75609 6.29751 7.76898 6.62011C7.78187 6.94272 7.49983 7.21358 7.13902 7.22511C4.64305 7.30483 2.96765 8.00481 2.47004 9.11375C1.44414 11.4 5.37122 15.1928 11.4434 17.3711C17.5155 19.5494 23.2855 19.2352 24.3114 16.949C25.2876 14.7736 21.7793 11.2341 16.2057 9.01944Z"
			fill={color}
			stroke={color}
			strokeWidth="0.72973"
		/>
	</svg>
);

export default OrientationChangeIcon;
