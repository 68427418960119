import React, { useEffect, useState } from "react";
import DynamicLineChartWeek from "../../Components/Charts/WeekChart";
import DynamicLineChart from "../../Components/Charts/AllTimeChart";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import "./Asset.css";
import { Headings } from "../../Shared/Fonts/Fonts";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { IconButton, Paper, CircularProgress } from "@mui/material";
import { getWebhooks } from "../../Store/Features/Webhook";
import DynamicLineChartMsgType from "../../Components/Charts/msgType";
import DynamicLineChartTemper from "../../Components/Charts/doorSlamTemper";
import doorOpen from "../../Assets/rdo.jpg";
import doorClose from "../../Assets/gdc.jpg";
import DynamicLineChartMagnetometer from "../../Components/Charts/magnaticReading";
import DynamicLineChartAccelerometer from "../../Components/Charts/acclerometer";
import DynamicLineChartTemperature from "../../Components/Charts/Temp";
import DynamicLineChartVBat from "../../Components/Charts/VoltageBat";
import DynamicLineChartRSSI from "../../Components/Charts/RSSI";
import DynamicLineChartSpreading from "../../Components/Charts/Spreading";

export const AssetInspection = () => {
	const { device_code } = useParams();
	const { value, loading } = useSelector((state) => state.webhooks);
	const [data, setData] = useState([]);
	const [weekly, setWeekluData] = useState([]);
	const [all, setAll] = useState([]);
	const now = new Date();

	const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
	const oneWeekAgoISO = oneWeekAgo.toISOString();

	const navigate = useNavigate();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getWebhooks(`Sensor=${device_code}`)).then((res) => {
			setAll(res.payload.rows);
		});

		dispatch(
			getWebhooks(
				`Sensor=${device_code}&createdAtRange=${oneWeekAgoISO}&createdAtRange=&`
			)
		).then((res) => {
			setWeekluData(res.payload.rows);
		});

		dispatch(
			getWebhooks(
				`limit=1&Sensor=${device_code}&field=createdAt&sort=desc`
			)
		).then((res) => {
			setData(res.payload.rows);
		});
	}, []);

	return (
		<div className="DevicePage">
			<div className="TitleBack">
				<IconButton onClick={() => navigate(-1)}>
					<KeyboardBackspaceIcon />
				</IconButton>
				<Headings fontSize={18}>{device_code} Report Overview</Headings>
			</div>
			<div className="deviceDataArea">
				<div className="flex flex-wrap gap-4 flex-col w-[70%]">
					<div style={{ display: "flex", gap: 10, flexWrap: "wrap" }}>
						<GeneralPaper
							title={"Msg#"}
							data={
								data[0]?.Data.uplink_message?.decoded_payload
									?.msgSeqNo
							}
						/>
						<GeneralPaper
							title={"Battery Status (@20C)"}
							data={data[0]?.bat_status}
						/>
						<GeneralPaper
							title={"Reset Status"}
							data={
								data[0]?.Data.uplink_message?.decoded_payload
									?.resetAck == 0
									? "Normal"
									: "Reset"
							}
						/>
						<GeneralPaper
							title={"Temperature"}
							data={`${data[0]?.Data.uplink_message?.decoded_payload?.temperature100c} C`}
						/>
						<GeneralPaper
							title={"Msg Type Last"}
							data={getMegStatus(
								data[0]?.Data.uplink_message?.decoded_payload
									?.msgType
							)}
						/>
						<GeneralPaper
							title={"Voltage"}
							data={
								data[0]?.Data.uplink_message?.decoded_payload
									?.vbatmv
							}
						/>
						<GeneralPaper
							title={"Door Timeout"}
							data={`${data[0]?.Data.uplink_message?.decoded_payload?.doorOpenTimeoutInMinutes} m`}
						/>
						<GeneralPaper
							title={"Report Interval"}
							data={`${data[0]?.Data.uplink_message?.decoded_payload?.reportingIntervalInSeconds} s`}
						/>
					</div>

					<Paper
						sx={{
							display: "flex",
							flexDirection: "column",
							height: 300,
							padding: "15px",
							width: "100%",
						}}>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
							}}>
							<Headings>Door Utilisation</Headings>
							<Headings color="#407AAD">Weekly</Headings>
						</div>

						<DynamicLineChartWeek
							data={weekly}
							chartTitle="Dynamic Line Chart"
							xLabel="Date"
							yLabel="Value"
						/>
					</Paper>
					<Paper
						sx={{
							display: "flex",
							flexDirection: "column",
							height: 300,
							padding: "15px",
							width: "100%",
						}}>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
							}}>
							<Headings>Door Utilisation</Headings>
							<Headings color="#407AAD">Lifetime</Headings>
						</div>

						<DynamicLineChart
							data={all}
							chartTitle="Dynamic Line Chart"
							xLabel="Date"
							yLabel="Value"
						/>
					</Paper>
					<Paper
						sx={{
							display: "flex",
							flexDirection: "column",
							height: 300,
							padding: "15px",
							width: "100%",
						}}>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
							}}>
							<Headings>Message Type</Headings>
							<Headings color="#407AAD">Weekly</Headings>
						</div>

						<DynamicLineChartMsgType
							data={weekly}
							chartTitle="Dynamic Line Chart"
							xLabel="Date"
							yLabel="Value"
						/>
					</Paper>
					<Paper
						sx={{
							display: "flex",
							flexDirection: "column",
							height: 300,
							padding: "15px",
							width: "100%",
						}}>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
							}}>
							<Headings>Door Slam and Door Temper</Headings>
							<Headings color="#407AAD">Weekly</Headings>
						</div>

						<DynamicLineChartTemper
							data={weekly}
							chartTitle="Dynamic Line Chart"
							xLabel="Date"
							yLabel="Value"
						/>
					</Paper>
					<Paper
						sx={{
							display: "flex",
							flexDirection: "column",
							height: 300,
							padding: "15px",
							width: "100%",
						}}>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
							}}>
							<Headings>Magnetometer</Headings>
							<Headings color="#407AAD">Weekly</Headings>
						</div>

						<DynamicLineChartMagnetometer
							data={weekly}
							chartTitle="Dynamic Line Chart"
							xLabel="Date"
							yLabel="Value"
						/>
					</Paper>
					<Paper
						sx={{
							display: "flex",
							flexDirection: "column",
							height: 300,
							padding: "15px",
							width: "100%",
						}}>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
							}}>
							<Headings>Accelerometer</Headings>
							<Headings color="#407AAD">Weekly</Headings>
						</div>

						<DynamicLineChartAccelerometer
							data={weekly}
							chartTitle="Dynamic Line Chart"
							xLabel="Date"
							yLabel="Value"
						/>
					</Paper>
					<Paper
						sx={{
							display: "flex",
							flexDirection: "column",
							height: 300,
							padding: "15px",
							width: "100%",
						}}>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
							}}>
							<Headings>Temperature</Headings>
							<Headings color="#407AAD">Weekly</Headings>
						</div>

						<DynamicLineChartTemperature
							data={weekly}
							chartTitle="Dynamic Line Chart"
							xLabel="Date"
							yLabel="Value"
						/>
					</Paper>
					<Paper
						sx={{
							display: "flex",
							flexDirection: "column",
							height: 300,
							padding: "15px",
							width: "100%",
						}}>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
							}}>
							<Headings>VBat</Headings>
							<Headings color="#407AAD">Weekly</Headings>
						</div>

						<DynamicLineChartVBat
							data={weekly}
							chartTitle="Dynamic Line Chart"
							xLabel="Date"
							yLabel="Value"
						/>
					</Paper>
					<Paper
						sx={{
							display: "flex",
							flexDirection: "column",
							height: 300,
							padding: "15px",
							width: "100%",
						}}>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
							}}>
							<Headings>RSSI</Headings>
							<Headings color="#407AAD">Weekly</Headings>
						</div>

						<DynamicLineChartRSSI
							data={weekly}
							chartTitle="Dynamic Line Chart"
							xLabel="Date"
							yLabel="Value"
						/>
					</Paper>
					<Paper
						sx={{
							display: "flex",
							flexDirection: "column",
							height: 300,
							padding: "15px",
							width: "100%",
						}}>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
							}}>
							<Headings>Spreading Factor</Headings>
							<Headings color="#407AAD">Weekly</Headings>
						</div>

						<DynamicLineChartSpreading
							data={weekly}
							chartTitle="Dynamic Line Chart"
							xLabel="Date"
							yLabel="Value"
						/>
					</Paper>
				</div>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 15,
					}}>
					<Paper
						sx={{
							display: "flex",
							flexDirection: "column",
							height: 300,
							padding: "15px",
							width: "100%",
						}}>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
							}}>
							<Headings>Door State (Open/Closed)</Headings>
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								width: "100%",
							}}>
							{data[0]?.Data.uplink_message?.decoded_payload
								?.doorStatus == 1 ? (
								<img
									src={doorClose}
									style={{
										width: "300px",
										height: "100%",
										border: "0px solid black",
									}}
								/>
							) : (
								<img
									src={doorOpen}
									style={{
										width: "300px",
										height: "100%",
										border: "0px solid black",
									}}
								/>
							)}
						</div>
					</Paper>

					<GeneralPaper
						title={"Device Up Time"}
						data={`${data[0]?.Data.uplink_message?.decoded_payload?.deviceTimeInSeconds} s`}
						width={"100%"}
					/>

					<GeneralPaper
						title={"Door Open Count"}
						data={
							data[0]?.Data.uplink_message?.decoded_payload
								?.doorOpenCount
						}
						width={"100%"}
					/>
					<GeneralPaper
						title={"Door Slam Count"}
						data={
							data[0]?.Data.uplink_message?.decoded_payload
								?.doorSlamCount
						}
						width={"100%"}
					/>
					<GeneralPaper
						title={"Door Temper Count"}
						data={
							data[0]?.Data.uplink_message?.decoded_payload
								?.doorTamperCount
						}
						width={"100%"}
					/>
					<GeneralPaper
						title={"Magnetometer X"}
						data={
							data[0]?.Data.uplink_message?.decoded_payload
								?.magnetometerX
						}
						width={"100%"}
					/>
					<GeneralPaper
						title={"Magnetometer Y"}
						data={
							data[0]?.Data.uplink_message?.decoded_payload
								?.magnetometerY
						}
						width={"100%"}
					/>
					<GeneralPaper
						title={"Magnetometer Z"}
						data={
							data[0]?.Data.uplink_message?.decoded_payload
								?.magnetometerZ
						}
						width={"100%"}
					/>
					<GeneralPaper
						title={"Accelerometer X"}
						data={`${Math.round(
							data[0]?.Data.uplink_message?.decoded_payload
								?.accelerometerX / 1000
						)} g`}
						width={"100%"}
					/>
					<GeneralPaper
						title={"Accelerometer Y"}
						data={`${Math.round(
							data[0]?.Data.uplink_message?.decoded_payload
								?.accelerometerY / 1000
						)} g`}
						width={"100%"}
					/>
					<GeneralPaper
						title={"Accelerometer Z"}
						data={`${Math.round(
							data[0]?.Data.uplink_message?.decoded_payload
								?.accelerometerZ / 1000
						)} g`}
						width={"100%"}
					/>
					<GeneralPaper
						title={"Temperature"}
						data={
							data[0]?.Data.uplink_message?.decoded_payload
								?.temperature100c
						}
						width={"100%"}
					/>
					<GeneralPaper
						title={"Temperature Maximum"}
						data={
							data[0]?.Data.uplink_message?.decoded_payload
								?.temperatureMax100c
						}
						width={"100%"}
						color={"red"}
					/>
					<GeneralPaper
						title={"Temperature Minimum"}
						data={
							data[0]?.Data.uplink_message?.decoded_payload
								?.temperatureMin100c
						}
						width={"100%"}
						color={"blue"}
					/>
					<GeneralPaper
						title={"Spreading Factor"}
						data={
							data[0]?.Data.uplink_message?.settings?.data_rate
								?.lora?.spreading_factor
						}
						width={"100%"}
					/>
					<GeneralPaper
						title={"SNR"}
						data={`${data[0]?.Data.uplink_message?.rx_metadata[0]?.snr} dB`}
						width={"100%"}
					/>
					<GeneralPaper
						title={"RSSI"}
						data={`${data[0]?.Data.uplink_message?.rx_metadata[0]?.rssi} dBm`}
						width={"100%"}
					/>

					<GapPaper
						title={"Engineering Notes"}
						data={data}
						width={"100%"}
					/>
				</div>
			</div>
		</div>
	);
};

const GeneralPaper = ({ title, data, width, color }) => {
	return (
		<Paper
			className="title"
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				width: width ? width : 250,
				height: 150,
				padding: "15px",
				boxSizing: "border-box",
			}}>
			<Headings fontSize={16}>{title}</Headings>
			<Headings fontSize={24} color={color}>
				{data}
			</Headings>
		</Paper>
	);
};
const GapPaper = ({ title, data, width, color }) => {
	return (
		<Paper
			className="title"
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				width: width ? width : 250,
				height: "auto",
				padding: "30px",
				boxSizing: "border-box",
			}}>
			<Headings fontSize={16}>{title}</Headings>
			<Headings fontSize={16}>Gaps (mm)</Headings>
			<div>
				<Headings fontSize={16} color={color}>
					Top Left-{data[0]?.top_left_measurement}
				</Headings>
				<Headings fontSize={16} color={color}>
					Top Right-{data[0]?.top_right_measurement}
				</Headings>
				<Headings fontSize={16} color={color}>
					Bottom Left-{data[0]?.bottom_left_measurement}
				</Headings>
				<Headings fontSize={16} color={color}>
					Bottom Right-{data[0]?.bottom_right_measurement}
				</Headings>
				<Headings fontSize={16} color={color}>
					Hinge Top-{data[0]?.hinge_top_measurement}
				</Headings>
				<Headings fontSize={16} color={color}>
					Hinge Bottom-{data[0]?.hinge_bottom_measurement}
				</Headings>
				<Headings fontSize={16} color={color}>
					Swing Top-{data[0]?.swing_top_measurement}
				</Headings>
				<Headings fontSize={16} color={color}>
					Swing Bottom-{data[0]?.swing_bottom_measurement}
				</Headings>
			</div>
		</Paper>
	);
};

const getMegStatus = (msgType) => {
	switch (msgType) {
		case 0:
			return "Normal Status";
		case 1:
			return "Door Time Out Alert";
		case 2:
			return "Commissioning";
		case 3:
			return "Commission Complete";
		case 4:
			return "Commission Failed";
		default:
			return "Unknown";
	}
};

const doorStatus = (status) => {
	switch (status) {
		case 0:
			return <img src={doorClose} />;
		case 1:
			return <img src={doorOpen} />;
	}
};
