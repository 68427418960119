import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postApi, getApi, deleteApi, putApi } from "../../Utils/serviceWorker";
import { toast } from "react-toastify";

export const getCodes = createAsyncThunk(
	"device_codes/get",
	async (credentials, thunkAPI) => {
		try {
			const response = await getApi(
				`device_codes?page=${credentials.page}&limit=${credentials.limit}&search=${credentials.search}&sort=${credentials.sort}&field=${credentials.field}`,
				true
			);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const getCodesByQuery = createAsyncThunk(
	"device_codes_query/get",
	async (credentials, thunkAPI) => {
		try {
			const response = await getApi(`device_codes?${credentials}`, true);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const getCode = createAsyncThunk(
	"device_code/get",
	async (credentials, thunkAPI) => {
		try {
			const response = await getApi(`device_codes/${credentials}`, true);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const addCode = createAsyncThunk(
	"device_code/add",
	async (credentials, thunkAPI) => {
		try {
			const response = await postApi(`device_codes`, true, credentials);

			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const updateCode = createAsyncThunk(
	"device_code/update",
	async (credentials, thunkAPI) => {
		try {
			const response = await putApi(
				`device_codes/${credentials.id}`,
				true,
				credentials
			);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const deleteCode = createAsyncThunk(
	"device_code/delete",
	async (credentials, thunkAPI) => {
		try {
			const response = await deleteApi(
				`device_codes/${credentials}`,
				true
			);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

const initialState = {
	value: [],
	loading: false,
	error: null,
	count: 0,
};

export const Codes = createSlice({
	name: "Codes",
	initialState,
	reducers: {
		reset: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(getCodes.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getCodes.fulfilled, (state, action) => {
				state.loading = false;
				state.value = action.payload.rows;
				state.count = action.payload.count;
			})
			.addCase(getCodes.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(updateCode.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(updateCode.fulfilled, (state, action) => {
				state.loading = false;
				toast.success("Updated Device Codes Successfully");
			})
			.addCase(updateCode.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(addCode.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(addCode.fulfilled, (state, action) => {
				state.loading = false;
				toast.success("Created Device Codes Successfully");
			})
			.addCase(addCode.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(getCode.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getCode.fulfilled, (state, action) => {
				state.loading = false;
				// Note: You might want to update the state with the fetched code here
			})
			.addCase(getCode.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(getCodesByQuery.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getCodesByQuery.fulfilled, (state, action) => {
				state.loading = false;
				// Note: You might want to update the state with the fetched codes here
			})
			.addCase(getCodesByQuery.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});
	},
});

export const { reset } = Codes.actions;
export default Codes.reducer;
