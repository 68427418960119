import React from "react";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import "./Custom.css";
import { SubHeadings } from "../Fonts/Fonts";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Paper } from "@mui/material";
import { getStatusMessages } from "../../Utils/Functions/getStatusMessages";
import { useNavigate, useParams } from "react-router-dom";

const FloorHoverComponent = ({ data, position, matches }) => {
	const navigate = useNavigate();
	const { siteId } = useParams();
	const { Component, color } = useStatus(data?.status);

	// Safeguard against missing data
	if (!data) {
		return null;
	}

	const message = getStatusMessages(data.status)?.split("-") || [];

	const hoverStyle = {
		position: "absolute",
		left: matches ? "0px" : position.x,
		top: matches ? "80%" : position.y,
	};

	const floorNo = data?.floor_no;
	const description = data?.description;

	return (
		<Paper
			className="floor-hover shadow-xl relative"
			style={hoverStyle}
			elevation={0}>
			<svg
				className="floorTip absolute top-1/2 -left-2 -translate-y-1/2 z-0"
				width="10"
				height="18"
				viewBox="0 0 10 18"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M0.5 8.99995L9.5 0.772705V17.2272L0.5 8.99995Z"
					fill="white"
				/>
			</svg>
			<div className="flex flex-col">
				<div className="flex gap-2 items-end mb-2">
					<Component />
					<SubHeadings className="text-base" color={color}>
						{getStatus(data.status)}
					</SubHeadings>
				</div>
				<SubHeadings className="text-base text-black">
					<span className="font-futura-book block">
						Floor No: {floorNo}
					</span>
					{description || "No description"}
				</SubHeadings>
			</div>
		</Paper>
	);
};

export default FloorHoverComponent;

// Status handling with proper error cases
const useStatus = (stat) => {
	const status = String(stat || "").toLowerCase();

	if (status === "conforming") {
		return {
			Component: () => (
				<CheckCircleOutlineOutlinedIcon className="w-4 h-4 text-green-500" />
			),
			color: "green",
		};
	} else if (status === "warning") {
		return {
			Component: () => (
				<WarningAmberIcon className="text-[#ddad01] w-4 h-4" />
			),
			color: "rgba(221, 173, 1, 1)",
		};
	} else {
		return {
			Component: () => (
				<WarningAmberIcon className="text-[#E32B12] w-4 h-4" />
			),
			color: "#E32B12",
		};
	}
};

const getStatus = (status) => {
	const statusStr = String(status || "").toLowerCase();

	if (statusStr === "conforming") {
		return "Conforming";
	} else if (statusStr === "warning") {
		return "Warning";
	} else if (statusStr === "not conforming") {
		return "Not Conforming";
	} else {
		return "Awaiting Installation";
	}
};
