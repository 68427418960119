import "./App.css";
import "./mediaQuery.css";
import { RouterProvider } from "react-router-dom";
import { router } from "./Routes/routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import store from "./Store/Store";
import { persistor } from "./Store/Store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import ErrorBoundary from './Error';
function App() {
	return (
		<ErrorBoundary>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ToastContainer />
				<RouterProvider router={router} />
			</PersistGate>
		</Provider>
		</ErrorBoundary>
	);
}

export default App;
