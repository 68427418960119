import React from "react";

const SystemResetStatusIcon = ({ color }) => (
	<svg
		width="28"
		height="29"
		viewBox="0 0 28 29"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M26 23.6981V27H8V2H26V12.3774"
			stroke={color}
			strokeWidth="2.46"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M16.3411 19.2463L18.6117 21.5115L17.3777 22.7462L13 18.3732L17.3777 14L18.6117 15.2348L16.3411 17.5H26.9698L27 28L19.9304 27.9721V26.2259H25.2236V19.2463H16.3411Z"
			fill={color}
		/>
	</svg>
);

export default SystemResetStatusIcon;
