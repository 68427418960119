import React from "react";
import { Tooltip } from "@mui/material";
import GapWarningIcon from "../../Screens/Asset/icons/GapWarning";
import SlamWarningIcon from "../../Screens/Asset/icons/SlamWarning";
import OrientationChangeIcon from "../../Screens/Asset/icons/OrientationChange";
import OpenTimeoutIcon from "../../Screens/Asset/icons/OpenTimeout";
import VandalishWarningIcon from "../../Screens/Asset/icons/VandalishWarning";
import LowBatteryIcon from "../../Screens/Asset/icons/LowBattery";
import SystemResetStatusIcon from "../../Screens/Asset/icons/SystemResetStatus";
import LowSignalIcon from "../../Screens/Asset/icons/LowSignal";
import SystemStatusIcon from "../../Screens/Asset/icons/SystemStatus";
import StatusMissedIcon from "../../Screens/Asset/icons/StatusMissed";

const Colors = {
	GREEN: "#34A853",
	YELLOW: "#DDAD01",
	RED: "#EB4335",
};

const getStatusColor = (status) => {
	switch (status) {
		case "Good":
			return Colors.GREEN;
		case "Low":
			return Colors.YELLOW;
		case "Critical":
			return Colors.RED;
		default:
			return Colors.GREEN;
	}
};

const getBatteryTooltip = (status) => {
	switch (status) {
		case "Good":
			return "Battery Status Normal";
		case "Low":
			return "Battery Status Low";
		case "Critical":
			return "Battery Status Critical";
		default:
			return "Battery Status Unknown";
	}
};

const getSignalTooltip = (status) => {
	switch (status) {
		case "Good":
			return "Signal Strength Normal";
		case "Low":
			return "Signal Strength Low";
		case "Critical":
			return "Signal Strength Critical";
		default:
			return "Signal Strength Unknown";
	}
};

const StatusIndicator = ({ Icon, color, tooltip }) => (
	<Tooltip title={tooltip} placement="top-start">
		<div className="iconLarge">
			<Icon color={color} />
		</div>
	</Tooltip>
);

export const SensorStatusIndicators = ({ sensor }) => {
	if (!sensor) return null;

	sensor = sensor.props || sensor;

	return (
		<ul className="flex flex-wrap items-center gap-6">
			<li>
				<StatusIndicator
					Icon={LowBatteryIcon}
					color={getStatusColor(sensor.battery_status)}
					tooltip={getBatteryTooltip(sensor.battery_status)}
				/>
			</li>
			<li>
				<StatusIndicator
					Icon={OpenTimeoutIcon}
					color={sensor.timeout_status ? Colors.RED : Colors.GREEN}
					tooltip={
						sensor.timeout_status
							? "Timeout Detected"
							: "No Timeout"
					}
				/>
			</li>
			<li>
				<StatusIndicator
					Icon={LowSignalIcon}
					color={getStatusColor(sensor.signal_status)}
					tooltip={getSignalTooltip(sensor.signal_status)}
				/>
			</li>
			<li>
				<StatusIndicator
					Icon={SystemResetStatusIcon}
					color={sensor.reset_status ? Colors.RED : Colors.GREEN}
					tooltip={
						sensor.reset_status
							? "System Reset Warning"
							: "System Status Normal"
					}
				/>
			</li>
			<li>
				<StatusIndicator
					Icon={StatusMissedIcon}
					color={
						sensor.transmission_missed_status
							? Colors.RED
							: Colors.GREEN
					}
					tooltip={
						sensor.transmission_missed_status
							? "Transmission Missed"
							: "Transmission Status Normal"
					}
				/>
			</li>
		</ul>
	);
};

export const DoorStatusIndicators = ({ door }) => {
	if (!door) {
		return null;
	}

	return (
		<div className="flex flex-wrap justify-between gap-2">
			<StatusIndicator
				Icon={SystemStatusIcon}
				color={door.system_status ? Colors.RED : Colors.GREEN}
				tooltip={
					door.system_status
						? "System Status Critical"
						: "System Status Normal"
				}
			/>
			<StatusIndicator
				Icon={OpenTimeoutIcon}
				color={door.timeout_status ? Colors.RED : Colors.GREEN}
				tooltip={
					door.timeout_status
						? "Door Open Timeout"
						: "Door Closing Time Normal"
				}
			/>
			<StatusIndicator
				Icon={OrientationChangeIcon}
				color={door.orientation_status ? Colors.RED : Colors.GREEN}
				tooltip={
					door.orientation_status
						? "Orientation Change Detected"
						: "Orientation Normal"
				}
			/>
			<StatusIndicator
				Icon={SlamWarningIcon}
				color={door.slam_status ? Colors.RED : Colors.GREEN}
				tooltip={
					door.slam_status
						? "Door Slam Detected"
						: "Slam Status Normal"
				}
			/>
			<StatusIndicator
				Icon={VandalishWarningIcon}
				color={door.vandalism_status ? Colors.RED : Colors.GREEN}
				tooltip={
					door.vandalism_status
						? "Vandalism Detected"
						: "No Vandalism Detected"
				}
			/>
			<StatusIndicator
				Icon={GapWarningIcon}
				color={door.gaps_status === "Alert" ? Colors.RED : Colors.GREEN}
				tooltip={
					door.gaps_status === "Alert"
						? "Gap Alert Detected"
						: "Gap Status Normal"
				}
			/>
		</div>
	);
};

export const mainStatusIndicator = (doorData) => {
	let door = doorData.props;
	if (!door) {
		return {
			status: "UNKNOWN",
			color: Colors.GREEN,
			background: "#E1FFE9",
		};
	}
	if (door.door_status === "Not Conforming") {
		return {
			status: "Not Conforming",
			color: Colors.RED,
			background: "#FFE6E6",
		};
	}

	if (door.door_status === "Conforming") {
		return {
			status: "Conforming",
			color: Colors.GREEN,
			background: "#E1FFE9",
		};
	} else if (door.door_status === "Warning") {
		return {
			status: "Warning",
			color: Colors.YELLOW,
			background: "#FFF9E6",
		};
	} else {
		return {
			status: "Awaiting Installation",
			color: Colors.YELLOW,
			background: "#FFF9E6",
		};
	}
};
