import React from "react";
import { Select as MuiSelect, MenuItem } from "@mui/material";
import { Headings } from "../Fonts/Fonts";
import "./Custom.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function Select({ options, onChange, value, title, placeholder, area }) {
	const handleOptionChange = (event) => {
		const selectedOption = event.target.value;
		if (onChange) {
			onChange(selectedOption);
		}
	};

	return (
		<MuiSelect
			value={value}
			onChange={handleOptionChange}
			className="border border-gray-200 bg-white flex gap-3 w-full font-futura font-normal"
			sx={{
				"& .MuiOutlinedInput-input": {
					paddingTop: "8px",
					paddingRight: "38px !important",
					paddingBottom: "8px",
					paddingLeft: "16px",
				},
				"&:focus": { bgcolor: "transparent" },
				"&: .MuiInputBase-root > .MuiOutlinedInput-notchedOutline": {
					border: "none",
				},
			}}
			displayEmpty
			// inputProps={{ 'aria-label': 'Select' }}
			placeholder={title}
			// IconComponent={() => <KeyboardArrowDownIcon sx={{ fontSize: "20px" }} />}
		>
			<MenuItem key={0} value={0}>
				<Headings className="capitalize text-base font-normal font-futura">
					{placeholder}
				</Headings>
			</MenuItem>
			{options?.map((option) => (
				<MenuItem key={option.data.id} value={option.data.id}>
					<Headings className="text-base font-normal font-futura">
						{" "}
						{area == "sites"
							? option.data.name
							: area == "floors"
							? option.data.description
							: area == "doors"
							? option.data.description
							: null}{" "}
					</Headings>
				</MenuItem>
			))}
		</MuiSelect>
	);
}

export default Select;
