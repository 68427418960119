import React, { useEffect } from "react";
import SitesMap from "../../Components/Maps/SitesMap";
import Search from "../../Components/Search/Search";
import { Sites } from "../Site/Sites";
import Doors from "../Door/Door";
import { useSelector } from "react-redux";
import AdminDashboard from "./AdminDashboard";

export const DashBoard = (props) => {
	const { user } = useSelector((state) => state.auth);

	useEffect(() => {
		document.title = "Home";
	}, []);
	return user?.roleId == process.env.REACT_APP_INSPECTION_ROLE_ID ? (
		<Search />
	) : user?.roleId == process.env.REACT_APP_ADMIN_ROLE_ID ||
	  user?.roleId == process.env.REACT_APP_ORG_ADMIN_ROLE_ID ||
	  user?.roleId == process.env.REACT_APP_USER_ROLE_ID ? (
		<AdminDashboard />
	) : user?.roleId == process.env.REACT_APP_AGENT_ROLE_ID ? (
		<Doors />
	) : (
		<SitesMap />
	);
};
