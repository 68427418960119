// export const getStatusMessages = (status) => {

// 	if (status >= 200 && status < 300) {
// 		switch (status) {
// 			case status == "200":
// 				return "Fire Door - Indicated Compliant";
// 			case status == "201":
// 				return "Fire Door - USB Powered";
// 			default:
// 				return "Fire Door - Indicated Compliant";
// 		}
// 	} else if (status >= 300 && status < 400) {
// 		switch (status) {
// 			case "301":
// 				return "Fire Door - Gap Close to Tolerance Limits";
// 			case "302":
// 				return "Fire Door - Indicated Frequent Slamming";
// 			case "303":
// 				return "Fire Door - Measurements Indication of Vandalism";
// 			case "304":
// 				return "Sensor - Low Battery Status - Limited life remaining";
// 			case "308":
// 				return "Fire Door - Gap Close to Tolerance Limits";
// 			case "305":
// 				return "System Warning - Please Contact Customer Support";
// 			default:
// 				return "System Warning - Please Contact Customer Support";
// 		}
// 	} else if (status >= 400) {
// 		switch (status) {
// 			case "401":
// 				return "Fire Door - Gaps Indicated Non-compliant";
// 			case "402":
// 				return "Sensor - Measurements Indicate Sensor or Door Removal or Severe Damage";
// 			case "403":
// 				return "Fire Door - Has been Continuously Open Longer than (set time) Minutes";
// 			case "404":
// 				return "Sensor - Critically Low Battery Status - Please Arrange for Replacement";
// 			case "406":
// 				return "Sensor - Critically Low Battery Status - Please Arrange for Replacement";
// 			case "407":
// 				return "Fire Door - Has been Continuously Open Longer than (set time) Minutes";
// 			case "409":
// 				return "Fire Door - More Than 2 Scheduled transmissions have been missed, Fire Door May be Non-Compliant";
// 			case "411":
// 				return "Sensor - Measurements Indicate Sensor or Door Removal or Severe Damag";
// 			case "412":
// 				return "Fire Door - Gaps Indicated Non-compliant";
// 			case "420":
// 				return "Sensor - Sensor Offline";
// 			default:
// 				return "Fire Door - More Than 2 Scheduled transmissions have been missed, Fire Door May be Non-Compliant";
// 		}
// 	} else {
// 		return "Fire Door - Awaiting Installation";
// 	}
// };

export const getStatusMessages = (status, floor) => {
	if (status === "Conforming") {
	  return "Confirming";
	}
  
	if (status === "Warning") {
	  return "Warning";
	}
  
	if (status === "Not Conforming") {
	  return "Not Confirming";
	} else if (floor) {
	  return "New";
	} else if (status === '' || status === null) {
	  return "Awaiting Installation";
	}
  };

export const getBatteryStatus = (status) => {
	switch (status) {
		case "304":
			return {
				message: "Low Battery Level",
				color: "rgba(221, 173, 1, 1)",
			};
		case "404":
			return {
				message: "Critical Battery",
				color: "#EB4335",
			};

		default:
			return { message: "Good", color: "#34A853" };
	}
};

export const getAllStatuses = (statuses) => {
	let message = ``;
	if (statuses != null) {
		const allStatuses = statuses.split(",");

		const alertStatuses = allStatuses.filter((status) => status >= 300);
		if (alertStatuses.length > 0) {
			alertStatuses.map((status) => {
				message += `${getStatusMessages(status)}\n`;
			});
		} else {
			message = `${getStatusMessages(allStatuses[0])}`;
		}

		return message;
	} else {
		return "Fire Door - Awaiting Installation";
	}
};
