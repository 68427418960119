import React from "react";

const OpenTimeoutIcon = ({ color }) => (
	<svg
		width="27"
		height="27"
		viewBox="0 0 27 27"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<rect
			x="7.91"
			y="0.91"
			width="18.18"
			height="25.18"
			fill="white"
			stroke={color}
			stroke-width="1.82"
		/>
		<path
			d="M16.5 7.69556V14.2173"
			stroke={color}
			stroke-width="1.08696"
			stroke-linecap="round"
		/>
		<path
			d="M20.7389 16.6576C21.0141 16.7776 21.3344 16.6517 21.4543 16.3765C21.5742 16.1014 21.4483 15.7811 21.1732 15.6612L20.7389 16.6576ZM21.1732 15.6612L16.7169 13.7192L16.2827 14.7157L20.7389 16.6576L21.1732 15.6612Z"
			fill={color}
		/>
	</svg>
);

export default OpenTimeoutIcon;
