import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Modal, Tooltip, FormControl, InputLabel } from "@mui/material";
import { CircularProgress } from "@mui/material";
import JsonFormatter from "react-json-formatter";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	getAllNotifications,
	getNotagainstorg,
	getNotifications,
} from "../../Store/Features/notifications";
import { getSites } from "../../Store/Features/Sites";
import { getFloorsOfSite } from "../../Store/Features/Floors";
import { getDoorsofFloor } from "../../Store/Features/Door";
import { getOrganizations } from "../../Store/Features/Customer";
import GapWarningIcon from "../../Screens/Asset/icons/GapWarning";
import SystemStatusIcon from "../../Screens/Asset/icons/SystemStatus";
import SlamWarningIcon from "../../Screens/Asset/icons/SlamWarning";
import OrientationChangeIcon from "../../Screens/Asset/icons/OrientationChange";
import VandalishWarningIcon from "../../Screens/Asset/icons/VandalishWarning";
import SystemResetStatusIcon from "../../Screens/Asset/icons/SystemResetStatus";
import StatusMissedIcon from "../../Screens/Asset/icons/StatusMissed";
import OpenTimeoutIcon from "../../Screens/Asset/icons/OpenTimeout";

import { FilterControls } from "./FilterControl";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export const Webhooks = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const { user } = useSelector((state) => state.auth);
	const [isLoading, setIsLoading] = useState(true);
	const navigate = useNavigate();

	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const [sortModel, setSortModel] = useState([
		{ field: "created_at", sort: "desc" },
	]);

	// States for filters
	const [organizations, setOrganizations] = useState([]);
	const [selectedOrg, setSelectedOrg] = useState("");
	const [sites, setSites] = useState([]);
	const [selectedSite, setSelectedSite] = useState("");
	const [floors, setFloors] = useState([]);
	const [selectedFloor, setSelectedFloor] = useState("");
	const [doors, setDoors] = useState([]);
	const [selectedDoor, setSelectedDoor] = useState("");

	const [selectedData, setSelectedData] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);

	const { loading, count } = useSelector((state) => state.notifications);
	const events = useSelector((state) => state.notifications.value);
	const hasSearchParams = location.search && location.search !== "";

	const theme = useTheme();
	const isSmallScreen = useMediaQuery("(max-width:768px)");

	useEffect(() => {
		const initializeData = async () => {
			setIsLoading(true);
			try {
				const params = new URLSearchParams(location.search);
				const doorId = params.get("doorId");
				const organizationId = params.get("organizationId");

				if (!user?.organizationId) {
					const orgResponse = await dispatch(getOrganizations());
					if (!orgResponse.error) {
						setOrganizations(orgResponse.payload?.rows || []);
					}
				}
				if (doorId) {
					setSelectedDoor(doorId);
					await dispatch(
						getNotifications({
							page: paginationModel.page,
							limit: paginationModel.pageSize,
							id: doorId,
							query: "door",
							sort: sortModel[0]?.sort || "desc",
							field: sortModel[0]?.field || "created_at",
						})
					);
				} else if (organizationId) {
					setSelectedOrg(organizationId);
					await dispatch(
						getNotifications({
							page: paginationModel.page,
							limit: paginationModel.pageSize,
							id: organizationId,
							query: "organization",
							sort: sortModel[0]?.sort || "desc",
							field: sortModel[0]?.field || "created_at",
						})
					);
				} else {
					await dispatch(
						getAllNotifications({
							page: paginationModel.page,
							limit: paginationModel.pageSize,
							sort: sortModel[0].sort,
							field: sortModel[0].field,
						})
					);
				}
			} catch (error) {
				console.error("Error loading initial data:", error);
			} finally {
				setIsLoading(false);
			}
		};

		initializeData();
	}, [
		dispatch,
		paginationModel.page,
		paginationModel.pageSize,
		sortModel,
		location.search,
		user?.organizationId,
	]);

	const handleOrgChange = (event) => {
		const orgId = event.target.value;
		setSelectedOrg(orgId);
		setSelectedSite("");
		setSelectedFloor("");
		setSelectedDoor("");
		setSites([]);
		setFloors([]);
		setDoors([]);

		if (orgId) {
			dispatch(getSites({ orgId: orgId })).then((res) => {
				if (!res.error) {
					setSites(res.payload?.data || []);
				}
			});
		}
	};

	// Handle site change
	const handleSiteChange = (event) => {
		const siteId = event.target.value;
		setSelectedSite(siteId);
		setSelectedFloor("");
		setSelectedDoor("");
		setFloors([]);
		setDoors([]);

		if (siteId) {
			dispatch(getFloorsOfSite(siteId)).then((res) => {
				if (!res.error) {
					setFloors(res.payload?.data || []);
				}
			});
		}
	};

	// Handle floor change
	const handleFloorChange = (event) => {
		const floorId = event.target.value;
		setSelectedFloor(floorId);
		setSelectedDoor("");
		setDoors([]);

		if (floorId) {
			dispatch(getDoorsofFloor(floorId)).then((res) => {
				if (!res.error) {
					setDoors(res.payload?.data || []);
				}
			});
		}
	};

	// Handle door change
	const handleDoorChange = (event) => {
		setSelectedDoor(event.target.value);
	};

	// Fetch notifications based on filters
	useEffect(() => {
		if (sortModel && sortModel[0]?.sort) {
			const params = {
				page: paginationModel.page,
				limit: paginationModel.pageSize,
				sort: sortModel[0].sort,
				field: sortModel[0].field,
			};

			if (selectedDoor) {
				dispatch(
					getNotifications({
						...params,
						id: selectedDoor,
						query: "door",
					})
				);
			} else if (selectedFloor) {
				dispatch(
					getNotifications({
						...params,
						id: selectedFloor,
						query: "floor",
					})
				);
			} else if (selectedSite) {
				dispatch(
					getNotifications({
						...params,
						id: selectedSite,
						query: "site",
					})
				);
			} else if (selectedOrg || user?.organizationId) {
				dispatch(
					getNotagainstorg({
						...params,
						orgId: selectedOrg || user?.organizationId,
					})
				);
			} else {
				dispatch(getAllNotifications(params));
			}
		}
	}, [
		sortModel,
		paginationModel,
		selectedOrg,
		selectedSite,
		selectedFloor,
		selectedDoor,
		dispatch,
		user?.organizationId,
	]);

	const getNotificationIcon = (type, styles = {}) => {
		const { color, background } = styles;

		switch (type) {
			case "Missed Transmission Status":
				return <StatusMissedIcon color={color} />;
			case "Slam Status":
				return <SlamWarningIcon color={color} />;
			case "Vandalism Status":
				return <VandalishWarningIcon color={color} />;
			case "Orientation Status":
				return <OrientationChangeIcon color={color} />;
			case "Gap Status":
				return <GapWarningIcon color={color} />;
			case "Timeout Status":
				return <OpenTimeoutIcon color={color} />;
			case "System Status":
				return <SystemStatusIcon color={color} />;
			default:
				return <SystemResetStatusIcon color={color} />;
		}
	};

	const getStatusColor = (status) => {
		switch (status) {
			case "Conforming":
				return {
					color: "#34A853",
					background: "rgba(52, 168, 83, 0.1)",
				};
			case "Warning":
				return {
					color: "#DDAD01",
					background: "rgba(221, 173, 1, 0.1)",
				};
			case "Non Conforming":
				return {
					color: "#EB4335",
					background: "rgba(235, 67, 53, 0.1)",
				};
			default:
				return {
					color: "#EB4335",
					background: "rgba(235, 67, 53, 0.1)",
				};
		}
	};

	const columns = [
		{
			field: "type",
			headerName: "Event Type",
			flex: 1,
			minWidth: 180,
			sortable: true,
			renderCell: (params) => {
				const { color, background } = getStatusColor(params.value);
				return (
					<div className="flex items-center gap-2">
						<Tooltip title={params.value}>
							<div
								className="flex items-center"
								data-value={params.value}>
								{getNotificationIcon(params.value, {
									color,
									background,
								})}
							</div>
						</Tooltip>
						<span>{params.value}</span>
					</div>
				);
			},
		},
		{
			field: "organizationName",
			headerName: "Organization",
			flex: 1,
			minWidth: 180,
			sortable: true,
			renderCell: (params) => (
				<Tooltip title={params.value}>
					<span className="truncate">{params.value}</span>
				</Tooltip>
			),
		},
		{
			field: "siteName",
			headerName: "Site",
			flex: 1,
			minWidth: 180,
			sortable: true,
			renderCell: (params) => (
				<Tooltip title={params.value}>
					<span className="truncate">{params.value}</span>
				</Tooltip>
			),
		},
		{
			field: "floorName",
			headerName: "Floor",
			flex: 1,
			minWidth: 180,
			sortable: true,
			renderCell: (params) => (
				<Tooltip
					title={`${params.row.floorName} (${params.row.floor_no})`}>
					<span className="truncate">{`${params.row.floorName} (${params.row.floor_no})`}</span>
				</Tooltip>
			),
		},
		{
			field: "doorName",
			headerName: "Door",
			flex: 1,
			minWidth: 180,
			sortable: true,
			renderCell: (params) => (
				<Tooltip title={params.value}>
					<span className="truncate">{params.value}</span>
				</Tooltip>
			),
		},
		{
			field: "message",
			headerName: "Message",
			flex: 1,
			minWidth: 180,
			sortable: true,
			renderCell: (params) => (
				<Tooltip title={params.value}>
					<span className="truncate">{params.value}</span>
				</Tooltip>
			),
		},
		{
			field: "created_at",
			headerName: "Created At",
			flex: 1,
			minWidth: 180,
			sortable: true,
			renderCell: (params) => {
				const date = new Date(params.value);
				const formattedDate = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
				return (
					<Tooltip title={formattedDate}>
						<div>{formattedDate}</div>
					</Tooltip>
				);
			},
		},
		{
			field: "status",
			headerName: "Status",
			flex: 1,
			minWidth: 180,
			renderCell: (params) => {
				const { color, background } = getStatusColor(params.value);
				return (
					<Tooltip title={params.value}>
						<div
							className="flex justify-center items-center h-[25px] py-[2px] px-5 rounded"
							style={{ backgroundColor: background }}>
							<span style={{ color }}>{params.value}</span>
						</div>
					</Tooltip>
				);
			},
		},
	];

	const columnVisibilityModel = {
		siteName: !isSmallScreen,
		floorName: !isSmallScreen,
		doorName: !isSmallScreen,
		message: !isSmallScreen,
		created_at: !isSmallScreen,
		status: !isSmallScreen,
		// Essential columns
		type: true,
		organizationName: true,
	};

	const handleCellClick = (params) => {
		console.log("Selected Data:", params.row);
		navigate(
			`/sites/${params?.row?.siteId}/floor/${params.row?.floorId}/asset/${params.row?.doorId}`
		);
	};

	if (isLoading) {
		return (
			<div className="flex items-center justify-center w-full h-screen">
				<div className="flex flex-col items-center gap-4">
					<CircularProgress size={40} sx={{ color: "#407AAD" }} />
					<p className="text-lg text-gray-600">Loading Events...</p>
				</div>
			</div>
		);
	}

	return (
		<div className="flex flex-col w-full h-full p-9">
			<div className="flex items-center justify-between h-10 mb-4">
				<div className="flex gap-4">
					<h1 className="text-xl font-medium">Events</h1>
				</div>
			</div>

			{!hasSearchParams && (
				<FilterControls
					selectedOrg={selectedOrg}
					selectedSite={selectedSite}
					selectedFloor={selectedFloor}
					selectedDoor={selectedDoor}
					onOrgChange={handleOrgChange}
					onSiteChange={handleSiteChange}
					onFloorChange={handleFloorChange}
					onDoorChange={handleDoorChange}
					organizations={organizations}
					sites={sites}
					floors={floors}
					doors={doors}
					user={user}
				/>
			)}

			<div className="w-full !min-h-96">
				<DataGrid
					rows={events}
					columns={columns}
					rowCount={count}
					loading={loading}
					pageSizeOptions={[5, 10, 20, 25, 50, 100]}
					className="px-5 py-0 w-full bg-white !border-none !rounded-none shadow-sm !min-h-96"
					sx={{
						"& .MuiDataGrid-iconButtonContainer": {
							visibility: "visible",
						},
						"& .MuiDataGrid-sortIcon": {
							opacity: "inherit !important",
						},
						"& .MuiDataGrid-cell": {
							display: "flex",
							alignItems: "center",
							padding: "0",
							color: "#212B36",
							fontFamily: "Futura PT Book !important",
							cursor: "pointer",
						},
						"& .MuiDataGrid-columnHeaders": {
							fontFamily: "Futura PT",
							fontWeight: "500",
							fontSize: 17,
							padding: "0",
						},
						"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
							outline: "none !important",
						},
						"& > .MuiDataGrid-columnSeparator": {
							display: "none !important",
						},
					}}
					paginationModel={paginationModel}
					onPaginationModelChange={setPaginationModel}
					paginationMode="server"
					sortModel={sortModel}
					columnVisibilityModel={columnVisibilityModel}
					onSortModelChange={(model) =>
						model.length > 0 && setSortModel(model)
					}
					onCellClick={handleCellClick}
					rowHeight={54}
				/>
			</div>

			<Modal
				open={modalOpen}
				onClose={() => setModalOpen(false)}
				aria-labelledby="event-details-modal">
				<Box className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[650px] max-h-[80vh] bg-white shadow-lg p-6 overflow-auto">
					<h2 className="text-xl mb-4">Event Details</h2>
					<JsonFormatter
						json={selectedData}
						tabWith={4}
						jsonStyle={{
							propertyStyle: { color: "red" },
							stringStyle: { color: "green" },
							numberStyle: { color: "darkorange" },
						}}
					/>
				</Box>
			</Modal>
		</div>
	);
};

export default Webhooks;
