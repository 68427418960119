import React, { useState, useEffect } from "react";
import { Headings, SubHeadings, Text } from "../../Shared/Fonts/Fonts";
import "./Forms.css";
import Divider from "@mui/material/Divider";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import counterClockWise from "../../Assets/counterClockWise.png";
import clockWiseDoor from "../../Assets/clockWiseDoor.png";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
export const DoorInformation = ({ form, setForm, data }) => {
	return (
		<div className="flex flex-col gap-3">
			<h2 className="text-lg font-medium">
				Data Recieved! Please Enter Door Information
			</h2>
			<div className="flex w-full bg-slate-200 p-5">
				<div className="flex flex-col gap-4 w-1/2">
					<h3 className="text-lg font-medium">Opening Direction</h3>
					<ImageRadios
						form={form}
						setForm={setForm}
						name="direction"
					/>
				</div>
				<Divider orientation="vertical" flexItem></Divider>
				<div className="flex flex-col gap-4 pl-6 w-1/2">
					<h3 className="text-lg font-medium">
						Confirm Door Size
						<small className="font-medium ml-2 p-2 rounded text-[#eb4335] bg-[#eb4335] bg-opacity-10">
							(Mtr)
						</small>
					</h3>

					<div className="flex gap-4">
						<Input form={form} setForm={setForm} name="width" />
						<Input form={form} setForm={setForm} name="height" />
					</div>
				</div>
			</div>
		</div>
	);
};
const Input = ({ setForm, name, form }) => {
	return (
		<div className="flex">
			<label className="flex flex-col gap-1">
				{" "}
				<span className="capitalize">{name}</span>
				<input
					placeholder={`${name}`}
					className="w-40 py-2 px-3 rounded"
					value={form[name]}
					onChange={(e) =>
						setForm({ ...form, [name]: e.target.value })
					}
				/>
			</label>
		</div>
	);
};
function ImageRadios({ form, setForm, name }) {
	const handleChange = (event) => {
		setForm({ ...form, direction: event.target.value });
	};

	// Disable if form[name] has a value
	const isDisabled = !!form[name];

	return (
		<FormControl fullWidth disabled={isDisabled}>
			<RadioGroup
				value={form[name]}
				onChange={handleChange}
				name={name}
				className="flex flex-row justify-between py-0 pl-2 pr-0">
				<FormControlLabel
					value="CW"
					control={<Radio className="p-0" />}
					label={
						<div className="flex flex-row justify-center items-end">
							<RotateLeftIcon />
						</div>
					}
				/>
				<FormControlLabel
					value="CCW"
					control={<Radio className="p-0" />}
					label={
						<div className="flex flex-row justify-center items-center">
							<RotateRightIcon />
						</div>
					}
				/>
			</RadioGroup>
		</FormControl>
	);
}
