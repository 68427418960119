import React, { useState, useEffect } from "react";
import {
	Card,
	CardContent,
	TextField,
	Button,
	InputAdornment,
} from "@mui/material";
import "./Auth.css";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { verifyEmail } from "../../Store/Features/Auth";
import { Headings, Text } from "../../Shared/Fonts/Fonts";

import { useLocation } from "react-router-dom";

export function VerifyScreen() {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const [tokenError, setTokenError] = useState("");
	const { loading } = useSelector((state) => state.auth);
	const searchParams = new URLSearchParams(location.search);
	const token = searchParams.get("token");

	const handleLogin = () => {
		if (!token || token.length == 0) {
			setTokenError("Email is required");
			return;
		}
	};

	useEffect(() => {
		if (token) {
			dispatch(verifyEmail(token)).then((res) => {
				if (!res.error) {
					navigate("/");
				}
			});
		}
	}, [token]);

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	useEffect(() => {
		document.title = "Verify Token";
	}, []);

	return (
		<Card className="login-card">
			<CardContent className="login-container flex items-center justify-center">
				{loading ? (
					<CircularProgress size={16} sx={{ color: "white" }} />
				) : (
					<Headings>Could Not Verify Email</Headings>
				)}
			</CardContent>
		</Card>
	);
}
