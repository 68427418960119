import React from "react";

const SlamWarningIcon = ({ color }) => (
	<svg
		width="27"
		height="27"
		viewBox="0 0 27 27"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<rect
			x="10.0333"
			y="0.912162"
			width="15.6892"
			height="25.1757"
			fill="white"
			stroke={color}
			strokeWidth="1.82432"
		/>
		<path
			d="M18.2034 25.78C18.0804 25.9037 17.9743 26.0066 17.8887 26.0878H4.50956C4.71722 25.8162 4.94137 25.5115 5.17548 25.1761C6.24999 23.6364 7.54763 21.4296 8.41567 18.7779C9.2848 16.1229 9.72912 13.0026 9.06786 9.66007C8.50475 6.81367 7.14859 3.85118 4.64096 0.912162H17.873C21.2835 4.27445 22.9544 7.49663 23.5864 10.415C24.235 13.4095 23.8063 16.1607 22.9302 18.5184C22.0518 20.8827 20.7279 22.8373 19.6142 24.2068C19.059 24.8895 18.5605 25.4213 18.2034 25.78Z"
			fill="white"
			stroke={color}
			strokeWidth="1.82432"
		/>
		<line
			x1="0.574471"
			y1="8.45768"
			x2="4.22312"
			y2="11.0117"
			stroke={color}
			strokeWidth="0.72973"
		/>
		<line
			x1="0.836728"
			y1="17.4859"
			x2="3.986"
			y2="14.3366"
			stroke={color}
			strokeWidth="0.72973"
		/>
		<line
			y1="12.7701"
			x2="4.01351"
			y2="12.7701"
			stroke={color}
			strokeWidth="0.72973"
		/>
		<path
			d="M19.703 14.4121C20.4083 14.4121 20.98 13.8404 20.98 13.1351C20.98 12.4298 20.4083 11.8581 19.703 11.8581C18.9977 11.8581 18.426 12.4298 18.426 13.1351C18.426 13.8404 18.9977 14.4121 19.703 14.4121Z"
			fill={color}
			stroke={color}
			strokeWidth="0.364865"
		/>
	</svg>
);

export default SlamWarningIcon;
