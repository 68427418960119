import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	getOrganizations,
	deleteOrganization,
} from "../../Store/Features/Customer";
import { Headings } from "../../Shared/Fonts/Fonts";
import { DataGrid } from "@mui/x-data-grid";
import { IconButton, Menu, MenuItem } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useMediaQuery, useTheme } from "@mui/material";
import search from "../../Assets/search.svg";
import Edit from "../../Assets/Edit.svg";

const Organization = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { loading } = useSelector((state) => state.customers);
	const organization = useSelector((state) => state.customers.value);
	const [searchQuery, setSearchQuery] = useState("");
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});

	const theme = useTheme();
	const isSmallScreen = useMediaQuery("(max-width:768px)");

	// Filter the organizations based on the search query
	const filteredUsers = organization?.filter((user) => {
		return ["id", "name", "email", "contact"].some((key) =>
			user[key]
				?.toString()
				.toLowerCase()
				.includes(searchQuery.toLowerCase())
		);
	});

	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
		setPaginationModel({ ...paginationModel, page: 0 });
	};

	const handleDeleteUser = (id) => {
		dispatch(deleteOrganization(id)).then(() => {
			dispatch(getOrganizations());
		});
	};

	const columns = [
		{
			field: "name",
			headerName: "Name",
			flex: 1,
			minWidth: 180,
			renderCell: (params) => (
				<Headings className="text-base text-dark font-futura-book font-normal">
					{params.row.name}
				</Headings>
			),
		},
		{ field: "contact", headerName: "Phone Number", flex: 1 },
		{
			field: "email",
			headerName: "Email",
			flex: 1,
			minWidth: 180,
			renderCell: (params) => (
				<Headings className="text-base text-dark font-futura-book font-normal">
					{params.row.email}
				</Headings>
			),
		},
		{
			field: "actions",
			headerName: "Actions",
			flex: 1,
			sortable: false,
			renderCell: (params) => (
				<ActionsDropdown
					userId={params.row.id}
					onDelete={handleDeleteUser}
				/>
			),
		},
	];

	const columnVisibilityModel = {
		contact: !isSmallScreen,
		email: !isSmallScreen,
		name: true,
		actions: true,
	};

	const dataGridStyles = {
		"& .MuiDataGrid-cell": {
			display: "flex",
			alignItems: "center",
			padding: "0",
			color: "#212B36",
			fontFamily: "Futura PT Book !important",
			cursor: "pointer",
		},
		"& .MuiDataGrid-columnHeaders": {
			fontFamily: "Futura PT",
			fontWeight: "500",
			fontSize: 17,
			padding: "0",
		},
		"& .MuiDataGrid-sortIcon": {
			opacity: "inherit !important",
		},
		"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
			outline: "none !important",
		},
		"& > .MuiDataGrid-columnSeparator": {
			display: "none !important",
		},
	};

	useEffect(() => {
		dispatch(getOrganizations());
	}, []);

	useEffect(() => {
		document.title = "Organizations";
	}, []);

	return (
		<div className="ContentWrapper">
			<div className="HeaderHeadline">
				<div className="flex gap-4">
					<h1 className="HeadlineTitle">Organisations</h1>
					<button
						className="bg-harmony py-1 px-4 rounded"
						onClick={() => navigate("/organizations/add")}>
						<Headings>Add Organisation</Headings>
					</button>
				</div>
				<div className="flex items-center bg-white h-10 sm:w-80 w-full max-w-full border-gray-200 border border-solid rounded">
					<img src={search} alt="No search" className="mx-3" />
					<input
						className="text-gray-600 outline-none w-full pr-3"
						type="text"
						placeholder="Search..."
						value={searchQuery}
						onChange={handleSearchChange}
					/>
				</div>
			</div>

			<div className="TableWrapper">
				<DataGrid
					autoHeight
					rows={filteredUsers || []}
					columns={columns}
					loading={loading}
					pageSizeOptions={[5, 10, 20, 25, 50, 100]}
					paginationModel={paginationModel}
					paginationMode="client"
					onPaginationModelChange={setPaginationModel}
					columnVisibilityModel={columnVisibilityModel}
					getRowId={(row) => row.id}
					className="px-5 py-0 w-full bg-white !border-none !rounded-none shadow-sm !min-h-96"
					sx={dataGridStyles}
					disableColumnMenu
					disableColumnSelector
				/>
			</div>
		</div>
	);
};

export default Organization;

const ActionsDropdown = ({ userId, onDelete }) => {
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDeleteClick = () => {
		onDelete(userId);
		handleClose();
	};

	return (
		<div>
			<IconButton variant="outlined" onClick={handleClick}>
				<img src={Edit} alt="Edit" />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}>
				<MenuItem onClick={handleDeleteClick}>
					<DeleteIcon fontSize="small" />
					Delete
				</MenuItem>
				<MenuItem onClick={() => navigate(`/organizations/${userId}`)}>
					<EditIcon fontSize="small" />
					Edit
				</MenuItem>
			</Menu>
		</div>
	);
};
