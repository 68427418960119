import React from "react";
import "./Layout.css";
import { Outlet } from "react-router-dom";
import { Headings } from "../Fonts/Fonts";
import { useNavigate } from "react-router-dom";
// import ThreeGlobe from 'three-globe';
// import globe from "../../Assets/globe_3d.png"
import * as THREE from "three";
import Globe from "../CustomElement/Globe";

export const AuthLayout = () => {
	const navigate = useNavigate();

	// const myScene = new THREE.Scene();
	// myScene.add(myGlobe);
	return (
		<div className="background">
			<Outlet />
		</div>
	);
};
