import React, { useState, useEffect } from 'react';
import { TextField, IconButton, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import { getSensorByCode } from '../../Store/Features/Sensors';
import { Alert } from '@mui/material';

export const SensorConnection = ({ data, form, setForm }) => {
    const dispatch = useDispatch();
    const [searchInput, setSearchInput] = useState('');
    const [searching, setSearching] = useState(false);
    const [error, setError] = useState(null);
    const [searchResults, setSearchResults] = useState(null);

	useEffect(() => {
        if (data?.door?.sensors && data.door.sensors.length >= 2) {
            setError("Both top and side sensors are already installed. Please contact administrator for further assistance.");
            return;
        }
    }, [data?.door?.sensors]);
    
    const handleSearchChange = (e) => {
        setSearchInput(e.target.value);
        setError(null);
    };

    const handleSearch = async () => {
        if (!searchInput.trim()) {
            setError("Please enter a sensor code");
            return;
        }

        setSearching(true);
        setError(null);
        setSearchResults(null);

        try {
            const response = await dispatch(getSensorByCode({ qr_code: searchInput })).unwrap();
            
            if (response.count > 0 && response.rows.length > 0) {
                setSearchResults(response.rows[0]);
            } else {
                setError("No sensor found with this code");
            }
        } catch (err) {
            setError(err.message || "Error searching for sensor");
        } finally {
            setSearching(false);
        }
    };

    const handleSelectSensor = () => {
        if (searchResults) {
            setForm(prev => ({
                ...prev,
                sensor_code: searchResults.sensor_code,
                sensorId: searchResults.id,
                sensor: searchResults,
                qrCode: searchResults.qr_code,
                nfcCode: searchResults.nfc_code
            }));
            setSearchResults(null);
            setSearchInput('');
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', height: '100%' }}>
            <div style={{ marginBottom: '1.5rem' }}>
                <h2 style={{ fontSize: '1.5rem', fontWeight: 600, marginBottom: '0.5rem' }}>
                    Select Door Device
                </h2>
                <p style={{ color: '#666' }}>Search for a sensor by entering its code</p>
            </div>

            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                <TextField
                    fullWidth
                    label="Search Sensor Code"
                    variant="outlined"
                    value={searchInput}
                    onChange={handleSearchChange}
                    onKeyPress={handleKeyPress}
                    placeholder="Enter sensor code..."
                    disabled={searching}
                />
                <IconButton 
                    onClick={handleSearch}
                    disabled={searching || !searchInput.trim()}
                    style={{
                        padding: '0.5rem',
                        backgroundColor: searching ? '#ccc' : 'var(--harmony-color, #1976d2)',
                        color: 'white'
                    }}
                >
                    {searching ? (
                        <CircularProgress size={24} style={{ color: 'white' }} />
                    ) : (
                        <SearchIcon />
                    )}
                </IconButton>
            </div>

            {error && (
                <Alert severity="error" style={{ marginTop: '0.5rem' }}>
                    {error}
                </Alert>
            )}

            {searchResults && (
                <div style={{ 
                    marginTop: '1rem',
                    padding: '1rem',
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                    backgroundColor: '#f5f5f5',
                    cursor: 'pointer'
                }}
                onClick={handleSelectSensor}
                >
                    <p style={{ fontWeight: 500 }}>Click to select: {searchResults.sensor_code}</p>
                </div>
            )}

            {form.sensor && form.sensor_code && (
                <div style={{ 
                    marginTop: '1rem',
                    padding: '1rem',
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                    backgroundColor: '#f5f5f5'
                }}>
                    <h3 style={{ fontWeight: 600, marginBottom: '0.5rem' }}>Selected Sensor</h3>
                    <p style={{ fontWeight: 500 }}>{form.sensor_code}</p>
                </div>
            )}
        </div>
    );
};