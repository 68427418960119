import * as React from "react";
import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import { useSelector } from "react-redux";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MobileLogo from "../../../Assets/MobileLogo.svg";
import { useNavigate, Link } from "react-router-dom";
import MobileNavDrawer from "../../../Components/Drawers/MobileDrawer";
import NotificationDrawer from "../../../Components/Drawers/NotificationDrawer";
import Avatar from "@mui/material/Avatar";

export default function MobileNavAppBar() {
	const [open, setOpen] = useState(false);
	const [openNoti, setOpenNoti] = useState(false);
	const navigate = useNavigate();
	const { user } = useSelector((state) => state.auth);
	const handleSettingsNavigation = () => {
		navigate("/profile");
	};
	return (
		<AppBar
			className="mobileClassNav-appbar !shadow-sm !bg-white"
			position="static">
			<MobileNavDrawer open={open} setOpen={setOpen} />
			<NotificationDrawer open={openNoti} setOpen={setOpenNoti} />
			<Toolbar className="justify-between">
				<IconButton
					size="large"
					edge="start"
					color="inherit"
					aria-label="menu"
					className="mr-[2px]"
					onClick={() => setOpen(!open)}>
					<MenuIcon className="text-[#105998]" />
				</IconButton>
				<Link to="/">
					<img
						className="h-[30px] w-[30px]"
						src={MobileLogo}
						alt="img"
					/>
				</Link>
				<IconButton onClick={handleSettingsNavigation} size="small">
					<Avatar
						className="!w-[30px] !h-[30px]"
						src={user?.avatar[0] && user?.avatar[0]?.publicUrl}
					/>
				</IconButton>
			</Toolbar>
		</AppBar>
	);
}
