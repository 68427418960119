import React, { useEffect, useRef, useState } from "react";
import { fabric } from "fabric";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getFloor } from "../../Store/Features/Floors";
import { getDoorsofFloor } from "../../Store/Features/Door";

const CanvasLoader = ({ parentRef }) => {
  const dispatch = useDispatch();
  const { assetId, floorId } = useParams();
  const canvasRef = useRef();
  const [image, setImage] = useState();
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const [hasFloorPlan, setHasFloorPlan] = useState(false);

  const loadCanvasData = async () => {
    try {
      const response = await dispatch(getFloor(floorId));
      const floorData = response.payload?.floor_data;

      // If no floor data exists, return early
      if (!floorData) {
        setHasFloorPlan(false);
        return;
      }

      setHasFloorPlan(true);

      // Create a new Fabric.js canvas
      const canvas = new fabric.Canvas();

      // Load the canvas data
      canvas.loadFromJSON(JSON.parse(floorData), async () => {
        const background = canvas.backgroundImage;
        
        if (!background) {
          setHasFloorPlan(false);
          return;
        }

        canvas.setWidth(background.width);
        canvas.setHeight(background.height);

        const objects = canvas.getObjects();
        let temp = [...objects];
        canvas.remove(...objects);

        // Get door data
        const res = await dispatch(getDoorsofFloor(floorId));
        
        // Find and update the relevant door
        for (let i = 0; i < res.payload.data.length; i++) {
          let door = temp.find(
            (obj) =>
              obj?.asset?.id == assetId ||
              obj?.asset?.data?.id == assetId
          );

          if (door) {
            if (door.type == "group") {
              door.asset = res.payload.data[i];
              door.selectable = false;
              door.evented = false;
              door.set(
                "fill",
                getStatus(res.payload.data[i].status).color
              );

              door.getObjects()[0].fill = getStatus(
                res.payload.data[i].status
              ).color;
            }
            door.selectable = false;
            door.evented = false;
            canvas.add(door);
          }
        }

        canvas.renderAll();
        canvas.setZoom(0.9999);
        setImage(canvas.toDataURL());
      });
    } catch (error) {
      console.error("Error loading canvas data:", error);
      setHasFloorPlan(false);
    }
  };

  useEffect(() => {
    if (width && height) {
      loadCanvasData();
    }
  }, [width, height]);

  useEffect(() => {
    setWidth(parentRef.current?.clientWidth);
    setHeight(parentRef.current?.clientHeight);
  }, [parentRef]);

  // If there's no floor plan data or image, return null
  if (!hasFloorPlan || !image) {
    return null;
  }

  // Return only the image if it exists
  return (
    <>
      <div className="hidden">
        <canvas ref={canvasRef} />
      </div>
      <img
        className="w-full h-full object-contain"
        width={width || 500}
        height={height || 500}
        src={image}
        alt="Floor Plan"
      />
    </>
  );
};

export default CanvasLoader;

const getStatus = (status) => {
  switch (status) {
    case "Conforming":
      return {
        color: "#34A853",
        background: "rgba(52, 168, 83, 0.1)",
      };
    case "Warning":
      return {
        color: "#DDAD01",
        background: "rgba(221, 173, 1, 0.1)",
      };
    case "Non Conforming":
      return {
        color: "#EB4335",
        background: "rgba(235, 67, 53, 0.1)",
      };
    default:
      return {
        color: "#EB4335",
        background: "rgba(235, 67, 53, 0.1)",
      };
  }
};