import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import { TextField, Button, Grid, MenuItem } from "@mui/material";
import { Headings } from "../../Shared/Fonts/Fonts";
import {
	getUser,
	updateUser,
	addUser,
	uploadAvatar,
} from "../../Store/Features/Users";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { getRoles } from "../../Store/Features/Roles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { IconButton } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Input from "@mui/material/Input";
import { getOrganizations } from "../../Store/Features/Customer";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useLocation } from "react-router-dom";
import { API_URL } from "../../Utils/Functions/utils";

var currentTime = new Date();
const AddUserPage = () => {
	const navigate = useNavigate();
	const { slug, organizationId } = useParams();
	const path = useLocation().pathname;
	const dispatch = useDispatch();
	const roles = useSelector((state) => state.roles.value);
	const isEdit = slug !== undefined;
	const loading = useSelector((state) => state.users.loading);
	const organizations = useSelector((state) => state.customers.value);
	const [showPassword, setShowPassword] = React.useState(false);

	const [formData, setFormData] = useState({
		firstName: "",
		email: "",
		password: "",
		lastName: "",
		roles: organizationId ? process.env.REACT_APP_USER_ROLE_ID : "",
		avatar: null,
		phoneNumber: "",
		organization: "",
		receive_email_notifications: false 
	});
	const [errors, setErrors] = useState({});
	const handleAvatar = (name, value) => {
		setFormData((prevData) => ({ ...prevData, [name]: value }));
	};
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({ ...prevData, [name]: value }));
	};

	const validateForm = () => {
		const newErrors = {};
		let valid = true;

		if (!formData.firstName || !formData.firstName.trim()) {
			newErrors.firstName = "First Name is required";
			valid = false;
		}

		if (!formData.lastName || !formData.lastName.trim()) {
			newErrors.lastName = "Last Name is required";
			valid = false;
		}

		if (!formData.organization) {
			newErrors.organization = "Organization is required";
			valid = false;
		}

		if (!formData.roles) {
			newErrors.roles = "Role is required";
			valid = false;
		}

		if (!formData.email || !formData.email.trim()) {
			newErrors.email = "Email is required";
			valid = false;
		} else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
			newErrors.email = "Invalid email format";
			valid = false;
		}

		if (!formData.phoneNumber || !formData.phoneNumber.trim()) {
			newErrors.phoneNumber = "Phone number is required";
			valid = false;
		} else if (!/^\d{10}$/.test(formData.phoneNumber)) {
			newErrors.phoneNumber = "Invalid phone number (10 digits required)";
			valid = false;
		}

		setErrors(newErrors);
		return valid;
	};

	useEffect(() => {
		// If it's an edit operation, you can fetch user data using 'slug' and set it in the form.
		if (isEdit) {
			dispatch(getUser(slug)).then((res) => {
				setFormData({
					firstName: res.payload?.firstName,
					lastName: res.payload?.lastName,
					email: res.payload?.email,
					phoneNumber: res.payload?.phoneNumber,
					avatar: res.payload?.avatar,
					roles: res.payload?.roleId,
					roleId: res.payload?.roleId,
					disabled: res.payload?.disabled,
					organization: res.payload?.organizationId,
					receive_email_notifications: res.payload?.receive_email_notifications ?? false 
				});
			});

			// Perform an API call or any necessary data fetching to get user details based on 'slug'
			// Set the fetched data to the formData state.
			// Example: fetchDataAndSetFormData(slug);
		}
	}, [isEdit, slug]);
	useEffect(() => {
		dispatch(getOrganizations());
	}, []);
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};
	useEffect(() => {
		dispatch(getRoles());
	}, []);

	const handleFormSubmit = (event) => {
		event.preventDefault();
		if (validateForm()) {
			const action = isEdit ? updateUser : addUser;
			dispatch(
				action({ id: isEdit ? slug : undefined, data: formData })
			).then(() => {
				navigate(-1);
			});
		}
	};
	const handleClickShowPassword = () => setShowPassword((show) => !show);

	useEffect(() => {
		document.title = slug ? "Edit User" : "Add User";
	}, []);

	return (
		<div className="ContentWrapper">
			<div className="HeaderHeadline">
				<div className="flex items-center gap-4">
					<IconButton onClick={() => navigate(-1)}>
						<KeyboardBackspaceIcon />
					</IconButton>

					<h1 className="HeadlineTitle">
						{isEdit
							? path.includes("installation_manager")
								? "Edit Installation Manager"
								: "Edit User"
							: "Add User"}
					</h1>
				</div>
			</div>

			<div className="bg-white shadow-sm p-5">
				<form onSubmit={handleFormSubmit}>
				<Grid container spacing={2}>
    {/* First Name */}
    <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextField
            label="First Name"
            variant="filled"
            fullWidth
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            error={!!errors.firstName}
            helperText={errors.firstName}
            InputLabelProps={{ shrink: true }}
        />
    </Grid>
    
    {/* Last Name */}
    <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextField
            label="Last Name"
            variant="filled"
            fullWidth
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            error={!!errors.lastName}
            helperText={errors.lastName}
            InputLabelProps={{ shrink: true }}
        />
    </Grid>
    
    {/* Email */}
    <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextField
            label="Email"
            variant="filled"
            fullWidth
            name="email"
            value={formData.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
            InputLabelProps={{ shrink: true }}
        />
    </Grid>
    
    {/* Phone Number */}
    <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextField
            label="Phone Number"
            variant="filled"
            fullWidth
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber}
            InputLabelProps={{ shrink: true }}
        />
    </Grid>
    
    {/* Password */}
    <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextField
            label="Password"
            variant="filled"
            placeholder="Enter your password"
            type={showPassword ? "text" : "password"}
            fullWidth
            name="password"
            value={formData.password}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            InputProps={{
                endAdornment: (
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                ),
            }}
        />
    </Grid>

    {/* Organization */}
    <Grid 
        item 
        xs={12} 
        sm={12} 
        md={6} 
        lg={6}
        sx={{
            display: organizationId || path.includes("installation_manager") ? "none" : null,
        }}
    >
        <TextField
            select
            label="Organisation"
            variant="filled"
            helperText={errors.organization}
            error={!!errors.organization}
            fullWidth
            name="organization"
            value={formData?.organization}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}>
            {organizations?.map((option) => (
                <MenuItem
                    key={option.id}
                    value={option.id}
                    sx={{ textTransform: "capitalize" }}>
                    {option.name}
                </MenuItem>
            ))}
        </TextField>
    </Grid>

    {/* User Role */}
    <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextField
            select
            InputLabelProps={{ shrink: true }}
            label="User Role"
            variant="filled"
            helperText={errors.roles}
            fullWidth
            name="roles"
            sx={{ display: organizationId ? "null" : null }}
            value={formData?.roles}
            error={!!errors.roles}
            onChange={handleChange}>
            {roles?.map((option) => (
                <MenuItem
                    key={option.id}
                    value={option.id}
                    sx={{ textTransform: "capitalize" }}>
                    {option.title}
                </MenuItem>
            ))}
        </TextField>
    </Grid>

    {/* Email Notifications Checkbox */}
    <Grid item xs={12} sm={12} md={6} lg={6}>
        <FormControlLabel
            control={
                <Checkbox
                    checked={formData.receive_email_notifications}
                    onChange={(e) => {
                        handleChange({
                            target: {
                                name: 'receive_email_notifications',
                                value: e.target.checked
                            }
                        });
                    }}
                    name="receive_email_notifications"
                />
            }
            label="Receive Email Notifications"
            sx={{ marginTop: '10px' }}
        />
    </Grid>

    {/* Profile Picture */}
    <Grid item xs={12}>
        <h3 className="text-base font-medium mb-3">
            Add Profile Picture
        </h3>
        <ImageUploadButton
            onChange={handleAvatar}
            name={"avatar"}
            path={"users/avatar"}
            form={formData}
        />
    </Grid>

    {/* Submit Button */}
    <Grid item xs={12} sm={12} md={6} lg={6}>
        <Button
            className="!bg-harmony py-1 px-4 rounded !mt-5"
            disabled={loading}
            type="submit"
            variant="contained"
            color="primary">
            {loading ? (
                <CircularProgress size={16} sx={{ color: "white" }} />
            ) : isEdit ? (
                <Headings>UPDATE USER</Headings>
            ) : (
                <Headings>CREATE USER</Headings>
            )}
        </Button>
    </Grid>
</Grid>
				</form>
			</div>
		</div>
	);
};

export default AddUserPage;

const ImageUploadButton = ({ onChange, name, path, form }) => {
	const dispatch = useDispatch();
	const { avatarLoading } = useSelector((state) => state.users);
	const handleImageChange = (event) => {
		const selectedFile = event.target.files[0];
		const extension = extractExtensionFrom(selectedFile.name);
		const id = uuidv4();
		const filename = `${id}.${extension}`;
		const privateUrl = `${path}/${filename}`;
		const uri = `/file/upload/${path}`;
		const publicUrl = API_URL() + `file/download?privateUrl=${privateUrl}`;
		const formData = new FormData();
		formData.append("file", selectedFile);
		formData.append("filename", filename);

		dispatch(uploadAvatar(formData)).then((res) => {
			onChange("avatar", {
				id: id,
				name: selectedFile.name,
				sizeInBytes: selectedFile.size,
				privateUrl,
				publicUrl,
				new: true,
			});
		});
	};

	return (
		<div className="flex gap-[10px]">
			<input
				accept="image/*"
				style={{ display: "none" }}
				id="image-upload-input"
				type="file"
				onChange={handleImageChange}
			/>
			<label htmlFor="image-upload-input">
				<Button
					className="!bg-harmony py-1 px-4 rounded"
					variant="contained"
					component="span">
					{avatarLoading ? (
						<CircularProgress size={16} sx={{ color: "white" }} />
					) : (
						<Headings>Upload Image</Headings>
					)}
				</Button>
			</label>
			<img
				className="w-[50px] h-[50px]"
				src={form.avatar?.publicUrl}
				style={{
					display: form.avatar?.publicUrl ? null : "none",
				}}
				alt="Img"
			/>
		</div>
	);
};

function extractExtensionFrom(filename) {
	if (!filename) {
		return null;
	}

	const regex = /(?:\.([^.]+))?$/;
	return regex.exec(filename)[1];
}
