import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import { Headings } from "../../Shared/Fonts/Fonts";
import MailIcon from "@mui/icons-material/Mail";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import { Avatar, IconButton } from "@mui/material";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import SensorDoorOutlinedIcon from "@mui/icons-material/SensorDoorOutlined";

import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate, useLocation } from "react-router-dom";

import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
	root: {
		position: "relative !important",
		"& .MuiBackdrop-root": {
			backgroundColor: "transparent",
		},
	},
	paper: {
		top: "58px !important",
		boxShadow: "none !important",
	},
});

export default function MobileNavDrawer(props) {
	const { open, setOpen } = props;
	const navigate = useNavigate();
	const path = useLocation().pathname;
	const classes = useStyles();

	const { user } = useSelector((state) => state.auth);
	const toggleDrawer = (anchor, open) => (event) => {
		setOpen((prev) => !prev);
	};

	const list = (anchor) => (
		<Box
			className="w-auto flex flex-col justify-center shadow-sm"
			role="presentation"
			onClick={toggleDrawer(anchor, false)}
			onKeyDown={toggleDrawer(anchor, false)}>
			<Tooltip title={"Home"} placement="right">
				<IconButton
					className="mobileNavButtons rounded-md justify-start"
					onClick={() => navigate("/")}
					sx={{
						backgroundColor:
							path == "/" ? "rgba(241, 247, 250, 1)" : null,
						borderRadius: "0 ",
						" &:hover": {
							backgroundColor: "rgba(241, 247, 250, 1)",
							borderRadius: "0",
						},
						justifyContent: "center",
						display: "flex",
						gap: "6px",
					}}>
					<svg
						width="19"
						height="20"
						viewBox="0 0 19 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M8.34559 0.55424C9.18329 -0.15403 10.4098 -0.15489 11.2485 0.55221L18.0088 6.25146C18.5159 6.67897 18.8086 7.30845 18.8086 7.97172V17.7703C18.8086 18.7368 18.0251 19.5203 17.0586 19.5203H2.55859C1.59209 19.5203 0.808594 18.7368 0.808594 17.7703V7.97044C0.808594 7.30827 1.10027 6.67973 1.60593 6.25222L8.34559 0.55424ZM10.2817 1.69904C10.0021 1.46335 9.59329 1.46363 9.31399 1.69972L2.57437 7.3977C2.40582 7.5402 2.30859 7.74972 2.30859 7.97044V17.7703C2.30859 17.9083 2.42052 18.0203 2.55859 18.0203H17.0586C17.1967 18.0203 17.3086 17.9083 17.3086 17.7703V7.97172C17.3086 7.75063 17.211 7.5408 17.042 7.3983L10.2817 1.69904Z"
							fill={
								path == "/" ? "rgba(16, 89, 152, 1)" : "#919EAB"
							}
						/>
					</svg>
					<h2 className="text-xl text-black font-futura-book">
						Home
					</h2>
				</IconButton>
			</Tooltip>
			<Tooltip title={"Sites"} placement="right">
				<IconButton
					className="mobileNavButtons rounded-md justify-start"
					style={{
						display:
							user?.roleId ==
								process.env.REACT_APP_INSPECTION_ROLE_ID ||
							user?.roleId == process.env.REACT_APP_AGENT_ROLE_ID
								? "none"
								: null,
					}}
					onClick={() => navigate("/sites")}
					sx={{
						backgroundColor: path.includes("sites")
							? "rgba(241, 247, 250, 1)"
							: null,
						borderRadius: "0",
						" &:hover": {
							backgroundColor: "rgba(241, 247, 250, 1)",
							borderRadius: "0",
						},
						justifyContent: "center",
						display: "flex",
						gap: "6px",
					}}>
					<svg
						width="21"
						height="22"
						viewBox="0 0 21 22"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M5.81519 4.74414C5.81519 5.34698 5.32648 5.83569 4.72363 5.83569C4.12079 5.83569 3.63208 5.34698 3.63208 4.74414C3.63208 4.1413 4.12079 3.65259 4.72363 3.65259C5.32648 3.65259 5.81519 4.1413 5.81519 4.74414Z"
							fill={
								path == "/sites"
									? "rgba(16, 89, 152, 1)"
									: "#919EAB"
							}
						/>
						<path
							d="M4.72363 9.11035C5.32648 9.11035 5.81519 8.62164 5.81519 8.0188C5.81519 7.41596 5.32648 6.92725 4.72363 6.92725C4.12079 6.92725 3.63208 7.41596 3.63208 8.0188C3.63208 8.62164 4.12079 9.11035 4.72363 9.11035Z"
							fill={
								path == "/sites"
									? "rgba(16, 89, 152, 1)"
									: "#919EAB"
							}
						/>
						<path
							d="M5.81519 11.2935C5.81519 11.8963 5.32648 12.385 4.72363 12.385C4.12079 12.385 3.63208 11.8963 3.63208 11.2935C3.63208 10.6906 4.12079 10.2019 4.72363 10.2019C5.32648 10.2019 5.81519 10.6906 5.81519 11.2935Z"
							fill={
								path == "/sites"
									? "rgba(16, 89, 152, 1)"
									: "#919EAB"
							}
						/>
						<path
							d="M5.81519 14.5681C5.81519 15.171 5.32648 15.6597 4.72363 15.6597C4.12079 15.6597 3.63208 15.171 3.63208 14.5681C3.63208 13.9653 4.12079 13.4766 4.72363 13.4766C5.32648 13.4766 5.81519 13.9653 5.81519 14.5681Z"
							fill={
								path == "/sites"
									? "rgba(16, 89, 152, 1)"
									: "#919EAB"
							}
						/>
						<path
							d="M4.72363 18.9343C5.32648 18.9343 5.81519 18.4456 5.81519 17.8428C5.81519 17.2399 5.32648 16.7512 4.72363 16.7512C4.12079 16.7512 3.63208 17.2399 3.63208 17.8428C3.63208 18.4456 4.12079 18.9343 4.72363 18.9343Z"
							fill={
								path == "/sites"
									? "rgba(16, 89, 152, 1)"
									: "#919EAB"
							}
						/>
						<path
							d="M12.3645 15.6597C12.9674 15.6597 13.4561 15.171 13.4561 14.5681C13.4561 13.9653 12.9674 13.4766 12.3645 13.4766C11.7616 13.4766 11.2729 13.9653 11.2729 14.5681C11.2729 15.171 11.7616 15.6597 12.3645 15.6597Z"
							fill={
								path == "/sites"
									? "rgba(16, 89, 152, 1)"
									: "#919EAB"
							}
						/>
						<path
							d="M15.6392 15.6597C16.242 15.6597 16.7307 15.171 16.7307 14.5681C16.7307 13.9653 16.242 13.4766 15.6392 13.4766C15.0363 13.4766 14.5476 13.9653 14.5476 14.5681C14.5476 15.171 15.0363 15.6597 15.6392 15.6597Z"
							fill={
								path == "/sites"
									? "rgba(16, 89, 152, 1)"
									: "#919EAB"
							}
						/>
						<path
							d="M15.6392 12.385C16.242 12.385 16.7307 11.8963 16.7307 11.2935C16.7307 10.6906 16.242 10.2019 15.6392 10.2019C15.0363 10.2019 14.5476 10.6906 14.5476 11.2935C14.5476 11.8963 15.0363 12.385 15.6392 12.385Z"
							fill={
								path == "/sites"
									? "rgba(16, 89, 152, 1)"
									: "#919EAB"
							}
						/>
						<path
							d="M13.4561 11.2935C13.4561 11.8963 12.9674 12.385 12.3645 12.385C11.7616 12.385 11.2729 11.8963 11.2729 11.2935C11.2729 10.6906 11.7616 10.2019 12.3645 10.2019C12.9674 10.2019 13.4561 10.6906 13.4561 11.2935Z"
							fill={
								path == "/sites"
									? "rgba(16, 89, 152, 1)"
									: "#919EAB"
							}
						/>
						<path
							d="M15.6392 9.11035C16.242 9.11035 16.7307 8.62164 16.7307 8.0188C16.7307 7.41596 16.242 6.92725 15.6392 6.92725C15.0363 6.92725 14.5476 7.41596 14.5476 8.0188C14.5476 8.62164 15.0363 9.11035 15.6392 9.11035Z"
							fill={
								path == "/sites"
									? "rgba(16, 89, 152, 1)"
									: "#919EAB"
							}
						/>
						<path
							d="M13.4561 8.0188C13.4561 8.62164 12.9674 9.11035 12.3645 9.11035C11.7616 9.11035 11.2729 8.62164 11.2729 8.0188C11.2729 7.41596 11.7616 6.92725 12.3645 6.92725C12.9674 6.92725 13.4561 7.41596 13.4561 8.0188Z"
							fill={
								path == "/sites"
									? "rgba(16, 89, 152, 1)"
									: "#919EAB"
							}
						/>
						<path
							d="M12.3645 3.65259V2.83392C12.3645 1.47752 11.2649 0.37793 9.90851 0.37793H2.81342C1.45701 0.37793 0.357422 1.47752 0.357422 2.83392V20.8445C0.357422 21.2967 0.723954 21.6632 1.17609 21.6632H19.1867C19.6388 21.6632 20.0054 21.2967 20.0054 20.8445V6.10858C20.0054 4.75217 18.9057 3.65259 17.5494 3.65259H12.3645ZM1.99475 2.83392C1.99475 2.38179 2.36128 2.01526 2.81342 2.01526H9.90851C10.3606 2.01526 10.7272 2.38179 10.7272 2.83392V3.65259H10.4543C9.09792 3.65259 7.99829 4.75217 7.99829 6.10858V20.0259H1.99475V2.83392ZM12.9103 20.0259V18.3885H15.0934V20.0259H12.9103ZM16.7307 17.5699C16.7307 17.1178 16.3642 16.7512 15.912 16.7512H12.0916C11.6395 16.7512 11.2729 17.1178 11.2729 17.5699V20.0259H9.63562V6.10858C9.63562 5.65645 10.0022 5.28992 10.4543 5.28992H17.5494C18.0015 5.28992 18.368 5.65645 18.368 6.10858V20.0259H16.7307V17.5699Z"
							fill={
								path.includes("sites")
									? "rgba(16, 89, 152, 1)"
									: "#919EAB"
							}
						/>
					</svg>
					<h2 className="text-xl text-black font-futura-book">
						Sites
					</h2>
				</IconButton>
			</Tooltip>
			<Tooltip title={"Doors"} placement="right">
				<IconButton
					className="mobileNavButtons rounded-md justify-start"
					style={{
						display:
							user?.roleId ==
								process.env.REACT_APP_ADMIN_ROLE_ID ||
							user?.roleId ==
								process.env.REACT_APP_ORG_ADMIN_ROLE_ID ||
							user?.roleId == process.env.REACT_APP_USER_ROLE_ID
								? null
								: "none",
					}}
					onClick={() => navigate("/doors")}
					sx={{
						backgroundColor: path.includes("doors")
							? "rgba(241, 247, 250, 1)"
							: null,
						borderRadius: "0",
						" &:hover": {
							backgroundColor: "rgba(241, 247, 250, 1)",
							borderRadius: "0",
						},
						justifyContent: "center",
						display: "flex",
						gap: "6px",
					}}>
					<SensorDoorOutlinedIcon
						sx={{
							color: path.includes("doors")
								? "rgba(16, 89, 152, 1)"
								: "#919EAB",
						}}
					/>
					<h2 className="text-xl text-black font-futura-book">
						Doors
					</h2>
				</IconButton>
			</Tooltip>
			<Tooltip title={"Sensors"} placement="right">
				<IconButton
					className="mobileNavButtons rounded-md justify-start"
					style={{
						display:
							user?.roleId == process.env.REACT_APP_ADMIN_ROLE_ID
								? null
								: "none",
					}}
					onClick={() => navigate("/devices")}
					sx={{
						backgroundColor: path.includes("sensors")
							? "rgba(241, 247, 250, 1)"
							: null,
						borderRadius: "0",
						" &:hover": {
							backgroundColor: "rgba(241, 247, 250, 1)",
							borderRadius: "0",
						},
						justifyContent: "center",
						display: "flex",
						gap: "6px",
					}}>
					<svg
						width="21"
						height="16"
						viewBox="0 0 21 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M4.17123 0.949404C4.46413 1.2423 4.46413 1.71717 4.17123 2.01006C0.851781 5.32952 0.851781 10.7115 4.17123 14.0309C4.46413 14.3238 4.46413 14.7987 4.17123 15.0916C3.87834 15.3845 3.40347 15.3845 3.11057 15.0916C-0.794669 11.1863 -0.794669 4.85465 3.11057 0.949404C3.40347 0.656514 3.87834 0.656514 4.17123 0.949404ZM17.2527 0.949404C21.1579 4.85465 21.1579 11.1863 17.2527 15.0916C16.9598 15.3845 16.4849 15.3845 16.192 15.0916C15.8991 14.7987 15.8991 14.3238 16.192 14.0309C19.5115 10.7115 19.5115 5.32952 16.192 2.01006C15.8991 1.71717 15.8991 1.2423 16.192 0.949404C16.4849 0.656514 16.9598 0.656514 17.2527 0.949404ZM6.99966 3.77783C7.29255 4.07072 7.29255 4.5456 6.99966 4.83849C5.2423 6.59585 5.2423 9.44505 6.99966 11.2025C7.29255 11.4954 7.29255 11.9703 6.99966 12.2632C6.70677 12.5561 6.23189 12.5561 5.939 12.2632C3.59585 9.91995 3.59585 6.12095 5.939 3.77783C6.23189 3.48494 6.70677 3.48494 6.99966 3.77783ZM14.4242 3.77783C16.7674 6.12095 16.7674 9.91995 14.4242 12.2632C14.1313 12.5561 13.6565 12.5561 13.3636 12.2632C13.0707 11.9703 13.0707 11.4954 13.3636 11.2025C15.1209 9.44505 15.1209 6.59585 13.3636 4.83849C13.0707 4.5456 13.0707 4.07072 13.3636 3.77783C13.6565 3.48494 14.1313 3.48494 14.4242 3.77783ZM10.1816 6.52045C11.01 6.52045 11.6816 7.19205 11.6816 8.02045C11.6816 8.84885 11.01 9.52045 10.1816 9.52045C9.35324 9.52045 8.68164 8.84885 8.68164 8.02045C8.68164 7.19205 9.35324 6.52045 10.1816 6.52045Z"
							fill={
								path.includes("devices")
									? "rgba(16, 89, 152, 1)"
									: "#919EAB"
							}
						/>
					</svg>
					<h2 className="text-xl text-black font-futura-book">
						Devices
					</h2>
				</IconButton>
			</Tooltip>
			<Tooltip title={"Events"} placement="right">
				<IconButton
					className="mobileNavButtons rounded-md justify-start"
					style={{
						display:
							user?.roleId ==
								process.env.REACT_APP_ADMIN_ROLE_ID ||
							user?.roleId ==
								process.env.REACT_APP_USER_ROLE_ID ||
							user?.roleId ==
								process.env.REACT_APP_ORG_ADMIN_ROLE_ID
								? null
								: "none",
					}}
					onClick={() => navigate("/events")}
					sx={{
						backgroundColor: path.includes("events")
							? "rgba(241, 247, 250, 1)"
							: null,
						borderRadius: "0",
						" &:hover": {
							backgroundColor: "rgba(241, 247, 250, 1)",
							borderRadius: "0",
						},
						justifyContent: "center",
						display: "flex",
						gap: "6px",
					}}>
					<svg
						width="17"
						height="21"
						viewBox="0 0 17 21"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M9.93164 0.0205078C11.1225 0.0205078 12.0972 0.945668 12.1764 2.11646L12.1816 2.27051C12.1816 2.18582 12.1769 2.10221 12.1678 2.01995L13.9316 2.02051C15.1742 2.02051 16.1816 3.02787 16.1816 4.27051V17.7705C16.1816 19.0131 15.1742 20.0205 13.9316 20.0205H2.43164C1.189 20.0205 0.181641 19.0131 0.181641 17.7705V4.27051C0.181641 3.02787 1.189 2.02051 2.43164 2.02051L4.19543 2.01995C4.18897 2.07825 4.18474 2.13722 4.18283 2.19677L4.18164 2.27051C4.18164 1.02787 5.189 0.0205078 6.43164 0.0205078H9.93164ZM9.93164 4.52051H6.43164C5.63759 4.52051 4.93961 4.10918 4.53915 3.48797L4.56066 3.52071L2.43164 3.52051C2.01743 3.52051 1.68164 3.8563 1.68164 4.27051V17.7705C1.68164 18.1847 2.01743 18.5205 2.43164 18.5205H13.9316C14.3458 18.5205 14.6816 18.1847 14.6816 17.7705V4.27051C14.6816 3.8563 14.3458 3.52051 13.9316 3.52051L11.8026 3.52071L11.8241 3.48797C11.4236 4.10918 10.7257 4.52051 9.93164 4.52051ZM9.93164 1.52051H6.43164C6.01743 1.52051 5.68164 1.8563 5.68164 2.27051C5.68164 2.68472 6.01743 3.02051 6.43164 3.02051H9.93164C10.3458 3.02051 10.6816 2.68472 10.6816 2.27051C10.6816 1.8563 10.3458 1.52051 9.93164 1.52051ZM4.18164 12.5205H8.18164C8.59584 12.5205 8.93164 12.1847 8.93164 11.7705C8.93164 11.3563 8.59584 11.0205 8.18164 11.0205H4.18164C3.76743 11.0205 3.43164 11.3563 3.43164 11.7705C3.43164 12.1847 3.76743 12.5205 4.18164 12.5205ZM4.18164 8.52051H12.1816C12.5958 8.52051 12.9316 8.18471 12.9316 7.77051C12.9316 7.3563 12.5958 7.02051 12.1816 7.02051H4.18164C3.76743 7.02051 3.43164 7.3563 3.43164 7.77051C3.43164 8.18471 3.76743 8.52051 4.18164 8.52051ZM4.18164 16.5205H10.1816C10.5958 16.5205 10.9316 16.1847 10.9316 15.7705C10.9316 15.3563 10.5958 15.0205 10.1816 15.0205H4.18164C3.76743 15.0205 3.43164 15.3563 3.43164 15.7705C3.43164 16.1847 3.76743 16.5205 4.18164 16.5205Z"
							fill={
								path == "/events"
									? "rgba(16, 89, 152, 1)"
									: "#919EAB"
							}
						/>
					</svg>
					<h2 className="text-xl text-black font-futura-book">
						Events
					</h2>
				</IconButton>
			</Tooltip>
			<Tooltip title={"Organisations"} placement="right">
				<IconButton
					className="mobileNavButtons rounded-md justify-start"
					style={{
						display:
							user?.roleId ==
								process.env.REACT_APP_ADMIN_ROLE_ID ||
							user?.roleId ==
								process.env.REACT_APP_ORG_ADMIN_ROLE_ID
								? null
								: "none",
					}}
					onClick={() => navigate("/organizations")}
					sx={{
						backgroundColor: path.includes("organizations")
							? "rgba(241, 247, 250, 1)"
							: null,
						borderRadius: "0",
						" &:hover": {
							backgroundColor: "rgba(241, 247, 250, 1)",
							borderRadius: "0",
						},
						justifyContent: "center",
						display: "flex",
						gap: "6px",
					}}>
					<HubOutlinedIcon
						sx={{
							color: path.includes("organizations")
								? "rgba(16, 89, 152, 1)"
								: "#919EAB",
						}}
					/>
					<h2 className="text-xl text-black font-futura-book">
						Organizations
					</h2>
				</IconButton>
			</Tooltip>
			<Tooltip title={"Users"} placement="right">
				<IconButton
					className="mobileNavButtons rounded-md justify-start"
					style={{
						display:
							user?.roleId ==
								process.env.REACT_APP_ADMIN_ROLE_ID ||
							user?.roleId ==
								process.env.REACT_APP_ORG_ADMIN_ROLE_ID
								? null
								: "none",
					}}
					onClick={() => navigate("/users")}
					sx={{
						backgroundColor: path.includes("users")
							? "rgba(241, 247, 250, 1)"
							: null,
						borderRadius: "0",
						" &:hover": {
							backgroundColor: "rgba(241, 247, 250, 1)",
							borderRadius: "0",
						},
						justifyContent: "center",
						display: "flex",
						gap: "6px",
					}}>
					<GroupOutlinedIcon
						sx={{
							color: path.includes("users")
								? "rgba(16, 89, 152, 1)"
								: "#919EAB",
						}}
					/>
					<h2 className="text-xl text-black font-futura-book">
						Users
					</h2>
				</IconButton>
			</Tooltip>
		</Box>
	);

	return (
		<Drawer
			anchor={"top"}
			className="mt-16 w-auto bg-transparent"
			style={{
				"& .MuiDrawer-paper": {
					position: "absolute", //imp
					top: "64px",
				},
			}}
			classes={{
				paper: classes.paper,
				root: classes.root,
			}}
			open={open}
			onClose={toggleDrawer("top", false)}>
			{list("top")}
		</Drawer>
	);
}
