import React, { useEffect, useState } from "react";
import { Divider } from "@mui/material";
import { Table } from "../../Components/Table/Tables";
import { MenuItem } from "@mui/material";
import { getSites } from "../../Store/Features/Sites";
import AddressToArea from "../../Utils/Functions/getLocations";
import GapWarningIcon from "../../Screens/Asset/icons/GapWarning";
import SystemStatusIcon from "../../Screens/Asset/icons/SystemStatus";
import SlamWarningIcon from "../../Screens/Asset/icons/SlamWarning";
import OrientationChangeIcon from "../../Screens/Asset/icons/OrientationChange";
import VandalishWarningIcon from "../../Screens/Asset/icons/VandalishWarning";
import SystemResetStatusIcon from "../../Screens/Asset/icons/SystemResetStatus";
import StatusMissedIcon from "../../Screens/Asset/icons/StatusMissed";
import OpenTimeoutIcon from "../../Screens/Asset/icons/OpenTimeout";
import {
	getNotagainstorg,
	getAllNotifications,
} from "../../Store/Features/notifications";
import { useDispatch, useSelector } from "react-redux";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import { Select as MuiSelect } from "@mui/material";
import { Headings } from "../../Shared/Fonts/Fonts";
import search from "../../Assets/search.svg";
import {
	getOrganizations,
	getOrganization,
} from "../../Store/Features/Customer";
import SitesMap from "../../Components/Maps/SitesMap";

const AdminDashboard = () => {
	const dispatch = useDispatch();
	const { value: organizations } = useSelector((state) => state.customers);
	const { user } = useSelector((state) => state.auth);
	const notifications = useSelector((state) => state.notifications.value);
	const loadingOrg = useSelector((state) => state.customers.loading);
	const [org, setOrg] = useState(null);
	const [customer, setCustomer] = useState(0);
	const [sites, setSites] = useState([]);
	const [searchQuery, setSearchQuery] = useState("");
	const navigate = useNavigate();

	useEffect(() => {
		if (
			user?.roleId === process.env.REACT_APP_USER_ROLE_ID ||
			user?.roleId === process.env.REACT_APP_ORG_ADMIN_ROLE_ID
		) {
			setCustomer(user?.organizationId);
			dispatch(getOrganization(user?.organizationId)).then((res) => {
				if (!res.error) {
					setOrg(res.payload);
				}
			});

			dispatch(getSites({ orgId: user?.organizationId })).then((res) => {
				if (!res.error) {
					setSites(res.payload.data);
				}
			});
			dispatch(
				getNotagainstorg({
					page: 0,
					limit: 9,
					orgId: user?.organizationId,
					sort: "desc",
					field: "createdAt",
				})
			);
		} else {
			dispatch(getOrganizations());
			dispatch(getSites({ orgId: "" })).then((ress) => {
				if (!ress.error) {
					setSites(ress.payload.data);
				}
			});
			dispatch(
				getAllNotifications({
					page: 0,
					limit: 9,
					sort: "desc",
					field: "createdAt",
				})
			);
		}
	}, [dispatch, user]);

	const handleOrganizationChange = async (newOrgId) => {
		setCustomer(newOrgId);
		if (newOrgId && newOrgId !== 0) {
			const sitesRes = await dispatch(getSites({ orgId: newOrgId }));
			if (!sitesRes.error && sitesRes.payload?.data) {
				setSites(sitesRes.payload.data);
			}
			dispatch(
				getNotagainstorg({
					page: 0,
					limit: 9,
					orgId: newOrgId,
					sort: "desc",
					field: "createdAt",
				})
			);
		} else {
			const sitesRes = await dispatch(getSites({ orgId: "" }));
			if (!sitesRes.error && sitesRes.payload?.data) {
				setSites(sitesRes.payload.data);
			}
			dispatch(
				getAllNotifications({
					page: 0,
					limit: 9,
					sort: "desc",
					field: "createdAt",
				})
			);
		}
	};

	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
	};

	const handleViewAllEventsClick = () => {
		const isAppAdmin = user?.roleId === process.env.REACT_APP_ADMIN_ROLE_ID;

		if (isAppAdmin) {
			navigate("/events");
		} else {
			navigate(`/events?organization=${user?.organizationId}`);
		}
	};

	return (
		<div className="ContentWrapper">
			<div className="HeaderHeadline">
				<h1 className="HeadlineTitle">
					{user?.roleId === process.env.REACT_APP_ORG_ADMIN_ROLE_ID ||
					user?.roleId === process.env.REACT_APP_USER_ROLE_ID
						? org?.name
						: customer !== 0
						? org?.name
						: "Admin"}{" "}
					Dashboard
				</h1>
				<div className="flex flex-wrap items-center gap-4 sm:gap-5">
					{/* Only show organization select for admin users */}
					{user?.roleId !== process.env.REACT_APP_USER_ROLE_ID &&
						user?.roleId !==
							process.env.REACT_APP_ORG_ADMIN_ROLE_ID && (
							<div className="flex items-center gap-6">
								<Select
									options={organizations}
									onChange={handleOrganizationChange}
									value={customer}
									title="Organization"
									placeholder="Select Organization"
								/>
							</div>
						)}
					<div className="flex items-center bg-white h-10 sm:w-72 w-full border-gray-200 border border-solid rounded">
						<img src={search} alt="No search" className="mx-3" />
						<input
							className="text-gray-600 outline-none w-full pr-3"
							type="text"
							placeholder="Search..."
							value={searchQuery}
							onChange={handleSearchChange}
						/>
					</div>
				</div>
			</div>

			<div className="WrapperLayout">
				<div className="AdminLeftColumn gap-8">
					<div className="shadow-sm">
						<div className="bg-white h-96 p-5 w-full">
							<div className="w-full h-full">
								<SitesMap zoom={4} organizationId={customer} />
							</div>
						</div>
					</div>

					<Sites sites={sites} />
				</div>

				<div className="AdminRightColumn">
					<div className="flex justify-between w-full items-center">
						<h2 className="text-lg font-medium">Event History</h2>
						<button
							className="bg-harmony py-1 px-4 rounded"
							onClick={handleViewAllEventsClick}>
							<Headings>View All Events</Headings>
						</button>
					</div>
					<div className="w-full">
						<NotificationSection notifications={notifications} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default AdminDashboard;

function Select({ options = [], onChange, value, title, placeholder }) {
	const handleOptionChange = (event) => {
		const selectedValue = event.target.value;
		if (onChange) {
			onChange(selectedValue);
		}
	};
	const validValue = options.some((opt) => opt.id === value) ? value : 0;
	return (
		<MuiSelect
			value={validValue}
			onChange={handleOptionChange}
			className="border border-gray-200 bg-white flex gap-3 w-full font-futura font-normal"
			sx={{
				"& .MuiOutlinedInput-input": {
					paddingTop: "8px",
					paddingRight: "38px !important",
					paddingBottom: "8px",
					paddingLeft: "16px",
				},
				"&:focus": { bgcolor: "transparent" },
				"&: .MuiInputBase-root > .MuiOutlinedInput-notchedOutline": {
					border: "none",
				},
				display: "flex",
			}}
			displayEmpty
			placeholder={title}>
			<MenuItem className="py-0" key={0} value={0}>
				<Headings className="text-base font-normal font-futura-book">
					All Organizations
				</Headings>
			</MenuItem>
			{options?.map((option) => (
				<MenuItem
					key={option.id}
					value={option.id}
					className="capitalize">
					<Headings className="text-base font-normal font-futura-book">
						{option.name}
					</Headings>
				</MenuItem>
			))}
		</MuiSelect>
	);
}

export const Sites = ({ sites }) => {
	const navigate = useNavigate();
	const loading = useSelector((state) => state.sites.loading);

	const getStatusStyle = (status) => {
		switch (status) {
			case "Conforming":
				return {
					color: "#34A853",
					background: "rgba(52, 168, 83, 0.1)",
				};
			case "Warning":
				return {
					color: "rgba(221, 173, 1, 1)",
					background: "rgba(221, 173, 1, 0.1)",
				};
			case "Not Conforming":
				return {
					color: "#EB4335",
					background: "rgba(235, 67, 53, 0.1)",
				};
			default:
				return {
					color: "rgba(221, 173, 1, 1)",
					background: "rgba(221, 173, 1, 0.1)",
				};
		}
	};

	const columns = [
		{
			field: "site_name",
			headerName: "Site Name",
			flex: 1,
			minWidth: 180,
			sortable: true,
			renderCell: (params) => (
				<Headings className="text-base text-dark font-normal font-futura-book capitalize">
					{params.row.site_name}
				</Headings>
			),
		},
		{
			field: "site_location",
			headerName: "Location",
			flex: 1,
			minWidth: 180,
			renderCell: (params) => (
				<Headings className="text-base text-dark font-normal font-futura-book capitalize">
					{AddressToArea(params.row.site_location)}
				</Headings>
			),
		},
		{
			field: "description",
			headerName: "Description",
			flex: 1,
			minWidth: 180,
			renderCell: (params) => (
				<Headings className="text-base text-dark font-normal font-futura-book capitalize">
					{params.row.site_sector} - {params.row.total_doors}
				</Headings>
			),
		},
		{
			field: "site_status",
			headerName: "Sensor Status",
			flex: 1,
			minWidth: 180,
			renderCell: (params) => {
				const status =
					params?.row?.site_status || "Awaiting Installation";
				const { color, background } = getStatusStyle(status);
				return (
					<div
						className="flex justify-center items-center h-8 py-2 w-full"
						style={{ backgroundColor: background }}>
						<Headings
							color={color}
							className="text-base font-extralight font-futura-book capitalize">
							{status}
						</Headings>
					</div>
				);
			},
		},
	];

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const columnVisibilityModel = {
		description: !isSmallScreen,
		site_location: !isSmallScreen,
		// Essential columns
		site_name: true,
		site_status: true,
	};

	const handleRowClick = (row) => {
		if (row && row.id) {
			navigate(`/sites/${row.id}`);
		}
	};

	const tableRows = Array.isArray(sites)
		? sites.map((site) => ({
				id: site.site_id,
				...site,
		  }))
		: [];

	return (
		<Table
			height={true}
			rows={tableRows}
			columns={columns}
			rowSelection={false}
			getRowId={(row) => row.id || row.site_id}
			columnVisibilityModel={columnVisibilityModel}
			loading={loading}
			handleRowClick={handleRowClick}
		/>
	);
};

export const NotificationSection = ({ notifications }) => {
	const navigate = useNavigate();
	const getNotificationIcon = (type, statusColor) => {
		const notificationType = type?.toLowerCase() || "";
		switch (notificationType) {
			case "slam status":
				return <SlamWarningIcon color={statusColor} />;
			case "vandalism status":
				return <VandalishWarningIcon color={statusColor} />;
			case "missed transmission status":
				return <StatusMissedIcon color={statusColor} />;
			case "orientation status":
				return <OrientationChangeIcon color={statusColor} />;
			case "gap status":
				return <GapWarningIcon color={statusColor} />;
			case "timeout status":
				return <OpenTimeoutIcon color={statusColor} />;
			case "system status":
				return <SystemStatusIcon color={statusColor} />;
			default:
				return <SystemResetStatusIcon color={statusColor} />;
		}
	};

	const getStatusStyle = (status) => {
		return status?.toLowerCase()?.includes("warning")
			? "#FFCC49"
			: "#EB4335";
	};
	const handleNotificationClick = (item) => {
		console.log(item);
		navigate(
			`/sites/${item.siteId}/floor/${item.floorId}/asset/${item.doorId}`
		);
	};

	return (
		<div className="flex flex-col">
			{notifications?.map((item) => {
				const statusColor = getStatusStyle(item.status);

				return (
					<div key={item.id} className="flex flex-col">
						<div
							className="flex items-start gap-3 py-4 rounded hover:bg-gray-50 cursor-pointer"
							onClick={() => handleNotificationClick(item)}>
							<div className="mt-[6px]">
								{getNotificationIcon(item.type, statusColor)}
							</div>

							<div className="flex-1">
								<Headings className="text-sm text-gray-800 font-medium">
									{item.message}
								</Headings>
								<div className="flex justify-between items-center mt-1">
									<Headings className="text-xs text-gray-500">
										{new Date(
											item.created_at || item.createdAt
										).toLocaleString("en-GB", {
											hour: "2-digit",
											minute: "2-digit",
											day: "2-digit",
											month: "2-digit",
											year: "numeric",
										})}
									</Headings>
								</div>
							</div>
						</div>
						<Divider />
					</div>
				);
			})}

			{(!notifications || notifications.length === 0) && (
				<div className="flex justify-center items-center p-4 text-gray-500">
					No notifications found
				</div>
			)}
		</div>
	);
};
