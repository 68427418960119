import React, { useState, useEffect } from "react";
import authLogo from "../../Assets/authLogo.svg";
import {
	Card,
	CardContent,
	TextField,
	Button,
	InputAdornment,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import IconButton from "@mui/material/IconButton";
import "./Auth.css";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../Store/Features/Auth";
import { Headings, Text } from "../../Shared/Fonts/Fonts";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export function Login() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [emailError, setEmailError] = useState("");
	const [showPassword, setShowPassword] = React.useState(false);
	const [passwordError, setPasswordError] = useState("");
	const loading = useSelector((state) => state.auth.loading);

	const handleLogin = (e) => {
		// Prevent default form submission
		e.preventDefault();

		// Validate fields before performing login action
		if (!email) {
			setEmailError("Email is required");
			return;
		}
		if (!isValidEmail(email)) {
			setEmailError("Invalid email format");
			return;
		}
		if (!password) {
			setPasswordError("Password is required");
			return;
		}
		if (password.length < 6) {
			setPasswordError("Password should be at least 6 characters");
			return;
		}

		dispatch(login({ email: email, password: password })).then((res) => {
			if (!res.error) {
				navigate("/");
			}
		});
	};
	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	useEffect(() => {
		document.title = "Login";
	}, []);

	const isValidEmail = (email) => {
		// Regular expression for email validation
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	return (
		<div className="login-card">
			<div className="login-container">
				<div className="logo-container flex justify-center">
					<img src={authLogo} alt="Logo" />
				</div>
				<div className="HeadingText">
					<Headings className="text-lg">
						Welcome to Harmony Fire
					</Headings>
				</div>
				<form onSubmit={handleLogin} noValidate>
					<TextField
						label="Email Address"
						placeholder="Enter your email address"
						fullWidth
						className="input-field"
						variant="standard"
						value={email}
						onChange={(e) => {
							setEmail(e.target.value);
							setEmailError("");
						}}
						error={!!emailError}
						helperText={emailError}
					/>
					<TextField
						label="Password"
						variant="standard"
						placeholder="Enter your password"
						type={showPassword ? "text" : "password"}
						fullWidth
						className="input-field"
						value={password}
						onChange={(e) => {
							setPassword(e.target.value);
							setPasswordError("");
						}}
						error={!!passwordError}
						helperText={passwordError}
						InputProps={{
							endAdornment: (
								<IconButton
									aria-label="toggle password visibility"
									onClick={handleClickShowPassword}
									onMouseDown={handleMouseDownPassword}
									edge="end">
									{showPassword ? (
										<VisibilityOff />
									) : (
										<Visibility />
									)}
								</IconButton>
							),
						}}
					/>
					<div className="flex justify-end">
						<a
							href="/"
							className="Forget-password cursor-pointer"
							onClick={() => navigate("/reset-password")}>
							Forgot password?
						</a>
					</div>
					<div className="flex">
						<Button
							type="submit" // Ensures this button triggers the form submission
							variant="contained"
							color="primary"
							className="bg-harmony !py-3 !px-4 !rounded-full !shadow-none !text-base !font-futura w-full"
							onClick={handleLogin}>
							{loading ? (
								<CircularProgress
									size={24}
									sx={{ color: "white" }}
								/>
							) : (
								"SIGN IN"
							)}
						</Button>
					</div>
				</form>
			</div>
		</div>
	);
}
