import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
} from "chart.js";
import 'chartjs-adapter-date-fns';
import { format, parseISO } from "date-fns";

ChartJS.register(
  ArcElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Legend,
  TimeScale
);

const DynamicLineChartVBat = ({ data, chartTitle, xLabel, yLabel }) => {
  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  const processedData = data
    .filter(entry => entry && entry.createdAt && entry.vbatmv !== undefined)
    .map(entry => ({
      x: parseISO(entry.createdAt),
      y: entry.vbatmv
    }))
    .sort((a, b) => a.x - b.x);

  const chartData = {
    datasets: [
      {
        label: "Voltage",
        data: processedData,
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        fill: false,
        pointBorderWidth: 2,
        pointRadius: 3,
        pointHitRadius: 10,
        spanGaps: true,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: chartTitle || "Battery Voltage Over Time",
      },
      tooltip: {
        callbacks: {
          title: (context) => format(context[0].parsed.x, 'yyyy-MM-dd HH:mm'),
          label: (context) => `Voltage: ${(context.parsed.y / 1000).toFixed(2)} V`,
        }
      }
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'MMM dd'
          },
          tooltipFormat: 'yyyy-MM-dd HH:mm'
        },
        title: {
          display: true,
          text: xLabel || "Date",
        },
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          font: {
            size: 10,
            family: "Futura Pt",
          },
        },
      },
      y: {
        title: {
          display: true,
          text: yLabel || "Voltage (V)",
        },
        ticks: {
          callback: function(value) {
            return (value / 1000).toFixed(2) + ' V';
          }
        },
        beginAtZero: false,
      },
    },
  };

  return <Line data={chartData} options={chartOptions} />;
};

export default DynamicLineChartVBat;