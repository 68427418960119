import * as React from "react";
import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import "./SidePanels.css";
import { SubHeadings } from "../../Shared/Fonts/Fonts";

const AssetDrawer = ({ siteInfo }) => {
	const {
		siteName,
		organization,
		totalDoors,
		totalSensors,
		totalFloors,
		sector,
		address,
	} = siteInfo || {};

	return (
		<Box>
			<Box className="flex flex-col gap-4">
				<div className="flex flex-col">
					<h2 className="HeadlineTitle">{siteName || "N/A"}</h2>
					{organization && (
						<span className="text-sm text-gray-500">
							{organization}
						</span>
					)}
				</div>

				<Divider className="w-full" />

				<div className="flex justify-between">
					<SubHeadings className="text-base text-black font-medium leading-9">
						Building Type
					</SubHeadings>
					<SubHeadings className="font-futura-book text-base text-dark font-normal leading-9">
						{sector || "N/A"}
					</SubHeadings>
				</div>

				<div className="flex justify-between">
					<SubHeadings className="leading-9 text-base text-black font-medium">
						No. of doors
					</SubHeadings>
					<SubHeadings className="leading-9 text-base font-futura-book text-dark font-normal">
						{totalDoors || 0}
					</SubHeadings>
				</div>

				<div className="flex justify-between">
					<SubHeadings className="leading-9 text-base text-black font-medium">
						No. of sensors
					</SubHeadings>
					<SubHeadings className="leading-9 text-base font-futura-book text-dark font-normal">
						{totalSensors || 0}
					</SubHeadings>
				</div>

				<div className="flex justify-between">
					<SubHeadings className="leading-9 text-base text-black font-medium">
						No. of floors
					</SubHeadings>
					<SubHeadings className="leading-9 text-base font-futura-book text-dark font-normal">
						{totalFloors || 0}
					</SubHeadings>
				</div>

				{address && (
					<div className="flex justify-between">
						<SubHeadings className="leading-9 text-base text-black font-medium">
							Address
						</SubHeadings>
						<SubHeadings className="leading-9 text-base font-futura-book text-dark font-normal max-w-[60%] text-right">
							{address}
						</SubHeadings>
					</div>
				)}
			</Box>
		</Box>
	);
};

export default AssetDrawer;
