import { createSlice } from "@reduxjs/toolkit";
import {
	postApi,
	getApi,
	deleteApi,
	putApi,
	uploadService,
} from "../../Utils/serviceWorker";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

export const getDoors = createAsyncThunk(
	"doors/get",
	async (credentials, thunkAPI) => {
		try {
			const response = await getApi(
				`doors?page=${credentials.page}&limit=${credentials.limit}&${credentials.query}=${credentials.id}&
      `,
				true
			);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const getDoorsofFloor = createAsyncThunk(
	"doors/floors",
	async (credentials, thunkAPI) => {
		try {
			const response = await getApi(`doors?floor=${credentials}`, true);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const getDoor = createAsyncThunk(
	"door/get",
	async (credentials, thunkAPI) => {
		try {
			const response = await getApi(`doors/${credentials}`, true);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const uploadDoorImage = createAsyncThunk(
	"door/image",
	async (credentials, thunkAPI) => {
		try {
			const response = await uploadService(
				`file/upload/doors/door_image`,
				true,
				credentials
			);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const addDoor = createAsyncThunk(
	"door/add",
	async (credentials, thunkAPI) => {
		try {
			const response = await postApi(`doors`, true, credentials);

			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const updateDoor = createAsyncThunk(
	"door/update",
	async (credentials, thunkAPI) => {
		try {
			const response = await putApi(
				`doors/${credentials.id}`,
				true,
				credentials
			);

			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const deleteDoor = createAsyncThunk(
	"door/delete",
	async (credentials, thunkAPI) => {
		try {
			const response = await deleteApi(`doors/${credentials}`, true);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const getDoorsOfInstalltion = createAsyncThunk(
	"doors/Installation",
	async (credentials, thunkAPI) => {
		try {
			const response = await getApi(
				`doors?assignee=${credentials}`,
				true
			);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const getDoorsOfOrg = createAsyncThunk(
	"doors/organization",
	async (credentials, thunkAPI) => {
		try {
			const response = await getApi(
				`doors/doorsagainstorganization/${credentials}`,
				true
			);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const uploadDoc = createAsyncThunk(
	"door/upload/doc",
	async (credentials, thunkAPI) => {
		try {
			const response = await uploadService(
				`file/upload/${credentials.path}`,
				true,
				credentials.data
			);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);
const initialState = {
	value: [],
	count: 0,
	loading: false,
	error: null,
	imageLoading: false,
};
export const Doors = createSlice({
	name: "door",
	initialState,
	reducers: {
		reset: () => initialState,
	},
	extraReducers: (builder) => {
		builder.addCase(getDoors.pending, (state) => {
			state.loading = true;
			state.error = null;
		});
		builder.addCase(getDoors.fulfilled, (state, action) => {
			state.loading = false;
			state.value = action.payload.data;
			state.count = action.payload.count || action.payload?.data?.length;
		});
		builder.addCase(getDoors.rejected, (state, action) => {
			state.loading = false;

			state.error = action.payload;
		});
		builder.addCase(updateDoor.pending, (state) => {
			state.loading = true;
			state.error = null;
		});
		builder.addCase(updateDoor.fulfilled, (state, action) => {
			state.loading = false;
			toast.success("Updated Door Successfully");
		});
		builder.addCase(updateDoor.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload;
		});
		builder.addCase(addDoor.pending, (state) => {
			state.loading = true;
			state.error = null;
		});
		builder.addCase(addDoor.fulfilled, (state, action) => {
			state.loading = false;
			toast.success("Created Door Successfully");
		});
		builder.addCase(addDoor.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload;
		});
		builder.addCase(getDoorsOfInstalltion.pending, (state) => {
			state.loading = true;
			state.error = null;
		});
		builder.addCase(getDoorsOfInstalltion.fulfilled, (state, action) => {
			state.loading = false;

			state.value = action.payload.data;
		});
		builder.addCase(getDoorsOfInstalltion.rejected, (state, action) => {
			state.loading = false;

			state.error = action.payload;
		});
		builder.addCase(uploadDoorImage.pending, (state) => {
			state.imageLoading = true;
			state.error = null;
		});
		builder.addCase(uploadDoorImage.fulfilled, (state, action) => {
			state.imageLoading = false;
			toast.success("Door Image Uploaded");
		});
		builder.addCase(uploadDoorImage.rejected, (state, action) => {
			state.imageLoading = false;

			state.error = action.payload;
		});
		builder.addCase(getDoorsofFloor.pending, (state) => {
			state.loading = true;
			toast.loading("Fetching Doors", {
				toastId: "doorsFetching",
			});
			state.error = null;
		});
		builder.addCase(getDoorsofFloor.fulfilled, (state, action) => {
			toast.dismiss();
			state.loading = false;
		});
		builder.addCase(getDoorsofFloor.rejected, (state, action) => {
			toast.dismiss();
			state.loading = false;
			state.error = action.payload;
		});

		builder.addCase(uploadDoc.pending, (state) => {
			// state.loading = true;
			toast.loading("Uploading Assets");
			state.error = null;
		});
		builder.addCase(uploadDoc.fulfilled, (state, action) => {
			toast.dismiss();
			toast.success("Uploaded Asset");
			// state.loading = false;
		});
		builder.addCase(uploadDoc.rejected, (state, action) => {
			toast.dismiss();
			// toast.error("Uploaded Asset");
			// state.loading = false;
			state.error = action.payload;
		});
		builder.addCase(getDoorsOfOrg.pending, (state) => {
			state.loading = true;
			state.error = null;
		});
		builder.addCase(getDoorsOfOrg.fulfilled, (state, action) => {
			state.loading = false;

			state.value = action.payload.data;
		});
		builder.addCase(getDoorsOfOrg.rejected, (state, action) => {
			state.loading = false;

			state.error = action.payload;
		});
	},
});
