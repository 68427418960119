import React, { useState, useEffect } from "react";
import { Headings } from "../../Shared/Fonts/Fonts";

export const SensorLocation = ({ form, setForm, data }) => {
    const [topDisabled, setTopDisabled] = useState(false);

    useEffect(() => {
        if (data?.door?.sensors) {
            const hasTopSensor = data.door.sensors.some((sensor) => sensor.location === "Top");
            setTopDisabled(hasTopSensor);
        }
    }, [data?.door?.sensors]);

    return (
        <div className="flex justify-center">
            <div className="flex flex-col items-center text-center gap-3">
                <h2 className="text-lg font-medium">Sensor Location?</h2>
                <div className="flex gap-3">
                    <button
                        type="button"
                        className={`py-1 px-4 ${
                            topDisabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                        }`}
                        style={{
                            backgroundColor: form.location === "Top" ? "#105998" : "#6B7280"
                        }}
                        disabled={topDisabled}
                        onClick={() => {
                            if (!topDisabled) {
                                setForm((prev) => ({ ...prev, location: "Top" }));
                            }
                        }}
                    >
                        <Headings>
                            {topDisabled ? "Top (Already Installed)" : "Top"}
                        </Headings>
                    </button>
                    <button
                        type="button"
                        className="bg-gray-500 py-1 px-4 cursor-pointer"
                        style={{
                            backgroundColor: form.location === "Side" ? "#105998" : "#6B7280"
                        }}
                        onClick={() => {
                            setForm((prev) => ({ ...prev, location: "Side" }));
                        }}
                    >
                        <Headings>Side</Headings>
                    </button>
                </div>
                {topDisabled && (
                    <p className="text-sm text-gray-500 mt-2">
                        A top sensor is already installed. You can only select the side location.
                    </p>
                )}
            </div>
        </div>
    );
};