import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
	TextField,
	Button,
	Grid,
	Avatar,
	IconButton,
	CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { profile } from "../../Store/Features/Auth";
import { logout } from "../../Screens/Auth/Logout";
import {
	getUser,
	updateUser,
	addUser,
	uploadAvatar,
} from "../../Store/Features/Users";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { API_URL } from "../../Utils/Functions/utils";
import { Headings } from "../../Shared/Fonts/Fonts"; // Custom component for headings

const Profile = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate(); // For redirecting after logout
	const { user } = useSelector((state) => state.auth);
	const { loading } = useSelector((state) => state.users);

	const [showPassword, setShowPassword] = useState(false);
	const [formData, setFormData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		phoneNumber: "",
		avatar: "",
		roles: "",
		password: "",
	});
	const [errors, setErrors] = useState({});

	// Handle form field changes
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({ ...prevData, [name]: value }));
	};

	// Handle password visibility toggle
	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	// Validate form
	const validateForm = () => {
		const newErrors = {};
		let valid = true;

		if (!formData.firstName.trim()) {
			newErrors.firstName = "First Name is required";
			valid = false;
		}
		if (!formData.lastName.trim()) {
			newErrors.lastName = "Last Name is required";
			valid = false;
		}
		if (!formData.email.trim()) {
			newErrors.email = "Email is required";
			valid = false;
		} else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
			newErrors.email = "Invalid email format";
			valid = false;
		}
		if (!formData.phoneNumber.trim()) {
			newErrors.phoneNumber = "Phone number is required";
			valid = false;
		} else if (!/^\d{10}$/.test(formData.phoneNumber)) {
			newErrors.phoneNumber = "Invalid phone number (10 digits required)";
			valid = false;
		}

		setErrors(newErrors);
		return valid;
	};

	// Handle form submission
	const handleFormSubmit = (event) => {
		event.preventDefault();

		if (validateForm()) {
			if (formData.password !== "") {
				dispatch(updateUser({ id: user.id, data: formData })).then(
					() => {
						dispatch(profile());
					}
				);
			} else {
				const { password, ...rest } = formData;
				dispatch(updateUser({ id: user.id, data: rest })).then(() => {
					dispatch(profile());
				});
			}
		}
	};

	// Handle logout
	const handleLogout = () => {
		dispatch(logout()).then(() => {
			navigate("/login");
		});
	};

	// Handle avatar change
	const handleAvatar = (name, value) => {
		setFormData((prevData) => ({ ...prevData, [name]: value }));
	};

	useEffect(() => {
		if (user) {
			setFormData({
				firstName: user.firstName || "",
				lastName: user.lastName || "",
				email: user.email || "",
				phoneNumber: user.phoneNumber || "",
				avatar: user.avatar || "",
				roles: user.roleId || "",
				password: "", // Keep password field empty
			});
		}
	}, [user]);

	useEffect(() => {
		document.title = "Profile";
	}, []);

	return (
		<div className="ContentWrapper">
			<div className="UserDetails bg-white py-4 px-6 mb-4 shadow-sm flex justify-between items-center flex-wrap sm:flex-nowrap gap-4">
				<div className="flex items-center gap-4">
					<Avatar
						className="!w-24 !h-24"
						src={
							formData.avatar[0]?.publicUrl ||
							formData.avatar?.publicUrl
						}
					/>
					<div>
						<h2 className="text-2xl font-medium">
							{user.firstName} {user.lastName}
						</h2>
						<p className="text-gray-600">{user.email}</p>
						<p className="text-gray-600">{user.phoneNumber}</p>
					</div>
				</div>
				<Button
					className="!bg-red-600 text-white"
					variant="contained"
					onClick={handleLogout}>
					Logout
				</Button>
			</div>

			<div className="bg-white shadow-sm p-5">
				<form
					className="flex flex-col gap-3 items-center"
					onSubmit={handleFormSubmit}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<TextField
								label="First Name"
								variant="filled"
								fullWidth
								name="firstName"
								value={formData.firstName}
								onChange={handleChange}
								error={!!errors.firstName}
								helperText={errors.firstName}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								label="Last Name"
								variant="filled"
								fullWidth
								name="lastName"
								value={formData.lastName}
								onChange={handleChange}
								error={!!errors.lastName}
								helperText={errors.lastName}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								label="Email"
								variant="filled"
								fullWidth
								name="email"
								value={formData.email}
								onChange={handleChange}
								error={!!errors.email}
								helperText={errors.email}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								label="Phone Number"
								variant="filled"
								fullWidth
								name="phoneNumber"
								value={formData.phoneNumber}
								onChange={handleChange}
								error={!!errors.phoneNumber}
								helperText={errors.phoneNumber}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								label="Password"
								variant="filled"
								placeholder="Enter new password"
								type={showPassword ? "text" : "password"}
								fullWidth
								name="password"
								value={formData.password}
								onChange={handleChange}
								InputProps={{
									endAdornment: (
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={
												handleMouseDownPassword
											}
											edge="end">
											{showPassword ? (
												<VisibilityOff />
											) : (
												<Visibility />
											)}
										</IconButton>
									),
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<h3 className="text-base font-medium mb-3">
								Change Profile Picture
							</h3>
							<ImageUploadButton
								onChange={handleAvatar}
								name="avatar"
								path="users/avatar"
							/>
						</Grid>
						<Grid item xs={12}>
							<Button
								className="!bg-harmony py-1 px-4 rounded !mt-5"
								disabled={loading}
								variant="contained"
								type="submit">
								{loading ? (
									<CircularProgress
										size={16}
										sx={{ color: "white" }}
									/>
								) : (
									<Headings>UPDATE PROFILE</Headings>
								)}
							</Button>
						</Grid>
					</Grid>
				</form>
			</div>
		</div>
	);
};

export default Profile;

// ImageUploadButton Component

const ImageUploadButton = ({ onChange, name, path }) => {
	const dispatch = useDispatch();

	const handleImageChange = async (event) => {
		const selectedFile = event.target.files[0];
		const extension = extractExtensionFrom(selectedFile.name);
		const id = uuidv4();
		const filename = `${id}.${extension}`;
		const privateUrl = `${path}/${filename}`;
		const publicUrl = API_URL() + `file/download?privateUrl=${privateUrl}`;
		const formData = new FormData();
		formData.append("file", selectedFile);
		formData.append("filename", filename);

		try {
			// Upload the avatar
			await dispatch(uploadAvatar(formData));
			// Update the parent component's state with the new avatar info
			onChange("avatar", {
				id,
				name: selectedFile.name,
				sizeInBytes: selectedFile.size,
				privateUrl,
				publicUrl,
				new: true,
			});
		} catch (error) {
			console.error("Image upload failed", error);
		}
	};

	return (
		<div>
			<input
				className="hidden"
				accept="image/*"
				id="image-upload-input"
				type="file"
				onChange={handleImageChange}
			/>
			<label htmlFor="image-upload-input">
				<Button
					type="button"
					className="!bg-harmony py-1 px-4 rounded"
					variant="contained"
					component="span">
					<Headings>Upload Image</Headings>
				</Button>
			</label>
		</div>
	);
};

function extractExtensionFrom(filename) {
	if (!filename) {
		return null;
	}

	const regex = /(?:\.([^.]+))?$/;
	return regex.exec(filename)[1];
}
