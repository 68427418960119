import React from "react";
import "./Door.css";
import DoorBackOutlinedIcon from "@mui/icons-material/DoorBackOutlined";
import DoorFrontOutlinedIcon from "@mui/icons-material/DoorFrontOutlined";
import { Headings } from "../../Shared/Fonts/Fonts";
import { mainStatusIndicator } from "../../Screens/Asset/StatusIndicators";

export const DoorCard = (props) => {
	const { description, direction, doorType, sensors } = props;

	const mainStatus = mainStatusIndicator({ props });

	return (
		<div className="bg-harmony p-6 flex gap-4 justify-between box-border">
			<div className="flex items-center gap-4">
				<div className="flex flex-col w-full">
					<h6 className="text-white HeadlineTitle">{description}</h6>
					{doorType && (
						<h6 className="text-white text-lg font-medium">
							{doorType}
						</h6>
					)}
				</div>
				<div
					className="flex justify-center items-center h-8 p-2 w-full rounded-md whitespace-nowrap"
					style={{
						backgroundColor: mainStatus.background,
					}}>
					<Headings color={mainStatus.color}>
						{mainStatus.status}
					</Headings>
				</div>
			</div>
		</div>
	);
};
