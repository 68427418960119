import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUsers, deleteUser } from "../../Store/Features/Users";
import { Headings } from "../../Shared/Fonts/Fonts";
import { DataGrid } from "@mui/x-data-grid";
import { IconButton, Menu, MenuItem } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Edit from "../../Assets/Edit.svg";
import search from "../../Assets/search.svg";
import "./Roles.css";

const Roles = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { loading, value: users } = useSelector((state) => state.users);
	const [searchQuery, setSearchQuery] = useState("");
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const [sortModel, setSortModel] = useState([
		{ field: "createdAt", sort: "desc" },
	]);

	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
		setPaginationModel({ ...paginationModel, page: 0 });
	};

	const handleDeleteUser = (id) => {
		dispatch(deleteUser(id)).then(() => {
			fetchInstallationManagers();
		});
	};

	const fetchInstallationManagers = () => {
		dispatch(
			getUsers({
				page: paginationModel.page + 1,
				limit: paginationModel.pageSize,
				search: searchQuery,
				sort: sortModel[0].sort,
				field: sortModel[0].field,
				roleId: process.env.REACT_APP_AGENT_ROLE_ID,
			})
		);
	};

	useEffect(() => {
		document.title = "Installation Manager";
	}, []);

	useEffect(() => {
		if (sortModel && sortModel[0].sort) {
			fetchInstallationManagers();
		}
	}, [dispatch, paginationModel, sortModel, searchQuery]);

	const columns = [
		{
			field: "firstName",
			headerName: "Name",
			flex: 1,
			renderCell: (params) => (
				<Headings className="font-normal text-dark font-futura-book">
					{`${params.row.firstName} ${params.row.lastName || ""}`}
				</Headings>
			),
		},
		{ field: "phoneNumber", headerName: "Phone Number", flex: 1 },
		{
			field: "email",
			headerName: "Email",
			flex: 1,
			renderCell: (params) => (
				<Headings className="text-base font-normal text-dark font-futura-book">
					{params.row.email}
				</Headings>
			),
		},
		{
			field: "role",
			headerName: "Role",
			flex: 1,
			renderCell: (params) => (
				<Headings className="font-normal text-dark font-futura-book">
					{params.row.roles?.title}
				</Headings>
			),
		},
		{
			field: "actions",
			headerName: "Actions",
			flex: 0.5,
			sortable: false,
			renderCell: (params) => (
				<ActionsDropdown
					userId={params.row.id}
					onDelete={handleDeleteUser}
				/>
			),
		},
	];
	return <div></div>;
	// return (
	// <div className="ContentWrapper">
	// 		<div className="HeaderHeadline">
	// 			<div className="flex gap-4">
	// 				<h1 className="HeadlineTitle">Installation Managers</h1>
	// 			</div>
	// 			<div className="flex items-center bg-white h-10 sm:w-80 w-full max-w-full border-gray-200 border border-solid rounded">
	// 				<img src={search} alt="No search" className="mx-3" />
	// 				<input
	// 					className="text-gray-600 outline-none w-full pr-3"
	// 					type="text"
	// 					placeholder="Search..."
	// 					value={searchQuery}
	// 					onChange={handleSearchChange}
	// 				/>
	// 			</div>
	// 		</div>
	// 		<div className="TableWrapper">
	// 			<DataGrid
	// 				rows={users?.data || []}
	// 				columns={columns}
	// 				rowCount={users?.totalItems || 0}
	// 				loading={loading}
	// 				pageSizeOptions={[5, 10, 20, 25, 50, 100]}
	// 				paginationModel={paginationModel}
	// 				paginationMode="server"
	// 				onPaginationModelChange={setPaginationModel}
	// 				sortModel={sortModel}
	// 				onSortModelChange={(model) =>
	// 					model.length > 0 ? setSortModel(model) : null
	// 				}
	// 				className="px-5 py-0 w-full bg-white !border-none !rounded-none shadow-sm !min-h-96"
	// 				sx={{
	// 					"& .MuiDataGrid-iconButtonContainer": {
	// 						visibility: "visible",
	// 					},
	// 					"& .MuiDataGrid-sortIcon": {
	// 						opacity: "inherit !important",
	// 					},
	// 					"& .MuiDataGrid-cell": {
	// 						display: "flex",
	// 						alignItems: "center",
	// 						padding: "0",
	// 						color: "#212B36",
	// 						fontFamily: "Futura PT Book !important",
	// 						cursor: "pointer",
	// 					},
	// 					"& .MuiDataGrid-columnHeaders": {
	// 						fontFamily: "Futura PT",
	// 						fontWeight: "500",
	// 						fontSize: 17,
	// 						padding: "0",
	// 					},
	// 					"& .MuiTablePagination-select": {
	// 						background: "#EEE",
	// 					},
	// 					"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
	// 						outline: "none !important",
	// 					},
	// 					"& > .MuiDataGrid-columnSeparator": {
	// 						display: "none !important",
	// 					},
	// 				}}
	// 				disableColumnMenu
	// 				disableColumnSelector
	// 			/>
	// 		</div>
	// 	</div>
	// );
};

const ActionsDropdown = ({ userId, onDelete }) => {
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDeleteClick = () => {
		onDelete(userId);
		handleClose();
	};

	return (
		<div>
			<IconButton variant="outlined" onClick={handleClick}>
				<img src={Edit} alt="Edit" />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}>
				<MenuItem onClick={handleDeleteClick}>
					<DeleteIcon fontSize="small" />
					Delete
				</MenuItem>
				<MenuItem
					onClick={() =>
						navigate(`/installation_manager/edit/${userId}`)
					}>
					<EditIcon fontSize="small" />
					Edit
				</MenuItem>
			</Menu>
		</div>
	);
};

export default Roles;
