import React from "react";

const LowSignalIcon = ({ color }) => (
	<svg
		width="27"
		height="27"
		viewBox="0 0 27 27"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<line x1="5.5" x2="5.5" y2="27" stroke={color} strokeWidth="3" />
		<path d="M10.5 8.5L10.5 27" stroke={color} strokeWidth="3" />
		<path d="M15.5 14V27" stroke={color} strokeWidth="3" />
		<path d="M20.5 20V27" stroke={color} strokeWidth="3" />
		<path d="M25.5 24.5V27" stroke={color} strokeWidth="3" />
	</svg>
);

export default LowSignalIcon;
