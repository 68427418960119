import React from "react";

const VandalishWarningIcon = ({ color }) => (
	<svg
		width="27"
		height="27"
		viewBox="0 0 27 27"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<rect
			x="7.91216"
			y="0.912162"
			width="17.8784"
			height="25.1757"
			fill="white"
			stroke={color}
			strokeWidth="1.82432"
		/>
		<path
			d="M13.0557 1V6.5M13.0557 6.5V12L8.55566 10L12.0557 14L13.0557 18L17.0557 14L19.0557 20.5L22.8057 12M13.0557 6.5L17.0557 10L20.5557 4V10L22.8057 12M25.0557 14L22.8057 12"
			stroke={color}
		/>
	</svg>
);

export default VandalishWarningIcon;
