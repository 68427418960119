import React, { useState } from "react";
import { Button, Modal, TextField } from "@mui/material";
import "./Modal.css";
import { Headings } from "../../Shared/Fonts/Fonts";
import { updateFloor } from "../../Store/Features/Floors";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";

const CustomModal = ({
	floorData,
	setFloorData,
	handleImageUpload,
	isOpen,
	setIsOpen,
	imageError,
	isLoading = false,
}) => {
	const dispatch = useDispatch();
	const { floorId } = useParams();
	const { loading } = useSelector((state) => state.floors);
	const handleEditFloor = () => {
		dispatch(updateFloor({ id: floorId, data: floorData })).then((res) => {
			if (!res.error) {
				handleClose();
			}
		});
	};

	const handleClose = () => {
		setIsOpen(false);
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		// Update the floorData state based on the field name
		// Assuming the keys in floorData match the field names
		setFloorData((prevData) => ({ ...prevData, [name]: value }));
	};

	const handleImageChange = (event) => {
		const imageFile = event.target.files[0];
		handleImageUpload(imageFile); // Call the provided function with the selected image
	};

	{
		isLoading && (
			<div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
				<CircularProgress />
			</div>
		);
	}

	return (
		<div>
			<Modal open={isOpen} onClose={handleClose} className="custom-modal">
				<div className="modal-content">
					<TextField
						label="Floor Name"
						name="description"
						value={floorData.description}
						onChange={handleChange}
						fullWidth
						variant="outlined"
						id="outlined-basic"
						classes={{ root: "outlined-input" }}
					/>
					<TextField
						label="Floor Number"
						name="floor_no"
						value={floorData.floor_no}
						onChange={handleChange}
						fullWidth
						variant="outlined"
						id="outlined-basic"
						classes={{ root: "outlined-input" }}
					/>
					{floorId ? (
						<Button
							variant="outlined"
							component="span"
							fullWidth
							onClick={handleEditFloor}>
							{loading ? (
								<CircularProgress
									size={16}
									sx={{ color: "white" }}
								/>
							) : (
								"Save"
							)}
						</Button>
					) : (
						<>
							<label htmlFor="image-button">
								<input
									type="file"
									id="image-button"
									style={{ display: "none" }}
									onChange={handleImageChange}
								/>
								<Button
									variant="outlined"
									component="span"
									fullWidth>
									Upload Image
								</Button>
							</label>
							<Headings className="text-red-600">
								{imageError}
							</Headings>
						</>
					)}
				</div>
			</Modal>
		</div>
	);
};

export default CustomModal;
