import React, { useState } from "react";

import { Divider } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { Headings, SubHeadings } from "../../Shared/Fonts/Fonts";
import "./Maps.css";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

export const MapMarker = (props) => {
	const { status, Location, country, solution, sector, name, id, Error } =
		props;
	const { Component, color } = useStatus(status);
	console.log("status", status);
	console.log("color", color);
	return (
		<div
			className="marker-layout-Map"
			style={{ borderTop: `1.5px solid ${color}` }}>
			<div className="HeadingMarker">
				<div className="HeadingAddress">
					<Headings className="text-base">{name}</Headings>
					<Component />
				</div>

				<SubHeadings className="text-sm" color={color}>
					{status}
				</SubHeadings>
			</div>
			<Divider />
			<div className="BottomSection">
				<div className="fx-space p-0">
					<SubHeadings className="text-base text-black">
						Solution
					</SubHeadings>
					<SubHeadings className="font-futura-book text-sm text-black font-normal">
						{solution}
					</SubHeadings>
				</div>
				<div className="fx-space p-0">
					<SubHeadings className="text-sm text-black">
						Sector
					</SubHeadings>
					<SubHeadings className="font-futura-book text-sm text-black font-normal">
						{sector}
					</SubHeadings>
				</div>
			</div>
		</div>
	);
};

const useStatus = (stat) => {
	if (stat === "Conforming") {
	  const Component = () => {
		return <CheckCircleOutlineOutlinedIcon className="w-4 h-4 text-[#34A853]" />;
	  };
	  return {
		Component,
		color: "#34A853",
		background: "linear-gradient(0deg, var(--green-10, rgba(52, 168, 83, 0.10)) 0%, var(--green-10, rgba(52, 168, 83, 0.10)) 100%), var(--White, #FFF)",
	  };
	} else if (stat === "Not Conforming") {
	  const Component = () => {
		return <WarningAmberIcon className="w-4 h-4 text-[#EB4335]" />;
	  };
	  return {
		Component,
		color: "#EB4335",
		background: "linear-gradient(0deg, var(--red-10, rgba(235, 67, 53, 0.10)) 0%, var(--red-10, rgba(235, 67, 53, 0.10)) 100%), var(--White, #FFF)",
	  };
	} else if (stat === "Warning") {
	  const Component = () => {
		return <WarningAmberIcon className="w-4 h-4 text-[#ddad01]" />;
	  };
	  return {
		Component,
		color: "rgba(221, 173, 1, 1)",
		background: "linear-gradient(0deg, var(--yellow-10, rgba(221, 173, 1, 0.10)) 0%, var(--yellow-10, rgba(221, 173, 1, 0.10)) 100%), var(--White, #FFF)",
	  };
	} else {
	  const Component = () => {
		return <WarningAmberIcon className="w-4 h-4 text-[#ddad01]" />;
	  };
	  return {
		Component,
		color: "rgba(221, 173, 1, 1)",
		background: "linear-gradient(0deg, var(--yellow-10, rgba(221, 173, 1, 0.10)) 0%, var(--yellow-10, rgba(221, 173, 1, 0.10)) 100%), var(--White, #FFF)",
	  };
	}
  };
