import React, { useState, useEffect } from "react";
import {
	GoogleMap,
	useJsApiLoader,
	Marker,
	InfoWindow,
} from "@react-google-maps/api";
import ReactDOM from "react-dom/client";
import { MapMarker } from "./Marker";
import RoomIcon from "@mui/icons-material/Room";
import Home from "../../Assets/pin1.svg";
import Warning from "../../Assets/pin2.svg";
import Ok from "../../Assets/pin3.svg";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const containerStyle = {
	width: "100%",
	height: "100%",
};

const center = {
	lat: 54.251186,
	lng: -4.463196,
};

function SitesMap({ zoom }) {
	const dispatch = useDispatch();
	const [isOpen, setIsOpen] = useState(false);
	const { value, loading } = useSelector((state) => state.sites);
	const { user } = useSelector((state) => state.auth);
	const navigate = useNavigate();
	const [anchor, setAnchor] = useState();

	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		libraries: ["places"],
		googleMapsApiKey: "AIzaSyBp62nI9KBGuDsGKlBGxXa54izDRyZacZM",
	});
	return isLoaded ? (
		<GoogleMap
			mapContainerStyle={containerStyle}
			center={center}
			options={{
				styles: [
					{
						elementType: "geometry",
						stylers: [
							{
								color: "#f5f5f5",
							},
						],
					},
					{
						elementType: "labels.icon",
						stylers: [
							{
								visibility: "off",
							},
						],
					},
					{
						elementType: "labels.text.fill",
						stylers: [
							{
								color: "#616161",
							},
						],
					},
					{
						elementType: "labels.text.stroke",
						stylers: [
							{
								color: "#f5f5f5",
							},
						],
					},
					{
						featureType: "administrative.land_parcel",
						elementType: "labels.text.fill",
						stylers: [
							{
								color: "#bdbdbd",
							},
						],
					},
					{
						featureType: "poi",
						elementType: "geometry",
						stylers: [
							{
								color: "#eeeeee",
							},
						],
					},
					{
						featureType: "poi",
						elementType: "labels.text.fill",
						stylers: [
							{
								color: "#757575",
							},
						],
					},
					{
						featureType: "poi.park",
						elementType: "geometry",
						stylers: [
							{
								color: "#e5e5e5",
							},
						],
					},
					{
						featureType: "poi.park",
						elementType: "labels.text.fill",
						stylers: [
							{
								color: "#9e9e9e",
							},
						],
					},
					{
						featureType: "road",
						elementType: "geometry",
						stylers: [
							{
								color: "#ffffff",
							},
						],
					},
					{
						featureType: "road.arterial",
						elementType: "labels.text.fill",
						stylers: [
							{
								color: "#757575",
							},
						],
					},
					{
						featureType: "road.highway",
						elementType: "geometry",
						stylers: [
							{
								color: "#dadada",
							},
						],
					},
					{
						featureType: "road.highway",
						elementType: "labels.text.fill",
						stylers: [
							{
								color: "#616161",
							},
						],
					},
					{
						featureType: "road.local",
						elementType: "labels.text.fill",
						stylers: [
							{
								color: "#9e9e9e",
							},
						],
					},
					{
						featureType: "transit.line",
						elementType: "geometry",
						stylers: [
							{
								color: "#e5e5e5",
							},
						],
					},
					{
						featureType: "transit.station",
						elementType: "geometry",
						stylers: [
							{
								color: "#eeeeee",
							},
						],
					},
					{
						featureType: "water",
						elementType: "geometry",
						stylers: [
							{
								color: "#c9c9c9",
							},
						],
					},
					{
						featureType: "water",
						elementType: "labels.text.fill",
						stylers: [
							{
								color: "#9e9e9e",
							},
						],
					},
				],
			}}
			zoom={zoom ? zoom : 6.2}>
			{value?.map((marker) => {
				return (
					<Marker
						key={marker.site_id}
						position={{
							lat: parseFloat(marker?.latitude),
							lng: parseFloat(marker?.longitude),
						}}
						onMouseOver={(e) => {
							setIsOpen(marker.site_id);
						}}
						onMouseOut={() => {
							setIsOpen();
						}}
						icon={getStatus(marker?.site_status) }
						onClick={() => navigate(`/sites/${marker?.site_id}`)}>
						{marker?.site_id == isOpen && (
							<InfoWindow
								options={{ disableAutoPan: true }}
								position={{
									lat: parseFloat(marker.latitude),
									lng: parseFloat(marker.longitude),
								}}>
								<MapMarker
									name={
										marker?.site_name || "Nottignham Res 14"
									}
									Error="Door Gap ++"
									sector={marker?.site_sector}
									solution={marker.solution}
									status={marker?.site_status || "Awaiting Installation"}
								/>
							</InfoWindow>
						)}
					</Marker>
				);
			})}
		</GoogleMap>
	) : null;
}

export default SitesMap;

const getStatus = (stat) => {
	let status = stat;

	if (status == "Conforming" ) {
		return Ok;
	} else if (status == "Warning") {
		return Warning;
	} else if (status == "Not Conforming" || status == "Non Conforming") {
		return Home;
	} else {
		return Warning;
	}
};
