import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
} from "chart.js";
import 'chartjs-adapter-date-fns';
import { format } from "date-fns";

ChartJS.register(
  ArcElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Legend,
  TimeScale
);

const DynamicLineChartAccelerometer = ({ data, chartTitle, xLabel, yLabel }) => {
  const labels = data?.map((entry) => entry.createdAt.split("T")[0]);
  const XData = data?.map((entry) => entry.Data?.uplink_message?.decoded_payload?.accelerometerX);
  const YData = data?.map((entry) => entry.Data?.uplink_message?.decoded_payload.accelerometerY);
  const ZData = data?.map((entry) => entry.Data?.uplink_message?.decoded_payload.accelerometerZ);
  const reversLebels = labels?.reverse();
  const reversX = XData?.reverse();
  const reverseY = YData?.reverse();
  const reverseZ = ZData?.reverse();
  const formattedLabels = reversLebels?.map((date) => {
    return format(new Date(date), "MM/dd");
  });

  const chartData = {
    labels: formattedLabels,
    datasets: [
      {
        label: "Accelerometer X",
        data: reversX,
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        fill: false,
        pointBorderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 0,
        spanGaps: true,
      },
      {
        label: "Accelerometer Y",
        data: reverseY,
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
        fill: false,
        pointBorderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 0,
        spanGaps: true,
      },
      {
        label: "Accelerometer Z",
        data: reverseZ,
        borderColor: "rgba(255, 205, 86, 1)",
        borderWidth: 1,
        fill: false,
        pointBorderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 0,
        spanGaps: true,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: chartTitle || 'Accelerometer Data Over Time'
      },
      tooltip: {
        callbacks: {
          title: (context) => format(new Date(reversLebels[context[0].dataIndex]), 'yyyy-MM-dd'),
          label: (context) => `${context.dataset.label}: ${context.parsed.y}`,
        }
      }
    },
    scales: {
      x: {
        type: "category",
        time: {
          unit: "day",
          displayFormats: {
            day: "MMM dd"
          },
        },
        title: {
          display: true,
          text: xLabel || "Date",
        },
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          font: {
            size: 6,
            family: 'Futura Pt'
          }
        },
      },
      y: {
        title: {
          display: true,
          text: yLabel || "Accelerometer Values",
        },
        beginAtZero: false,
      },
    },
  };

  return <Line data={chartData} options={chartOptions} />;
};

export default DynamicLineChartAccelerometer;