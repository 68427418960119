export const formatDateToGB = (date_string, separator = "-") => {
	let date_options = { day: "2-digit", month: "2-digit", year: "numeric" };
	let _date = new Date(date_string);
	return _date
		.toLocaleDateString("en-GB", date_options)
		.split("/")
		.join(separator);
};

export const API_URL = () => {
	switch (process.env.NODE_ENV) {
		case "development":
			return process.env.REACT_APP_API_URL_DEVELOPMENT;
		case "production":
			return process.env.REACT_APP_API_URL;
		default:
			return process.env.REACT_APP_API_URL_DEVELOPMENT;
	}
};
