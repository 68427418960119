import { createSlice } from "@reduxjs/toolkit";
import {
	postApi,
	getApi,
	deleteApi,
	putApi,
	uploadService,
} from "../../Utils/serviceWorker";
import { createAsyncThunk } from "@reduxjs/toolkit";
import jsCookie from "js-cookie";
import { toast } from "react-toastify";

export const getUsers = createAsyncThunk(
	"users/get",
	async (credentials, thunkAPI) => {
		try {
			let url = `users?page=${credentials.page}&limit=${credentials.limit}&search=${credentials.search}&sort=${credentials.sort}&field=${credentials.field}`;
			if (credentials.roleId) {
				url += `&roleId=${credentials.roleId}`;
			}

			const response = await getApi(url, true);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const getUser = createAsyncThunk(
	"user/get",
	async (credentials, thunkAPI) => {
		try {
			const response = await getApi(`users/${credentials}`, true);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const addUser = createAsyncThunk(
	"user/add",
	async (credentials, thunkAPI) => {
		try {
			const response = await postApi(`users/`, true, credentials);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const updateUser = createAsyncThunk(
	"user/update",
	async (credentials, thunkAPI) => {
		try {
			const response = await putApi(
				`users/${credentials.id}`,
				true,
				credentials
			);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const deleteUser = createAsyncThunk(
	"role/User",
	async (credentials, thunkAPI) => {
		try {
			const response = await deleteApi(`users/${credentials}`, true);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const uploadAvatar = createAsyncThunk(
	"user/avatar",
	async (credentials, thunkAPI) => {
		try {
			const response = await uploadService(
				`file/upload/users/avatar`,
				true,
				credentials
			);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const getInstallationManager = createAsyncThunk(
	"role/User",
	async (credentials, thunkAPI) => {
		try {
			const response = await getApi(
				`users?roleId=${process.env.REACT_APP_AGENT_ROLE_ID}`,
				true
			);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const getOrganizationUsers = createAsyncThunk(
	"role/User",
	async (credentials, thunkAPI) => {
		try {
			const response = await getApi(
				`users?organization=${credentials}`,
				true
			);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

const initialState = {
	value: [],
	avatarLoading: false,
	loading: false,
	error: null,
	count: 0,
};

export const Users = createSlice({
	name: "users",
	initialState,
	reducers: {
		reset: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			// getUsers
			.addCase(getUsers.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getUsers.fulfilled, (state, action) => {
				state.loading = false;
				state.value = {
					data: action.payload.rows,
					currentPage: action.payload.currentPage,
					totalPages: action.payload.totalPages,
					totalItems: action.payload.count,
				};
			})
			.addCase(getUsers.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			// getOrganizationUsers
			.addCase(getOrganizationUsers.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getOrganizationUsers.fulfilled, (state, action) => {
				state.loading = false;
				state.value = {
					data: action.payload.rows,
					currentPage: action.payload.currentPage,
					totalPages: action.payload.totalPages,
					totalItems: action.payload.count,
				};
			})
			.addCase(getOrganizationUsers.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			// updateUser
			.addCase(updateUser.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(updateUser.fulfilled, (state) => {
				state.loading = false;
				toast.success("Updated User Successfully");
			})
			.addCase(updateUser.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			// addUser
			.addCase(addUser.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(addUser.fulfilled, (state) => {
				state.loading = false;
				toast.success("Created User Successfully");
			})
			.addCase(addUser.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			// uploadAvatar
			.addCase(uploadAvatar.pending, (state) => {
				state.avatarLoading = true;
				state.error = null;
			})
			.addCase(uploadAvatar.fulfilled, (state) => {
				state.avatarLoading = false;
				toast.success("Uploaded Image Successfully");
			})
			.addCase(uploadAvatar.rejected, (state, action) => {
				state.avatarLoading = false;
				state.error = action.payload;
			});
	},
});
