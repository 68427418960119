import * as React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { Headings, SubHeadings } from "../../Shared/Fonts/Fonts";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import collapseUp from "../../Assets/DropDownUp.svg";
import collapseDown from "../../Assets/DropDownDown.svg";

import {
	getSite,
	getSensorsOfSite,
	getDoorsOfSite,
} from "../../Store/Features/Sites";

const drawerBleeding = 80;

const Root = styled("div")(({ theme }) => ({
	height: "100%",

	backgroundColor:
		theme.palette.mode === "light"
			? grey[100]
			: theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
	width: 30,
	height: 6,
	backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
	borderRadius: 3,
	position: "absolute",
	top: 8,
	left: "calc(50% - 15px)",
}));

function SwipeableEdgeDrawer(props) {
	const { window } = props;
	const [site, setSite] = useState({});
	const [open, setOpen] = React.useState(false);
	const dispatch = useDispatch();
	const { siteId } = useParams();

	const toggleDrawer = (newOpen) => () => {
		setOpen(newOpen);
	};
	const matches = useMediaQuery("(max-width:768px)");
	useEffect(() => {
		dispatch(getSite(siteId)).then((res) => {
			setSite(res.payload);
		});
	}, []);

	// This is used only for the example
	const container =
		window !== undefined ? () => window().document.body : undefined;

	const clickedHeader = () => {
		console.log("header clicked");
	};

	return (
		<Root>
			<CssBaseline />
			<Global
				styles={{
					".MuiDrawer-root > .MuiPaper-root": {
						height: `calc(50% - ${drawerBleeding}px)`,
						overflow: "visible",
						bottom: "20px",
						width: "95%",
						right: "2.5%",
						left: "2.5%",
					},
				}}
				className="swiper"
			/>

			<SwipeableDrawer
				container={container}
				anchor="bottom"
				open={open}
				onClose={toggleDrawer(false)}
				onOpen={toggleDrawer(true)}
				swipeAreaWidth={drawerBleeding}
				disableSwipeToOpen={false}
				ModalProps={{
					keepMounted: true,
				}}
				className="swiper"
				sx={{
					"& .MuiSwipeableDrawer-paper": {
						width: "95%",
					},
				}}>
				<StyledBox
					className="absolute -top-20 visible right-0 left-0 pointer-events-auto"
					sx={{
						borderBottomLeftRadius: open ? 0 : 20,
						borderBottomRightRadius: open ? 0 : 20,
						display: matches ? null : "none",
					}}
					onClick={toggleDrawer(!open)}>
					<div className="h-20 w-full p-5 box-border flex justify-between items-center">
						<Headings>{site?.name}</Headings>
						<img src={open ? collapseDown : collapseUp} alt="Img" />
					</div>
				</StyledBox>
				<StyledBox className="px-[2px] pb-[2px] h-full overflow-auto right-[10px] left-[10px]">
					<div className="fx-space">
						<SubHeadings className="leading-9 text-base text-black font-medium">
							Building Type
						</SubHeadings>
						<SubHeadings className="leading-9 font-futura-book text-base text-dark font-normal">
							{site?.sector}
						</SubHeadings>
					</div>
					<div className="fx-space">
						<SubHeadings className="leading-9 text-base text-black font-medium">
							No. of doors
						</SubHeadings>
						<SubHeadings className="leading-9 font-futura-book text-base text-dark font-normal">
							{site?.doorsCount}
						</SubHeadings>
					</div>
					<div className="fx-space">
						<SubHeadings className="leading-9 text-base text-black font-medium">
							No. of sensors
						</SubHeadings>
						<SubHeadings className="leading-9 font-futura-book text-base text-dark font-normal">
							{site?.sensorsCount}
						</SubHeadings>
					</div>
					<div className="fx-space">
						<SubHeadings className="leading-9 text-base text-black font-medium">
							Address
						</SubHeadings>
						<div className="w-[40%]">
							<SubHeadings className="leading-9 font-futura-book text-base text-dark font-normal">
								{site?.address}
							</SubHeadings>
						</div>
					</div>
				</StyledBox>
			</SwipeableDrawer>
		</Root>
	);
}

SwipeableEdgeDrawer.propTypes = {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window: PropTypes.func,
};

export default SwipeableEdgeDrawer;
