import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
} from "chart.js";
import 'chartjs-adapter-date-fns';
import { format } from "date-fns";

ChartJS.register(
  ArcElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Legend,
  TimeScale
);

const DynamicLineChartSpreading = ({ data, chartTitle, xLabel, yLabel }) => {
  const labels = data?.map((entry) => entry.createdAt.split("T")[0]);
  const doorOpenCounts = data?.map((entry) => entry.Data?.uplink_message?.settings?.data_rate?.lora?.spreading_factor);
  const reversLebels = labels?.reverse();
  const reversCounts = doorOpenCounts?.reverse();

  const formattedLabels = reversLebels?.map((date) => {
    return format(new Date(date), "MM/dd");
  });

  const chartData = {
    labels: formattedLabels,
    datasets: [
      {
        label: "Spreading Factor",
        data: reversCounts,
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        fill: false,
        pointBorderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 0,
        spanGaps: true,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: chartTitle || 'Spreading Factor Over Time'
      },
      tooltip: {
        callbacks: {
          title: (context) => format(new Date(reversLebels[context[0].dataIndex]), 'yyyy-MM-dd'),
          label: (context) => `Spreading Factor: ${context.parsed.y}`,
        }
      }
    },
    scales: {
      x: {
        type: "category",
        time: {
          unit: "day",
          displayFormats: {
            day: "EEE"
          },
          tooltipFormat: "yyyy-MM-dd"
        },
        title: {
          display: true,
          text: xLabel || "Date",
        },
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          font: {
            size: 10,
            family: 'Futura Pt'
          }
        },
      },
      y: {
        title: {
          display: true,
          text: yLabel || "Spreading Factor",
        },
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  return <Line data={chartData} options={chartOptions} />;
};

export default DynamicLineChartSpreading;