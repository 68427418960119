import React from "react";

const SystemStatusIcon = ({ color }) => (
	<svg
		width="27"
		height="27"
		viewBox="0 0 27 27"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<rect
			x="7.91216"
			y="0.912162"
			width="18.1757"
			height="25.1757"
			fill="white"
			stroke={color}
			stroke-width="1.82432"
		/>
		<circle
			cx="8"
			cy="19"
			r="7.15"
			fill="white"
			stroke={color}
			stroke-width="1.7"
		/>
		<path
			d="M8.00254 20.3603C7.87062 20.3603 7.7563 20.3184 7.65956 20.2346C7.57162 20.1508 7.51886 20.0293 7.50127 19.8701L7.01319 14.9804L7 14.8547C7 14.595 7.09234 14.3897 7.27702 14.2388C7.47049 14.0796 7.71233 14 8.00254 14C8.31033 14 8.56097 14.0922 8.75444 14.2765C8.94791 14.4525 9.02706 14.6913 8.99188 14.993L8.50381 19.8701C8.48622 20.0293 8.42905 20.1508 8.33232 20.2346C8.24438 20.3184 8.13445 20.3603 8.00254 20.3603ZM8.00254 23C7.72112 23 7.48808 22.912 7.3034 22.736C7.11872 22.5517 7.02638 22.3254 7.02638 22.0573C7.02638 21.7891 7.11872 21.567 7.3034 21.3911C7.48808 21.2067 7.72112 21.1145 8.00254 21.1145C8.28395 21.1145 8.517 21.2067 8.70167 21.3911C8.88635 21.567 8.97869 21.7891 8.97869 22.0573C8.97869 22.3254 8.88635 22.5517 8.70167 22.736C8.517 22.912 8.28395 23 8.00254 23Z"
			fill={color}
		/>
	</svg>
);

export default SystemStatusIcon;
