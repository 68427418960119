import { createSlice } from "@reduxjs/toolkit";
import { getApi, postApi } from "../../Utils/serviceWorker";
import { createAsyncThunk } from "@reduxjs/toolkit";
import jsCookie from "js-cookie";
import { toast } from "react-toastify";
const initialState = {
	value: [],
	loading: false,
	error: null,
	count: 0,
};
export const getNotifications = createAsyncThunk(
	"notifications/get",
	async (credentials, thunkAPI) => {
		try {
			const response = await getApi(
				`notifications?page=${credentials.page}&limit=${credentials.limit}
      %20%20%20%20&notifications=%20%20%20%20&&${credentials.query}=${credentials.id}&
      `,
				true
			);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const getAllNotifications = createAsyncThunk(
	"notificationsAll/get",
	async (credentials, thunkAPI) => {
		try {
			const response = await getApi(
				`notifications?page=${credentials.page}&limit=${credentials.limit}&sort=${credentials.sort}&field=${credentials.field}`,
				true
			);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const getReport = createAsyncThunk(
	"notifications/get/report",
	async (credentials, thunkAPI) => {
		try {
			const response = await postApi(
				`notifications/notificationstatus`,
				true,
				credentials
			);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const getNotagainstorg = createAsyncThunk(
	"notifications/get/",
	async (credentials, thunkAPI) => {
		try {
			const response = await getApi(
				`notifications/notificationagainstorganization/${credentials.orgId}?page=${credentials.page}&limit=${credentials.limit}&sort=${credentials.sort}&field=${credentials.field}`,
				true
			);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const Notifications = createSlice({
	name: "notifications",
	initialState,
	reducers: {
		reset: () => initialState,
	},
	extraReducers: (builder) => {
		builder.addCase(getNotifications.pending, (state) => {
			state.loading = true;
			state.error = null;
		});
		builder.addCase(getNotifications.fulfilled, (state, action) => {
			state.loading = false;

			state.value = action.payload.rows;
			state.count = action.payload.count;
		});
		builder.addCase(getNotifications.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload;
		});

		builder.addCase(getAllNotifications.pending, (state) => {
			state.loading = true;
			state.error = null;
		});
		builder.addCase(getAllNotifications.fulfilled, (state, action) => {
			state.loading = false;

			state.value = action.payload.rows;
			state.count = action.payload.count;
		});
		builder.addCase(getAllNotifications.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload;
		});

		builder.addCase(getReport.pending, (state) => {
			state.loading = true;
			state.error = null;
			toast.loading("Generating Report");
		});
		builder.addCase(getReport.fulfilled, (state, action) => {
			toast.dismiss();
			state.loading = false;
		});
		builder.addCase(getReport.rejected, (state, action) => {
			toast.dismiss();
			toast.error("Error while generating report");
			state.loading = false;
			state.error = action.payload;
		});
		builder.addCase(getNotagainstorg.pending, (state) => {
			state.loading = true;
			state.error = null;
		});
		builder.addCase(getNotagainstorg.fulfilled, (state, action) => {
			state.loading = false;
			state.value = action.payload.rows;
			state.count = action.payload.count;
		});
		builder.addCase(getNotagainstorg.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload;
		});
	},
});

export const { reset } = Notifications.actions;
